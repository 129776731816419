import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { formatDate } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  calculateTimeDifference,
  getFormattedDate,
  getFormattedTime,
  getStartAndEndTime,
  getWelfareCheckInterval,
  isLargeScreen,
} from '../../../../../../global.variable';
import { AppService } from '../../../../../app.service';
import { AuthService } from '../../../../../auth/auth.service';
import { ModelDialogueService } from '../../../../../shared/components/modal-dialogue/model-dialogue.service';
import { LoadingSpinnerService } from '../../../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../../../shared/services/toaster.service';
import { CheckpointService } from '../../../checkpoints/checkpoint.service';
import { JobsService } from '../../jobs.service';

@Component({
  selector: 'gtapp-patrol',
  templateUrl: './patrol.component.html',
  styleUrl: './patrol.component.scss',
})
export class PatrolComponent implements OnInit {
  @Input() selectedStatus: any;

  formdata = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    assigneeName: new UntypedFormControl(),

    patrol_brief: new UntypedFormControl(),
  });

  addJobPressed: boolean = false;
  currentStep: number = 1;
  showCheckpointList: boolean = false;
  patrolJobType: any = {
    jobType: {
      name: 'job Type',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    name: {
      name: 'Name',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
    checkpoints: {
      name: 'Checkpoints',
      value: '',
      edit: false,
      data: [],
      tempValue: '',
    },
    patrol_brief: {
      name: 'Patrol Brief',
      value: '',
      edit: false,
      data: [],
      tempValue: '',
    },
    patrol_brief_files: {
      name: 'Patrol Brief Files',
      value: '',
      edit: false,
      data: [],
      tempValue: '',
    },
    assignees: {
      name: 'Assignee',
      value: '',
      edit: false,
      data: [],
      tempValue: '',
    },

    schedule: {
      name: 'Schedule',
      value: '',
      edit: false,
      data: null,
      tempValue: '',
    },
  };

  checkpointListData: any = [];

  dialogRef: any;
  userData: any;

  schedulerForm: UntypedFormGroup = new UntypedFormGroup({
    end_time: new FormControl(null),
    start_time: new FormControl(null),
    start_day: new FormControl(new Date()),
    end_day: new FormControl(null, Validators.required),
    repeat_times: new UntypedFormControl(null),
    repeat_type: new UntypedFormControl(null, Validators.required),
    repeat_details: new UntypedFormControl([]),
    repeat_interval: new UntypedFormControl(1, [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
    ]),
    position: new UntypedFormControl(null, []),
    week_day: new UntypedFormControl(null, []),
    month_repeat_case: new UntypedFormControl(null, []),
  });
  addSchedule: boolean = false;

  patrolRouteCpRows: number = 10;
  patrolRouteCpPrevious: number = 0;
  patrolRouteCpPageNum: number = 1;
  patrolRouteCpTotalPages: number = 0;
  patrolRouteCPList: any = [];
  patrolRouteCpTotalCount: number = 0;
  patrolRouteCpCount: number = 0;
  checkpointSearchList: any = [];
  searchFilteredCheckpoints: any = [];
  filteredCheckpointsSelected: boolean = false;

  assigneeList: any = [];
  selectedAssignees: any = [];
  assigneeSearchList: any = [];

  patrolRouteAssigneeRows: number = 25;
  patrolRouteAssigneePrevious: number = 0;
  patrolRouteAssigneePageNum: number = 1;
  patrolRouteAssigneeTotalPages: number = 0;
  patrolRouteAssigneeTotalCount: number = 0;

  scheduleStepperActive: boolean = false;
  intervalData: any = [
    { name: 'Disabled', value: 0 },
    { name: 'Every 15 mins', value: 15 },
    { name: 'Every 30 mins', value: 30 },
  ];
  intervalCheck: any;
  welfareReminderDict: any = {};

  largeView = isLargeScreen;
  selectedLastCP: any;

  constructor(
    private jobService: JobsService,
    private dialogService: ModelDialogueService,
    private authService: AuthService,
    private router: Router,
    private spinnerService: LoadingSpinnerService,
    private checkpointService: CheckpointService,
    private viewContainerRef: ViewContainerRef,
    private appService: AppService,
    private toasterService: ToasterService
  ) {
    this.userData = this.authService.getUserData();

    router.events.subscribe((event: any) => {
      // close dialog boxes if any are open when back is pressed
      if (event.navigationTrigger === 'popstate') {
        this.dialogRef?.close();
      }
    });
  }

  ngOnInit(): void {
    this.patrolJobType.jobType.value = this.selectedStatus?.name;
    this.patrolJobType.jobType.data = this.selectedStatus;

    this.editOtherJobTypeItem('name');
  }

  clearValues(_field: string) {}

  onAddJob() {
    this.schedulerForm.value.start_day =
      this.schedulerForm.value.start_day == ''
        ? ''
        : formatDate(this.schedulerForm.value.start_day, 'yyyy-MM-dd', 'en');
    this.schedulerForm.value.end_day =
      this.schedulerForm.value.end_day == null
        ? null
        : formatDate(this.schedulerForm.value.end_day, 'yyyy-MM-dd', 'en');
    let timezoneOffset = new Date();

    this.addJobPressed = true;
    this.spinnerService.show();
    let data: any = {
      job_type_id: this.selectedStatus.id,
      time_offset: timezoneOffset.getTimezoneOffset(),
      ...this.schedulerForm.value,
      name: this.formdata.value?.name,
      patrol_brief: this.formdata.value?.patrol_brief,
      assignees: [
        ...new Set(
          this.patrolJobType.assignees?.data?.map(
            (assignee: any) => assignee?.id
          )
        ),
      ],
      checkpoints: this.patrolJobType.checkpoints?.data?.map(
        (checkpoint: any) => ({
          id: checkpoint?.id,
        })
      ),
    };

    data['interval_check'] = this.intervalCheck || 0;

    let formData: FormData = new FormData();
    formData.append('file', this.patrolJobType.patrol_brief_files.value);
    formData.append('form_data', JSON.stringify(data));
    let params: any = {};

    this.appService
      .formDataApi('roster_schedule/add_patrol_route', formData, false, params)
      .then((response: any) => {
        if (response['status'] == 'success') {
          sessionStorage.removeItem('selectedRosterDate');
          this.router.navigate(['/roster']);
        } else {
          this.addJobPressed = false;
          this.toasterService.setMessage({
            errorMessage: response['message'],
            successMessage: '',
          });
        }

        this.spinnerService.hide();
      });
  }

  shiftFocus(elementId: string) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);

      element?.focus();
    }, 100);
  }
  editOtherJobTypeItem(field: string) {
    for (let key in this.patrolJobType) {
      this.patrolJobType[key]['edit'] = false;
    }
    this.patrolJobType[field]['edit'] = true;
  }
  openTemplate(template: TemplateRef<any>) {
    this.dialogRef = this.dialogService.open(
      template,
      {
        data: {},
      },
      this.viewContainerRef
    );
  }
  ngOnDestroy() {
    sessionStorage.removeItem('schedulerData');
  }
  formatScheduleForm() {
    this.addSchedule = true;
    let passedDate = sessionStorage.getItem('selectedRosterDate') || new Date();

    const [startDateTime, endDateTime] = getStartAndEndTime(
      passedDate ? new Date(passedDate) : new Date()
    );
    this.schedulerForm.controls['repeat_type'].setValue('once');
    this.schedulerForm.controls['end_day'].setValue(
      getFormattedDate(endDateTime)
    );
    this.schedulerForm.controls['end_time'].setValue(
      getFormattedTime(endDateTime)
    );
    this.schedulerForm.controls['end_day'].updateValueAndValidity();
    this.schedulerForm.controls['end_day'].clearAsyncValidators();
    this.schedulerForm.controls['start_day'].setValue(
      getFormattedDate(startDateTime)
    );
    this.schedulerForm.controls['start_time'].setValue(
      getFormattedTime(startDateTime)
    );

    sessionStorage.removeItem('selectedRosterDate');
    this.scheduleStepperActive = true;
    this.getIntervalData();
  }

  getPatrolRouteCheckpoints() {
    let params: any = {};
    if (this.patrolRouteCpRows) {
      params['rows'] = this.patrolRouteCpRows;
    }
    if (this.patrolRouteCpPrevious) {
      params['previous'] = this.patrolRouteCpPrevious;
    }

    this.checkpointService.getCheckpoints(params).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.patrolRouteCPList.push(...response['data']);
        this.patrolRouteCPList = this.patrolRouteCPList?.filter(
          (x: any, index: any, y: any) =>
            y?.findIndex((t: any) => t?.id === x?.id) === index
        );
        this.spinnerService.hide();
        this.patrolRouteCpTotalCount = response['total_size'];
        this.patrolRouteCpCount = response['cp_count'];
        this.patrolRouteCpTotalPages = Math.ceil(
          this.patrolRouteCpTotalCount / this.patrolRouteCpRows
        );
        this.currentStep = 2;
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }
    });
  }
  removeSelectedCP(selectedIndex: number) {
    this.patrolJobType.checkpoints.data =
      this.patrolJobType?.checkpoints?.data?.filter(
        (checkpoint: any, index: number) => index !== selectedIndex
      );
    if (this.patrolJobType.checkpoints.data.length === 0) {
      // Activate the first tab (All Checkpoints)
      const allCheckpointsTab = document.getElementById('all-checkpoints-tab');
      if (allCheckpointsTab) {
        allCheckpointsTab.click(); // This will select the first tab
      }
    }
  }
  checkPointCounter(checkpointId: any) {
    return this.patrolJobType.checkpoints.data?.filter(
      (item: any) => item?.id === checkpointId
    )?.length;
  }
  addSelectedCP(addCheckpoint: any, alert = true) {
    this.patrolJobType?.checkpoints?.data.push(addCheckpoint);
    this.selectedLastCP = addCheckpoint;
  }

  onLoadMore() {
    this.spinnerService.show();
    this.patrolRouteCpPrevious =
      this.patrolRouteCpPrevious + this.patrolRouteCpRows;

    this.getPatrolRouteCheckpoints();
    setTimeout(() => {
      const table = document.querySelector('#cpList');
      if (table) {
        table.scrollTo({ top: table.scrollHeight, behavior: 'smooth' });
      }
    }, 100);
  }
  addAllCheckpoints() {
    this.patrolRouteCPList?.forEach((checkpoint: any) =>
      this.addSelectedCP(checkpoint)
    );
  }
  removeAllCheckpoints() {
    this.patrolJobType.checkpoints.data = [];
    const allCheckpointsTab = document.getElementById('all-checkpoints-tab');
    if (allCheckpointsTab) {
      allCheckpointsTab.click(); // This will select the first tab
    }
  }

  searchCheckPoints(event?: any) {
    if (event.target.value?.length > 2) {
      let params: any = { name: event.target.value };

      this.checkpointService
        .getCheckpoints(params)
        .subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.patrolRouteCPList = response?.data;
            this.patrolRouteCpTotalCount = response['total_size'];
            this.patrolRouteCpTotalPages = Math.ceil(
              this.patrolRouteCpTotalCount / this.patrolRouteCpRows
            );
          } else {
            this.toasterService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
          }
        });
    } else if (!event.target.value?.length) {
      this.patrolRouteCPList = [];
      this.patrolRouteCpRows = 10;
      this.patrolRouteCpPrevious = 0;
      this.patrolRouteCpPageNum = 1;
      this.patrolRouteCpTotalPages = 0;
      this.getPatrolRouteCheckpoints();
    }
  }
  getPatrolRouteAssignees() {
    let params: any = {};
    if (this.patrolRouteAssigneeRows) {
      params['rows'] = this.patrolRouteAssigneeRows;
    }
    if (this.patrolRouteAssigneePrevious) {
      params['previous'] = this.patrolRouteAssigneePrevious;
    }

    this.spinnerService.show();
    this.jobService.getAssignees(params).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.assigneeList.push(...response?.data);

        this.patrolRouteAssigneeTotalCount = response?.total_count;
        this.patrolRouteAssigneeTotalPages = Math.ceil(
          this.patrolRouteAssigneeTotalCount / this.patrolRouteAssigneeRows
        );

        this.makeAssigneeSelectionChanges();
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response?.message,
        });
      }
      this.spinnerService.hide();
    });
  }
  removeSelectedAssignee(deleteAssignee: any) {
    this.patrolJobType.assignees.data =
      this.patrolJobType?.assignees?.data?.filter(
        (assignee: any) => assignee?.id !== deleteAssignee?.id
      );
    this.makeAssigneeSelectionChanges();
  }
  addSelectedAssignee(addAssignee: any) {
    if (addAssignee?.id) {
      if (
        this.patrolJobType?.assignees?.data?.some(
          (assignee: any) => assignee?.id === addAssignee?.id
        )
      ) {
        this.toasterService.setMessage({
          errorMessage: 'assignee already added',
          successMessage: '',
        });
      } else {
        this.patrolJobType?.assignees?.data.push(addAssignee);
        if (
          !this.assigneeList?.some(
            (assignee: any) => assignee?.id === addAssignee?.id
          )
        ) {
          this.assigneeList.push(addAssignee);
        }
      }

      this.makeAssigneeSelectionChanges();
    }
  }
  addRemovessignee(assignee: any) {
    assignee.selected = !assignee?.selected;
    if (assignee.selected) {
      this.addSelectedAssignee(assignee);
    } else {
      this.removeSelectedAssignee(assignee);
    }
  }
  makeAssigneeSelectionChanges() {
    this.assigneeList.forEach((assignee: any) => {
      assignee.selected = this.patrolJobType?.assignees?.data?.some(
        (selectedAssignee: any) => selectedAssignee?.id === assignee?.id
      );
    });
  }

  searchAssignee(event?: any) {
    if (event.target.value?.length > 2) {
      let params: any = { full_name: event.target.value };
      this.jobService.getAssignees(params).subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.assigneeSearchList = response?.data?.filter(
            (value1: any) =>
              !this.patrolJobType?.assignees?.data?.some(
                (value2: any) => value1?.id === value2?.id
              )
          );
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
    }
  }
  updateInterval(interval: any) {
    this.intervalCheck = interval;
  }
  onLoadMoreAssignees() {
    this.patrolRouteAssigneePrevious =
      this.patrolRouteAssigneePrevious + this.patrolRouteAssigneeRows;

    this.getPatrolRouteAssignees();
    setTimeout(() => {
      const table = document.querySelector('#assigneeList');
      if (table) {
        table.scrollTo({ top: table.scrollHeight, behavior: 'smooth' });
      }
    }, 100);
  }
  uploadFile() {
    var button: any = document.getElementById('fileInput');
    button.click();
  }

  getIntervalData() {
    const scheduleData: any = JSON.parse(
      JSON.stringify(this.schedulerForm.value)
    );

    let diffValue = calculateTimeDifference(scheduleData);
    [this.intervalData, this.welfareReminderDict] =
      getWelfareCheckInterval(diffValue);

    if (!this.intervalData?.length) {
      this.intervalData = [{ name: 'Disabled', value: 0 }];
    }
  }

  handleFileInput(event: any) {
    const allowedFileTypes = [
      'application/pdf',
      'image/jpeg',
      'image/png',
      'image/avif',
    ];
    const file = event.target.files[0];

    if (file && allowedFileTypes.includes(file.type)) {
      // Process the file (e.g., upload it or display a preview)
      this.patrolJobType.patrol_brief_files.value =
        event?.target?.files.item(0);
    } else {
      // Display an error message or reset the input
      this.toasterService.setMessage({
        successMessage: '',
        errorMessage:
          'Invalid file type selected!, Please choose a PDF or image file.',
      });

      event.target.value = ''; // Reset the file input
    }
  }
  dropCheckPoint(event: CdkDragDrop<any[]>) {
    moveItemInArray(
      this.patrolJobType?.checkpoints?.data,
      event.previousIndex,
      event.currentIndex
    );
  }
}
