import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  browserGpsInfo,
  dateRangeValueConverter,
} from '../../../../../global.variable';
import { DatetimePickerComponent } from '../../../../shared/components/datetime-picker/datetime-picker.component';
import { ModelDialogueService } from '../../../../shared/components/modal-dialogue/model-dialogue.service';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { UsersService } from '../users.service';

@Component({
  selector: 'gtapp-guards-online',
  templateUrl: './guards-online.component.html',
  styleUrl: './guards-online.component.scss',
})
export class GuardsOnlineComponent implements OnInit {
  userOnlineEventDetails: any = {
    individualDetails: [
      {
        name: 'full_name',
        cardRowStyle: {
          'font-weight': 600,
          'margin-bottom': '0.5rem',
        },
      },
      {
        name: 'email',
        cardRowStyle: {
          'font-size': 'small',
        },
      },
      {
        name: 'clocked_in_time',
        datetimeObj: true,
        rowDataStyle: {
          'font-size': 'small',
          'font-weight': 600,
        },
        prefixStyle: {
          'font-size': 'small',
        },

        prefix: 'Clocked In at ',
      },
    ],
  };
  tableStyle = {
    'overflow': 'auto',
    'max-height': '100%',
  };
  userOnlineDetailsTableView: any = [
    {
      header: 'Email',
      name: 'email',
      sortKey: 'email',
    },

    {
      header: 'Clock In Time',
      name: 'clocked_in_time',
      datetimeObj: true,
      sortKey: 'clocked_in_time',
    },
    {
      header: 'Guard',
      name: 'full_name',
      sortKey: 'full_name',
    },
  ];

  clockedUsersTableDetailsMobile = {
    individualDetails: [
      {
        name: 'event_type',
        cardRowStyle: {
          'font-size': 'small',
        },
      },

      {
        name: 'updated_at',
        datetimeObj: true,
        cardRowStyle: {
          'font-size': 'small',
        },
      },
    ],
  };

  clockedUserTableDetails = [
    {
      header: 'Event Time',
      name: 'updated_at',
      datetimeObj: true,
      sortKey: 'updated_at',
    },
    {
      header: 'Event Type',
      name: 'event_type',
    },
  ];

  apiFilter: boolean = true;
  userEvents = [];
  guardLastLocationData: any;

  selectedEventType: string = 'last_location';
  sortKeys: any = [];

  totalRows: number = 0;
  previous: number = 0;
  pageSize: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;

  scrollPosition: any;
  browserGpsInfo: any = browserGpsInfo;

  dialogeRef: any;
  dateRangeValue: any;

  showClockedInUsers: boolean = true;
  guardNbOptionList: any;
  centerLatLon: any;
  showMap: boolean = false;
  onlineUsers: any = [];

  selectedGuard: any;
  selectedGuardLocationInfo: any;

  tabTitles = {
    tab1: 'Guard Locations',
    tab2: 'Location History',
  };
  otherParams: any;
  dateRange: any;
  mapLatLong: any;

  // userHistory page params
  totalRowsUserLocationHistory: number = 0;
  previousUserLocationHistory: number = 0;
  pageSizeUserLocationHistory: number = 10;
  pageNumUserLocationHistory: number = 1;
  totalPagesUserLocationHistory: number = 0;
  otherParamsUserLocationHistory: any;
  sortKeysUserLocationHistory: any = ['-updated_at'];
  searchParams: any;
  defaultTimeRange: any;
  selectedTab: string = this.tabTitles.tab1;
  showguardsDropdown: boolean = false;
  guardNameValue: string = '';

  guardLatestInfo: any = [];
  guardLatestInfoBackup: any = [];

  // {1:"Clocked In", 2:"Awaiting Clock In", 3:"Clocked Out"}
  guardStatus: any = {
    1: {
      name: 'Clocked In',
      selected: true,
      circle: {
        'width': '12px',
        'height': '12px',
        'border-radius': ' 50%',
        'background-color': 'var(--color-success-500)',
        'display': 'inline-block',
      },
    },
    2: {
      name: 'Awaiting Clock In',
      selected: true,
      circle: {
        'width': '12px',
        'height': '12px',
        'border-radius': ' 50%',
        'background-color': 'var(--color-danger-300)',
        'display': 'inline-block',
      },
    },
    3: {
      name: 'Clocked Out',
      selected: true,
      circle: {
        'width': '12px',
        'height': '12px',
        'border-radius': ' 50%',
        'background-color': 'var(--card-text-color)',
        'display': 'inline-block',
      },
    },
  };
  guardStatusKeys = Object.keys(this.guardStatus);
  selectedGuardIndex: number = -1;
  selectedGuardUser: any;
  constructor(
    private userService: UsersService,
    private spinnerService: LoadingSpinnerService,
    private dialogService: ModelDialogueService,
    private toasterService: ToasterService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getUserOnline();
    this.getGuardLatestInfo();

    const today = new Date();
    today.setDate(new Date().getDate() - 1);

    this.defaultTimeRange = {
      start: today,
      end: new Date(),
    };
    this.dateRange = { ...this.defaultTimeRange };
    this.dateRangeValue = `${dateRangeValueConverter(
      this.dateRange?.start
    )} - \n ${dateRangeValueConverter(this.dateRange?.end)}`;
    this.getUserUpdateLog();
  }
  changeTab(event: any) {
    this.selectedGuard = null;
    this.showClockedInUsers = true;
    this.showMap = false;
    this.clearAll();
    if (event.tabTitle === this.tabTitles.tab1) {
      this.spinnerService.show();
      setTimeout(() => {
        this.showMap = true;
        this.spinnerService.hide();
      }, 800);
    }
  }

  getUserOnline() {
    this.spinnerService.show();
    let params: any = { clocked_in_guards: 1 };

    if (this.sortKeys?.length) {
      params['sort_key'] = this.sortKeys[0];
    }

    if (this.pageSize) {
      params['rows'] = this.pageSize;
    }
    if (this.previous) {
      params['previous'] = this.previous;
    }

    this.userService
      .getCurrentlyClockedUsers(params)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.onlineUsers = response['data'];
          this.spinnerService.hide();
          this.totalRows = response['total_size'];
          this.totalPages = Math.ceil(this.totalRows / this.pageSize);
          this.otherParams = {
            paginationData: {
              totalRows: this.totalRows,
              previousRows: this.previous,
              rows: this.pageSize,
              pageNum: this.pageNum,
              totalPages: this.totalPages,
            },
          };
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
          this.spinnerService.hide();
        }
      });
  }
  onChangeTablePagination(data: any) {
    this.previous = data?.paginationData?.previousRows;
    this.pageNum = data?.paginationData?.pageNum;
    this.pageSize = data?.paginationData?.rows;

    this.getUserOnline();

    this.otherParams = {
      paginationData: { ...data?.paginationData },
    };
  }
  onChangeUserLocationHistoryPagination(data: any) {
    this.previousUserLocationHistory = data?.paginationData?.previousRows;
    this.pageNumUserLocationHistory = data?.paginationData?.pageNum;
    this.pageSizeUserLocationHistory = data?.paginationData?.rows;

    this.getUserUpdateLog();

    this.otherParamsUserLocationHistory = {
      paginationData: { ...data?.paginationData },
    };
  }
  getUserUpdateLog() {
    this.spinnerService.show();
    let timezoneOffset = new Date();
    let body: any = {
      event_type: this.selectedEventType,
      start_date: this.dateRange.start,
      end_date: this.dateRange.end,
      time_offset: timezoneOffset.getTimezoneOffset(),
    };
    this.selectedGuardLocationInfo = null;

    if (this.sortKeysUserLocationHistory)
      body.sort_key = this.sortKeysUserLocationHistory;

    if (this.pageSizeUserLocationHistory) {
      body['rows'] = this.pageSizeUserLocationHistory;
    }
    if (this.previousUserLocationHistory) {
      body['previous'] = this.previousUserLocationHistory;
    }
    if (this.selectedGuard?.id) {
      body['sub_user_id'] = this.selectedGuard.id;
    }
    this.showMap = false;
    this.userService.getUserUpdateLog(body).subscribe((response: any) => {
      if (response['status'] == 'success') {
        if (this.selectedEventType === 'last_location') {
          this.guardLastLocationData = response['data'];
          this.guardNbOptionList = [...this.guardLastLocationData];
        } else {
          this.userEvents = this.userEvents?.concat(response?.data || []);

          this.userEvents?.forEach(
            (item: any, index: number) => (item.index = index + 1)
          );

          if (this.userEvents?.length) {
            this.showUserLocation(
              this.userEvents?.[this.previousUserLocationHistory]
            );
          }

          this.totalRowsUserLocationHistory = response['total_size'];
          this.totalPagesUserLocationHistory = Math.ceil(
            this.totalRowsUserLocationHistory / this.pageSizeUserLocationHistory
          );
          this.otherParamsUserLocationHistory = {
            paginationData: {
              totalRows: this.totalRowsUserLocationHistory,
              previousRows: this.previousUserLocationHistory,
              rows: this.pageSizeUserLocationHistory,
              pageNum: this.pageNumUserLocationHistory,
              totalPages: this.totalPagesUserLocationHistory,
            },
          };
        }

        this.spinnerService.hide();
        this.totalRows = response['total_size'];

        this.showMap = true;

        this.totalPages = Math.ceil(this.totalRows / this.pageSize);
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
        this.spinnerService.hide();
      }
    });
  }
  onUserSelection(userData: any) {
    this.showClockedInUsers = false;
    this.selectedGuard = userData;
    this.selectedEventType = 'user_location_history';

    this.dateRange = { ...this.defaultTimeRange };
    this.dateRangeValue = `${dateRangeValueConverter(
      this.dateRange?.start
    )} - \n ${dateRangeValueConverter(this.dateRange?.end)}`;
    this.getUserUpdateLog();
  }

  clearAll() {
    this.userEvents = [];
    this.totalPages = 0;
    this.selectedGuardLocationInfo = null;
    this.totalRowsUserLocationHistory = 0;
    this.previousUserLocationHistory = 0;
    this.pageSizeUserLocationHistory = 10;
    this.pageNumUserLocationHistory = 1;
    this.totalPagesUserLocationHistory = 0;
  }

  sortColumn(body: any) {
    this.sortKeys = body?.sortList || [];
    this.getUserOnline();
  }
  searchColumn(event: any) {
    this.searchParams = event?.searchParams || {};
    this.getUserOnline();
  }
  sortColumnUserLocation(body: any) {
    this.sortKeysUserLocationHistory = body?.sortList || [];
    this.getUserUpdateLog();
  }

  onChangePagination(event: any) {
    this.previous = event.previous;
    this.pageNum = event.pageNum;
    this.pageSize = event.pageSize;
    this.getUserOnline();
  }
  onChangeUserLocationHistoryPaginationMobile(event: any) {
    this.previousUserLocationHistory = event.previous;
    this.pageNumUserLocationHistory = event.pageNum;
    this.pageSizeUserLocationHistory = event.pageSize;
    this.getUserUpdateLog();
  }

  openRangePicker() {
    const dialogRef = this.dialogService.open(DatetimePickerComponent, {
      data: {
        dateRange: this.dateRange,
      },
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value !== 'close') {
        this.dateRange = value;
        this.clearAll();

        this.getUserUpdateLog();
        this.dateRangeValue = `${dateRangeValueConverter(
          this.dateRange?.start
        )} - \n ${dateRangeValueConverter(this.dateRange?.end)}`;
      }
    });
  }

  onGuardSelect(event: any) {
    if (!event) return;
    this.showMap = false;

    setTimeout(() => {
      window.scrollTo(0, 0);
      this.showMap = true;
      this.selectedGuardUser = event;

      this.guardLatestInfo?.forEach((user: any, index: number) => {
        if (user?.user_id === this.selectedGuardUser?.user_id) {
          this.selectedGuardIndex = index;
        }
      });
    }, 50);
  }
  onGuardSearch(event: any) {
    if (event.target.value?.length === 0) {
      this.guardNbOptionList = [...this.guardLastLocationData];
    } else {
      this.guardNbOptionList = [
        ...this.guardLastLocationData?.filter((item: any) =>
          item?.user_name
            ?.toLowerCase()
            .includes(event?.target?.value?.toLowerCase())
        ),
      ];
    }
  }
  showUserLocation(userData: any) {
    this.scrollPosition = window.scrollY;

    this.tableStyle = {
      'overflow': 'auto',
      'max-height': '35vh',
    };

    if (userData?.gps) this.mapLatLong = { ...userData?.gps };
    this.selectedGuardLocationInfo = userData;
  }
  nextLocation() {
    const currentItem = this.selectedGuardLocationInfo?.index;
    this.selectedGuardLocationInfo = null;

    if (currentItem + 1 > this.userEvents?.length) {
      this.previousUserLocationHistory = currentItem;

      this.getUserUpdateLog();
    } else {
      this.showUserLocation(this.userEvents?.[currentItem]);
    }
  }
  prevLocation() {
    const currentItem = this.selectedGuardLocationInfo?.index;
    this.selectedGuardLocationInfo = null;

    this.showUserLocation(this.userEvents?.[currentItem - 2]);
  }

  goBackButton() {
    if (this.selectedTab == this.tabTitles.tab1) {
      this.router.navigate(['/']);
    } else {
      if (this.showClockedInUsers) {
        this.router.navigate(['/']);
      } else {
        this.selectedGuard = null;
        this.showClockedInUsers = true;
        this.selectedGuardLocationInfo = {};
        this.getUserOnline();
      }
    }
  }
  getGuardLatestInfo() {
    this.userService
      .getUserUpdateLog({ event_type: 'guard_latest_info' })
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.guardLatestInfo = response?.data;
          this.guardLatestInfoBackup = response?.data;
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
          this.spinnerService.hide();
        }
      });
  }

  selectGuard(guard: any) {
    let selectedUser = this.guardLastLocationData?.find(
      (user: any) => user?.user_id === guard?.user_id
    );

    if (!selectedUser) {
      selectedUser = guard;
      this.guardLastLocationData.push(guard);
    }
    this.guardNbOptionList = [...this.guardLastLocationData];
    this.onGuardSelect(selectedUser);
  }
  filterGuardByStatus(key: any) {
    this.guardStatus[key]['selected'] = !this.guardStatus?.[key]?.['selected'];
    const selectedKeys = Object.keys(this.guardStatus)
      ?.filter((key: any) => this.guardStatus[key]?.selected)
      ?.map((key: any) => key);

    this.guardLatestInfo = [
      ...this.guardLatestInfoBackup?.filter((guard: any) =>
        selectedKeys?.includes(String(guard?.status))
      ),
    ];
  }
}
