import { Location } from '@angular/common';
import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isLargeScreen } from '../../../../../global.variable';
import { AppService } from '../../../../app.service';
import { AddEditClientComponent } from '../../../../shared/components/add-edit-client/add-edit-client.component';
import { AddEditSiteComponent } from '../../../../shared/components/add-edit-site/add-edit-site.component';
import { ClientSiteContactsComponent } from '../../../../shared/components/client-site-contacts/client-site-contacts.component';
import { ConfirmDialogComponent } from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ContactComponent } from '../../../../shared/components/contact/contact.component';
import { EventSchedulerComponent } from '../../../../shared/components/event-scheduler/event-scheduler.component';
import { ImagePreviewComponent } from '../../../../shared/components/image-preview/image-preview.component';
import { ModelDialogueService } from '../../../../shared/components/modal-dialogue/model-dialogue.service';
import { DataCheckService } from '../../../../shared/services/data-check.service';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { companyTableDetails } from '../../tableDetails';
import { ClientsService } from '../clients.service';

@Component({
  selector: 'gtapp-view-client',
  templateUrl: './view-client.component.html',
  styleUrl: './view-client.component.scss',
})
export class ViewClientComponent implements OnInit {
  customerForm: UntypedFormGroup = new UntypedFormGroup({
    is_individual: new UntypedFormControl(false, Validators.required),
    company_name: new UntypedFormControl(),
    address1: new UntypedFormControl('', Validators.required),
    address2: new UntypedFormControl('', Validators.required),
    address3: new UntypedFormControl(''),
    addressLookup: new UntypedFormControl(''),
  });

  @Input() detailData: any = [];

  hasJobs: boolean = false;
  hasCheckPoints: boolean = false;
  contactListTemp: any = [];

  siteData: any = [];

  clientId: Number = 0;
  selectedEvent: any;

  userEventDetailsTableView = companyTableDetails?.desktopTable;
  clientHistory: any = [];

  clientData: any;
  dialogref: any;

  siteCount: number = 0;

  selectedUsers: any = [];

  userListData: any = [];

  isAdmin: any;
  isDispatchUser: any;

  newInfromation: any;
  patrolReportSchedules: any = [];
  clientFiles: any = [];

  uploadFile: any;
  fileUploadError: any;
  fileDescription: any;

  slicedSiteData: any = [];
  slicedClientFiles: any = [];

  largeView: Boolean = isLargeScreen;

  constructor(
    private dialogService: ModelDialogueService,
    private clientService: ClientsService,
    private spinnerService: LoadingSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private appService: AppService,
    private dataCheckService: DataCheckService,
    private _location: Location,
    private viewContainerRef: ViewContainerRef,
    private toasterService: ToasterService
  ) {
    // WHEN USER CLICK THE BACK BUTTON
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.dialogref?.close();
      }
    });
    this.route.params.subscribe({
      next: (params) => {
        if (params?.['cKey']) {
          this.clientId = params?.['cKey'];
          this.getCustomerDetailById();
        }
      },
    });
  }

  ngOnInit(): void {
    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.isDispatchUser = this.dataCheckService.isDispatchUser();
  }
  formatData(response: any) {
    this.clientData = response;
    this.clientFiles = response['company_files'];
    this.slicedClientFiles = this.clientFiles?.slice(0, 3);
    this.detailData = response['data'];
    this.clientHistory = response['history'];
    this.hasJobs = Boolean(response?.data?.company_stats?.job_count);
    this.hasCheckPoints = Boolean(
      response?.data?.company_stats?.checkpoint_count
    );
    this.contactListTemp = response['contacts'] || [];
    this.siteCount = response?.data?.company_stats?.site_count;
    this.siteData = response['sites'] || [];
    this.slicedSiteData = this.siteData.slice(0, 3);
    this.userListData = response['users'].slice(0, 3) || [];

    this.patrolReportSchedules = response['patrol_report_schedules'] || [];
  }
  onTableRowClick(event: any) {
    this.selectedEvent = event;

    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 100);
  }

  getCustomerDetailById() {
    this.spinnerService.show();
    this.clientService
      .getCompanyDetailById(this.clientId)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.formatData(response);
        }
        this.spinnerService.hide();
      });
  }

  getCustomerContacts() {
    this.spinnerService.show();
    this.clientService
      .getCustomerContacts(this.clientId)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.contactListTemp = response['data'];
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
        }
      });
  }

  openClientContactTemplate() {
    if (this.isAdmin || this.isDispatchUser) {
      this.dialogref = this.dialogService.open(ClientSiteContactsComponent, {
        data: {
          clientData: this.detailData,

          selectedUsers: this.contactListTemp,
          contactType: 'lc',
        },
      });
      this.dialogref.afterClosed().subscribe((value: any) => {
        this.spinnerService.hide();
        this.getCustomerDetailById();
      });
    }
  }

  addNewSite() {
    this.dialogref = this.dialogService.open(AddEditSiteComponent, {
      data: {
        detailData: {
          company: this.detailData?.id,
          clientName: this.detailData?.company_name,
        },
      },
    });
    this.dialogref.afterClosed().subscribe((value: any) => {
      if (value !== 'close') {
        this.getCustomerDetailById();
      }
    });
  }
  openJobView() {
    this.router.navigate(['/jobs', { clientId: this.clientId }]);
    sessionStorage.setItem(
      'fromClient',
      JSON.stringify({
        client: {
          id: this.detailData?.id,
          company_name: this.detailData?.company_name,
        },
      })
    );
  }
  openCPView() {
    this.router.navigate(['/checkpoints', { clientId: this.clientId }]);
    sessionStorage.setItem(
      'fromClient',
      JSON.stringify({
        client: {
          id: this.detailData?.id,
          company_name: this.detailData?.company_name,
        },
      })
    );
  }

  openSiteView(siteData: any) {
    this.router.navigate(['/view-site', { sKey: String(siteData?.id) }]);
  }
  showAllSites() {
    this.router.navigate([
      '/sites',
      {
        clientId: this.clientId,
      },
    ]);
  }

  openEditTemplate() {
    this.dialogref = this.dialogService.open(AddEditClientComponent, {
      data: { updateView: true, companyData: this.detailData },
    });
    this.dialogref.afterClosed().subscribe((value: any) => {
      this.spinnerService.hide();
      if (value !== false) {
        this.formatData(value);
      }
    });
  }

  openJobReportContactTemplate() {
    this.selectedUsers = [];

    this.clientData?.users?.forEach((item: any) => {
      this.selectedUsers.push(item);
    });

    if (this.isAdmin || this.isDispatchUser) {
      const dialogRef = this.dialogService.open(ClientSiteContactsComponent, {
        data: {
          clientData: this.detailData,
          selectedUsers: this.selectedUsers,
        },
      });
      dialogRef.afterClosed().subscribe((res: any) => {
        this.getCustomerDetailById();
      });
    }
  }

  openSiteContactTemplate(data: any, clientData: any, contactType: any) {
    this.dialogref = this.dialogService.open(ContactComponent, {
      data: {
        contactData: data,
        clientData: clientData,
        contactType: contactType,
      },
    });
    this.dialogref.afterClosed().subscribe((dialogResponse: any) => {
      if (dialogResponse !== false) {
        this.getCustomerDetailById();
      }
    });
  }

  openSchedulerTemplate(schedule?: any) {
    this.dialogref = this.dialogService.open(EventSchedulerComponent, {
      data: { clientData: this.detailData, scheduleData: schedule },
    });
    this.dialogref.afterClosed().subscribe((value: any) => {
      if (value !== false) {
        this.formatData(value);
      }
    });
  }
  openInformationTemplate(template: any) {
    this.newInfromation = this.detailData?.information;
    this.dialogref = this.dialogService.open(
      template,
      {},
      this.viewContainerRef
    );
  }

  openFileUploadTemplate(template: any) {
    this.fileUploadError = null;
    this.fileDescription = null;
    this.dialogref = this.dialogService.open(
      template,
      {
        data: {},
      },
      this.viewContainerRef
    );
  }

  updateClientInformation() {
    this.spinnerService.show();
    this.clientService
      .updateClient(
        this.detailData?.id,
        {
          information: this.newInfromation,
        },
        { update_information: 1 }
      )
      .then((response: any) => {
        if (response['status'] == 'success') {
          this.formatData(response);
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
        this.spinnerService.hide();
      });
  }
  async fileCheck(fileExtension: string, fileSize: number) {
    let fileLimit: number = 1024 * 1024 * 8; // 8MB
    return new Promise((resolve) => {
      if (!['pdf', 'jpeg', 'jpg', 'png'].includes(fileExtension)) {
        this.fileUploadError = 'Unsupported file format';
        resolve(false);
      } else if (fileSize > fileLimit) {
        this.fileUploadError = 'File Too Large ' + fileSize + ' / ' + fileLimit;
        resolve(false);
      } else {
        this.fileUploadError = null;
        resolve(true);
      }
    });
  }
  async handleFileInput(event: any) {
    let file = event?.target?.files.item(0);
    let fileSize: number = file.size;
    let fileName: string = file.name;
    let fileExtension: string = fileName.split('.').pop() || '';
    let fileCheck = await this.fileCheck(fileExtension, fileSize);
    if (fileCheck) {
      this.uploadFile = event?.target?.files.item(0);
    }
  }

  onUploadFile() {
    if (this.uploadFile) {
      this.spinnerService.show();
      let fileData: FormData = new FormData();
      fileData.append('file', this.uploadFile);
      fileData.append('upload_files', '1');
      fileData.append('description', this.fileDescription);

      this.appService
        .formDataApi(`company/${this.detailData?.id}/update`, fileData)
        .then((response: any) => {
          if (response['status'] == 'success') {
            this.formatData(response);

            this.fileUploadError = null;
            this.fileDescription = null;
            this.uploadFile = null;
            this.spinnerService.hide();
          } else {
            this.fileUploadError = null;
            this.fileDescription = null;
            this.uploadFile = null;
            this.spinnerService.hide();
            this.toasterService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });
          }
        });
    }
  }
  onOpenFile(file: any) {
    if (file?.extension === '.pdf') {
      this.downloadFile(file);
    } else {
      this.dialogService.open(
        ImagePreviewComponent,
        {
          data: { imageSrc: file?.file_url, timeStamp: file.name },
        },
        this.viewContainerRef
      );
    }
  }

  downloadFile(file: any) {
    const a = document.createElement('a');
    a.href = file?.file_url;
    a.download = `${file?.name}${file?.extension}`;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  deleteClientFile(file: any) {
    this.clientService
      .updateClient(this.detailData?.id, {
        file_id: file?.id,
        delete_file: 1,
      })
      .then((response: any) => {
        if (response['status'] == 'success') {
          this.formatData(response);
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
        this.spinnerService.hide();
      });
  }
  deleteFile(file: any) {
    let dialogMsg = `Permanently delete file: ${file.description}`;
    this.dialogref = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: `Delete File`,
        message: dialogMsg,
      },
    });
    this.dialogref.afterClosed().subscribe((value: any) => {
      if (value === true) {
        this.spinnerService.show();

        this.deleteClientFile(file);
      }
    });
  }
  archiveUnarchive() {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: `${this.detailData?.is_active ? 'Delete' : 'Restore'}  Client `,
        message: `${
          this.detailData?.is_active
            ? 'Deleting this client will mean sites will become unavailable when adding new jobs.'
            : 'Restore client'
        } `,
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value === true) {
        this.spinnerService.show();
        this.clientService
          .enableDisableCompanyById(this.detailData?.id)
          .then((response: any) => {
            if (response?.status == 'success') {
              this.toasterService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
              this._location.back();
            } else {
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
            this.spinnerService.hide();
          });
      }
    });
  }

  shiftFocus(elementId: string, time?: number) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      element.focus();
    }, time);
  }

  selectFile() {
    var button: any = document.getElementById('fileInput');
    button.click();
  }
}
