import { Location } from '@angular/common';
import {
  Component,
  NgZone,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  getFormattedDateTime,
  getFormattedTime,
  isLargeScreen,
} from '../../../../../global.variable';
import { AppService } from '../../../../app.service';
import { AddEditIncidentTypeComponent } from '../../../../shared/components/add-edit-incident-type/add-edit-incident-type.component';
import { ConfirmDialogComponent } from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ImagePreviewComponent } from '../../../../shared/components/image-preview/image-preview.component';
import { ModelDialogueService } from '../../../../shared/components/modal-dialogue/model-dialogue.service';
import { WebcamImageComponent } from '../../../../shared/components/web-cam-image/web-cam-image.component';
import { DataCheckService } from '../../../../shared/services/data-check.service';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { IncidentsService } from '../incidents.service';

@Component({
  selector: 'gtapp-view-incident',
  templateUrl: './view-incident.component.html',
  styleUrl: './view-incident.component.scss',
})
export class ViewIncidentComponent implements OnInit {
  largeView = isLargeScreen;
  fetchingData: Boolean = true;

  incidentHistoryTableDetails: any = [
    {
      header: 'Date',
      name: 'updated_at',
      datetimeObj: true,
      sortKey: 'updated_at',
      dateRangeKey: 'updated_at',
    },
    {
      header: 'Update By',
      name: 'user_name',
      sortKey: 'user_name',
      searchKey: 'user_name',
    },

    {
      header: 'Update',
      name: 'action',
      sortKey: 'action',
      searchKey: 'action',
    },
  ];
  histChangeTableView: any = [
    {
      header: 'Date',
      name: 'updated_at',
      datetimeObj: true,
      sortKey: 'updated_at',
      dateRangeKey: 'updated_at',
    },
    {
      header: 'Update By',
      name: 'user_name',
      sortKey: 'user_name',
      searchKey: 'user_name',
    },
  ];
  tableStyle = {
    'overflow': 'auto',
    'max-height': '400px',
  };
  incidentId: any;

  detailData: any;

  isAdmin: boolean = false;
  isCreatedUser: boolean = false;
  isDispatcher: boolean = false;

  isIncidentSubmitted: boolean = false;
  dialogRef: any;
  newValue: any;
  previousTextValue: any;
  subscriberUserId: any;
  userData: any;

  incidentCommentFiles: any = [];
  sortedIncidentCommentFiles: any[] = [];
  incidentHistory: any = [];
  selectedUsers: any = [];
  userList: any = [];
  resolutionDay: any;
  resolutionTime: any;
  resolutionDateTime: any;

  selectedHistoryEvent: any;

  todayDate: any = new Date();
  minResDate: any;
  resDateTimeErrorMessage: any;

  lastInputLength: number = 0;
  callApi: boolean = true;
  previousdateTimeValue: any;
  dateTimeValue: any;
  newDateValue: any;
  newTimeValue: any;

  incidentTypeList: any;
  tempIncidentTypeList: any;
  previousincidentTypeValue: any;
  incidentTypeValue: any;

  emailRecipients: any;
  slicedUsersCount: any = 4;

  displayAllUsers: boolean = false;
  bottomSectionFixedHeight: string = '200 px';
  @ViewChild('resolTimeTemplateRef') resolTimeTemplateRef: any =
    TemplateRef<any>;
  promptSendReport: boolean = false;
  displayedEmailRecipients: any;
  showAll: any;
  sortOrder: string = 'desc';
  constructor(
    private spinnerService: LoadingSpinnerService,
    private appService: AppService,
    private dialogService: ModelDialogueService,
    private dataCheckService: DataCheckService,
    private incidentService: IncidentsService,
    private ngZone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    private viewContainerRef: ViewContainerRef,
    private toasterService: ToasterService
  ) {
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.dialogRef?.close();
      }
    });

    this.isAdmin = this.dataCheckService.isUserAdmin();
    this.isDispatcher = this.dataCheckService.isDispatchUser();

    this.route.params.subscribe({
      next: (params) => {
        if (params?.['inKey']) {
          this.incidentId = params?.['inKey'];

          this.getIncidentDetail();
        }
      },
    });
  }

  ngOnInit(): void {
    this.userData = this.appService.getUserData();

    this.subscriberUserId = this.userData?.subscriber?.subscriber_user_id;
    this.resolutionDateTime = new Date();
    this.resolutionDay = getFormattedDateTime();
    this.newDateValue = getFormattedDateTime();
    this.resolutionTime = getFormattedTime();
  }

  getIncidentDetail() {
    this.spinnerService.show();
    this.incidentService
      .retrieveIncident(this.incidentId)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.formatData(response);
          this.getAllUsersContacts();
          this.appService.updateOnBoardingValue('incident');
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }

        this.spinnerService.hide();
      });
  }
  formatData(response: any) {
    this.detailData = response?.data;

    this.incidentHistory = response?.history;
    this.emailRecipients = response?.recipient_emails?.join('<br> ');
    this.emailRecipients = response?.recipient_emails;
    this.updateDisplayedEmailRecipients();

    this.incidentCommentFiles = [
      ...response?.history?.filter((item: any) =>
        //1 = added comment
        //2 = take photo from camera
        //3 = comment changed
        //5 = corrective actions changed
        //7 = incident time changed
        //8 = incident report sent
        //9 = report re-sent
        //10 = incident type changed
        //11 = uploaded photo from device
        [2, 8, 9, 11].includes(item?.event_action)
      ),
    ];
    this.sortedIncidentCommentFiles = [...this.incidentCommentFiles].sort(
      (a, b) =>
        new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
    );

    this.isCreatedUser = Boolean(
      this.subscriberUserId === this.detailData?.subscriber_user?.id
    );

    this.isIncidentSubmitted = this.detailData?.submitted_at || false;
    this.spinnerService.hide();
    this.minResDate = new Date(this.detailData?.incident_time);
    this.minResDate.setDate(this.minResDate.getDate() - 1);
    this.ngZone.run(() => {
      setTimeout(() => {
        this.calculateFixedBottomHeight();
        this.spinnerService.hide();
      }, 100);
    });
    this.clearValues();
  }
  clearValues() {
    this.newValue = null;
    this.previousTextValue = null;
    this.callApi = true;
    this.lastInputLength = 0;
    this.dialogRef?.close();
  }

  openCommentTemplate(template: TemplateRef<any>, commentData?: any) {
    let dialogContext: any = {};
    if (commentData) {
      this.newValue = commentData?.detail;
      this.previousTextValue = this.newValue;
      dialogContext = commentData;
    }
    this.dialogRef = this.dialogService.open(
      template,
      {
        data: dialogContext,
      },
      this.viewContainerRef
    );
    this.dialogRef.afterClosed().subscribe((value: any) => {
      if (value) {
        this.formatData(value);
        this.dialogRef.close();
      }
    });
  }
  openExtraInfoTemplate(template: TemplateRef<any>, extrainfoType?: string) {
    this.newValue =
      extrainfoType == 'ca'
        ? this.detailData?.corrective_actions
        : this.detailData?.information;
    this.previousTextValue = this.newValue;

    this.dialogRef = this.dialogService.open(
      template,
      {
        data: { extrainfoType: extrainfoType },
      },
      this.viewContainerRef
    );
  }
  openHistoryChangeTemplate(template: TemplateRef<any>, data?: any) {
    this.dialogRef = this.dialogService.open(
      template,
      {
        data: data,
      },
      this.viewContainerRef
    );
  }
  openupdateIncidentTimeTemplate(template: TemplateRef<any>) {
    this.previousdateTimeValue = this.newDateValue;

    this.dialogRef = this.dialogService.open(
      template,
      {
        data: {},
      },
      this.viewContainerRef
    );
  }
  openPromptSendReport() {
    let dialogMsg = 'Send Report Now?';
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirmation Needed',
        message: dialogMsg,
      },
    });
    dialogRef.afterClosed().subscribe((dialogResponse) => {
      if (dialogResponse === true) {
        this.getAllUsersContacts();
        this.dialogService.open(
          this.resolTimeTemplateRef,
          {},
          this.viewContainerRef
        );
      }
    });
  }
  openupdateIncidentTypeTemplate(template: TemplateRef<any>) {
    this.getIncidentTypes();
    this.incidentTypeValue = this.detailData?.incident_type;
    this.previousincidentTypeValue = this.detailData?.incident_type;
    this.dialogRef = this.dialogService.open(
      template,
      {
        data: {},
      },
      this.viewContainerRef
    );
  }
  onSelectIncidentType(event: any) {
    if (event?.key === 0) {
      this.openAddIncidentTypeForm();
    }
    if (event?.id) {
      this.incidentTypeValue = event?.name;

      let body: any = {
        incident_id: this.detailData?.id,
        incident_type: event?.id,
      };

      if (body) {
        this.updateIncident(body);
      }
    }
  }

  openAddIncidentTypeForm() {
    const incidentTypeDialogRef = this.dialogService.open(
      AddEditIncidentTypeComponent,
      {
        data: {
          name: this.previousincidentTypeValue,
        },
      }
    );

    incidentTypeDialogRef.afterClosed().subscribe((value: any) => {
      if (value !== 'close') {
        this.onSelectIncidentType(value?.data);
      }
    });
  }
  mapsSelector(lat: any, lon: any) {
    if (
      /* if we're on iOS, open in Apple Maps */
      navigator.platform.indexOf('iPhone') != -1 ||
      navigator.platform.indexOf('iPad') != -1 ||
      navigator.platform.indexOf('iPod') != -1
    )
      window.open(`maps://maps.google.com/maps?daddr=${lat},${lon}&amp;ll=`);
    /* else use Google */ else
      window.open(`https://maps.google.com/maps?daddr=${lat},${lon}&amp;ll=`);
  }
  addComment(data?: any) {
    if (this.newValue && this.newValue.trim()) {
      this.spinnerService.show();
      let body: any = {
        incident_id: this.detailData?.id,
        comment: this.newValue,
      };
      if (Object.keys(data).length) {
        body.id = data?.id;
        body.edit_comment = 1;
      }
      if (body) {
        this.updateIncident(body);
      }
    }
  }
  updateIncident(body: any, params?: any) {
    this.spinnerService.show();
    this.incidentService.updateIncident(body, params).then((response: any) => {
      if (response?.status == 'success') {
        if (response?.data) {
          this.detailData = response['data'];

          if (this.promptSendReport && this.selectedUsers?.length) {
            this.promptSendReport = false;
            this.openPromptSendReport();
          }
          this.formatData(response);

          this.toasterService.setMessage({
            successMessage: response['message'],
            errorMessage: '',
          });
        }
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }

      this.spinnerService.hide();
    });
  }
  openCamPopup() {
    this.dialogRef = this.dialogService.open(WebcamImageComponent, {
      data: { showFrom: 'updateIncident' },
    });
    this.dialogRef.afterClosed().subscribe((value: any) => {
      if (value && value !== 'error') {
        this.onFileSubmit(value);
      } else if (value == 'error') {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: 'ERROR: Unable to access your camera',
        });
      }
    });
  }

  //Method to upload the photo
  onFileSubmit(imageData: any) {
    if (!imageData?.actualFileUpload) {
      this.toasterService.setMessage({
        successMessage: '',
        errorMessage: 'ERROR: Please add a photo',
      });
    } else {
      this.spinnerService.show('Uploading Details');
      let fileData: FormData = new FormData();

      fileData.append('file', imageData?.actualFileUpload);
      if (imageData?.newComment) {
        fileData.append('comment', imageData?.newComment);
      }
      let url = `incident/update`;
      fileData.append('incident_id', this.detailData?.id);
      fileData.append('image_source', imageData?.uploadType);

      this.appService.formDataApi(url, fileData).then((response: any) => {
        if (response?.data) {
          this.formatData(response);
        }

        this.spinnerService.hide();
      });
    }
  }
  onOpenImage(event: any, data: any) {
    var target = event.target || event.srcElement || event.currentTarget;
    var srcAttr = target.attributes.src;

    this.dialogRef = this.dialogService.open(ImagePreviewComponent, {
      data: { imageSrc: srcAttr.nodeValue, timeStamp: data.updated_at },
    });
  }
  redirectToClientPage() {
    this.router.navigate([
      '/view-client',
      { cKey: String(this.detailData?.company?.id) },
    ]);
  }

  updateExtraInfo(infoData: any) {
    let body: any = { incident_id: this.detailData?.id };
    body[
      infoData?.extrainfoType == 'ca' ? 'corrective_actions' : 'information'
    ] = this.newValue;
    if (body) {
      this.promptSendReport = Boolean(infoData?.extrainfoType == 'ca');

      this.updateIncident(body);
    }
  }
  updateincidentTime(infoData: any) {
    let body: any = { incident_id: this.detailData?.id };
    let dateTimeString = new Date(this.newDateValue);
    body['incident_time'] = dateTimeString;
    if (body) {
      this.updateIncident(body);
    }
  }

  getIncidentTypes(params?: any) {
    this.incidentService.fetchIncidentTypeList(params).subscribe((res: any) => {
      if (res['status'] == 'success') {
        this.incidentTypeList = res?.data?.filter(
          (_type: any) => _type?.id !== this.detailData?.incident_type_id
        );
      }
    });
  }
  onSearchIncidentType(event: any) {
    this.previousincidentTypeValue = event.target.value;
    if (
      event.target.value.length === 3 ||
      (event.target.value.length > 3 && this.incidentTypeList?.length)
    ) {
      this.getIncidentTypes({ search_str: event?.target?.value });
    }
  }
  getUniqueIncidents() {
    this.spinnerService.show();
    this.incidentService
      .getIncidents({}, { incident_type: 1 })
      .subscribe((response: any) => {
        if (response?.status === 'success') {
          this.incidentTypeList = response?.data;
          this.tempIncidentTypeList = response?.data;
        }
        this.spinnerService.hide();
      });
  }

  submitIncident() {
    this.spinnerService.show();
    let markedUSers = this.selectedUsers?.filter(
      (user: any) => !user?.isSnubbed
    );

    let body: any = {
      incident_id: this.detailData?.id,
      user_ids: [...new Set([...markedUSers].map((item) => item.pk))],
    };
    if (this.isIncidentSubmitted) {
      body.resend_report = true;
    } else {
      let dateTime = new Date(this.resolutionDateTime);
      body.resolution_time = dateTime;
      body.submit = true;
    }

    this.updateIncident(body);
  }
  formateStartDate(event: any) {
    this.resolutionDay = event.target.value;
    this.resolutionDateTime = event.target.value;
  }

  markResolved(template: TemplateRef<any>) {
    this.getAllUsersContacts();
    this.dialogRef = this.dialogService.open(
      template,
      {
        data: {},
      },
      this.viewContainerRef
    );
  }
  shiftFocus(elementId: string) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);

      element?.click();

      element?.focus();
    });
  }

  getAllUsersContacts() {
    this.incidentService
      .retrieveIncident(this.incidentId, { get_contacts: 1 })
      .subscribe((response: any) => {
        if (response.status === 'success') {
          this.selectedUsers = response.data;
          this.fetchingData = false;
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response.message,
          });
        }
      });
  }

  deletePhoto(incidentPhoto: any) {
    let dialogMsg = 'Delete Photo?';
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirmation Needed',
        message: dialogMsg,
      },
    });
    dialogRef.afterClosed().subscribe((dialogResponse) => {
      if (dialogResponse === true) {
        this.spinnerService.show();
        let body: any = {
          incident_id: this.detailData?.id,
          delete_photo: 1,
          incident_update_id: incidentPhoto?.id,
        };
        if (body) {
          this.updateIncident(body);
        }
      }
    });
  }
  setIncidentTime() {
    var abc: any;
    abc = new Date(this.detailData?.incident_time);
    abc.setHours(parseInt(abc.getHours()));
    abc.setMinutes(parseInt(abc.getMinutes()));
    abc.setSeconds(5);
    abc = abc.toLocaleTimeString('en-Gb', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      hourCycle: 'h23',
    });
    abc = abc.replace(':05', '');

    this.newTimeValue = abc;
  }
  formateNewStartDate(event: any) {
    this.newDateValue = event.target.value;
  }
  formatNewStartTime(event: any) {
    var [hours, minutes] = event.target.value.split(':');
    let startDateTime = new Date(this.dateTimeValue);
    startDateTime.setHours(parseInt(hours));
    startDateTime.setMinutes(parseInt(minutes));

    if (startDateTime.getTime() > new Date().getTime()) {
      this.toasterService.setMessage({
        successMessage: '',
        errorMessage: 'cannot set future date',
      });
    } else {
      this.newTimeValue = event.target.value;
      this.dateTimeValue = startDateTime;
    }
  }
  deleteIncident() {
    let dialogMsg = 'Delete Incident?';
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirmation Needed',
        message: dialogMsg,
      },
    });
    dialogRef.afterClosed().subscribe((dialogResponse) => {
      if (dialogResponse === true) {
        this.spinnerService.show();
        this.incidentService
          .deleteIncident(this.detailData?.id)
          .subscribe((response: any) => {
            if (response?.status == 'success') {
              this.toasterService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
              this.router.navigate(['/incidents']);
            } else {
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }

            this.spinnerService.hide();
          });
      }
    });
  }
  slicedData(data: any[]): any[] {
    return this.displayAllUsers ? data : data?.slice(0, this.slicedUsersCount);
  }
  hasUnsnubbedUsers(): boolean {
    return this.selectedUsers.some((user: any) => !user.isSnubbed);
  }
  calculateFixedBottomHeight() {
    // Get the bottom-section-fixed element by ID

    const bottomSectionFixed = document.getElementById('fixedBtn');

    if (bottomSectionFixed) {
      this.bottomSectionFixedHeight =
        bottomSectionFixed.offsetHeight.toString() + 'px';
    }
  }
  toggleSortOrder() {
    this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    this.sortUpdates();
  }
  sortUpdates(event?: any) {
    if (this.sortOrder === 'asc') {
      this.sortedIncidentCommentFiles = [...this.incidentCommentFiles].sort(
        (a, b) =>
          new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()
      );
    } else {
      this.sortedIncidentCommentFiles = [...this.incidentCommentFiles].sort(
        (a, b) =>
          new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
      );
    }
  }

  updateDisplayedEmailRecipients() {
    this.displayedEmailRecipients = this.showAll
      ? this.emailRecipients.join('<br>')
      : this.emailRecipients.slice(0, 5).join('<br>');
  }
  toggleShowAll() {
    this.showAll = !this.showAll;
    this.updateDisplayedEmailRecipients();
  }
  openSiteView(siteData: any) {
    this.router.navigate(['/view-site', { sKey: String(siteData?.id) }]);
  }
}
