import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceInfoService } from '../../../shared/services/device-info.service';

@Injectable({
  providedIn: 'root',
})
export class HolAndChatService {
  constructor(
    private http: HttpClient,
    private deviceInfoService: DeviceInfoService
  ) {}

  getHandOverLogs(params?: any) {
    return this.http.get(`/api/hand_over_log/`, {
      params: params,
    });
  }
  createHandOverLog(body?: any, params?: any) {
    return this.http.post(`/api/hand_over_log/`, body, {
      params: params,
    });
  }
  updateHandOverLog(id: string, body?: any, params?: any) {
    return this.http.put(`/api/hand_over_log/${id}/`, body, {
      params: params,
    });
  }
  retrieveHandOverLog(id: string, params?: any) {
    return this.http.get(`/api/hand_over_log/${id}/`, {
      params: params,
    });
  }

  // internal-messaging apis
  getUserMessageList(params: any) {
    return this.http.get('/api/gt_message/', { params: params });
  }
  sendMessage(body: any, params?: any) {
    return this.http.post('/api/gt_message/', body, { params: params });
  }
}
