<div class="d-flex flex-column flex-sm-row input-btn-group mb-2">
  <div class="flex-sm-grow-1 mb-2 mb-sm-0">
    <gtapp-auto-complete
      [data]="incidentTypeDropDownList"
      placeHolder="Search Incident Types"
      searchKeyword="name"
      [itemTemplate]="incidentTypeTemplate"
      (inputChanged)="onIncidentTypeLookup($event)"
      (selected)="openAddEditIncidentTypeComponent($event)"
      (submitted)="globalFilter = $event; fetchIncidentTypeList()"
    >
    </gtapp-auto-complete>
    <ng-template #incidentTypeTemplate let-item>
      <div>{{ item?.name }}</div>
    </ng-template>
  </div>
  <div class="ms-sm-2">
    <button
      class="btn btn-primary btn-lg w-100"
      (click)="openAddEditIncidentTypeComponent()"
    >
      + Add Incident Type
    </button>
  </div>
</div>

<div>
  <gtapp-card-list
    [mobileCardBackendData]="incidentTypeList"
    [mobileCardDetails]="cardDetails"
    [mobileActionIcons]="cardActionIcons"
    [otherParams]="otherParams"
    (actionClick)="deleteIncidentType($event?.event)"
    (rowClick)="openAddEditIncidentTypeComponent($event)"
    (paginationChange)="onChangePagination($event)"
  ></gtapp-card-list>
</div>
