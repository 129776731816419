<div>
  <gtapp-gt-table
    [desktopTableBackendData]="userGroupData"
    [desktopTableDetails]="[
      {
        header: 'Group',
        name: 'name'
      },
      {
        header: 'Description',
        name: 'description'
      }
    ]"
    (rowClick)="rowClick($event)"
  ></gtapp-gt-table>
  <ng-container *ngIf="selectedUserGroup">
    <div class="fw-bold text-center">
      {{ selectedUserGroup?.name }}
    </div>

    <div
      class="row row-cols-md-2 row-cols-xxl-3 row-cols-xxxl-6"
      *ngIf="selectedUserGroup?.users?.length"
    >
      <gtapp-gt-table
        [desktopTableBackendData]="selectedUserGroup?.users"
        [desktopTableDetails]="[
          {
            header: 'Full Name',
            name: 'full_name'
          },
          {
            header: 'Email',
            name: 'email'
          }
        ]"
        (rowClick)="openUserDialogBox($event)"
      ></gtapp-gt-table>
    </div>
  </ng-container>
  <ng-container *ngIf="largeView">
    <div class="mt-4 ps-3 fw-bold text-center">Event Logs</div>
    <gtapp-user-update-log [eventType]="'userGroup'"> </gtapp-user-update-log>
  </ng-container>
</div>
