<div>
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <a
        href="#missed-welfare"
        class="nav-link active"
        id="missed-welfare-tab"
        data-bs-toggle="tab"
        role="tab"
        aria-selected="true"
      >
        Missed Welfare Checks
      </a>
    </li>
  </ul>
</div>
<div class="tab-content" id="myTabContent">
  <div
    class="tab-pane active"
    id="missed-welfare"
    role="tabpanel"
    aria-labelledby="missed-welfare-tab"
  >
    <div class="mt-2">
      @if(largeView){

      <gtapp-gt-table
        [desktopTableBackendData]="missedWelfareData"
        [desktopTableDetails]="tableDetailMissedWelfareCheck"
        [otherParams]="otherParamsMissedWelfareCheck"
        (searchColumn)="searchColumnMissedWelfareCheck($event)"
        (sortColumn)="sortColumnMissedWelfareCheck($event)"
        (paginationChange)="onChangePaginationMissedWelfareCheck($event)"
        (rowClick)="openUserDetailTemplate($event)"
      >
      </gtapp-gt-table>

      }@else {
      <gtapp-card-list
        [mobileCardBackendData]="missedWelfareData"
        [mobileCardDetails]="mobileDetailMissedWelfareCheck"
        [otherParams]="otherParamsMissedWelfareCheck"
        (searchColumn)="searchColumnMissedWelfareCheck($event)"
        (sortColumn)="sortColumnMissedWelfareCheck($event)"
        (paginationChange)="onChangePaginationMissedWelfareCheck($event)"
        (rowClick)="openUserDetailTemplate($event)"
      ></gtapp-card-list>
      }
    </div>
  </div>
</div>
