<div>
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li
      class="nav-item"
      role="presentation"
      (click)="changeTab(licenseTab.tab1)"
    >
      <a
        class="nav-link"
        [ngClass]="selectedTab === licenseTab.tab1 ? 'active' : ''"
        >{{ licenseTab.tab1 }}</a
      >
    </li>
    <li
      class="nav-item"
      role="presentation"
      (click)="changeTab(licenseTab.tab2)"
    >
      <a
        class="nav-link"
        [ngClass]="selectedTab === licenseTab.tab2 ? 'active' : ''"
        >{{ licenseTab.tab2 }}</a
      >
    </li>
  </ul>
  <div class="tab-content" id="myTabContent">
    <div
      class="tab-pane"
      [ngClass]="selectedTab === licenseTab.tab1 ? 'active' : ''"
    >
      <div class="mt-4">
        @if(userDataList?.[0]?.length){
        <ng-container>
          <gtapp-gt-table
            [desktopTableUIData]="userDataList?.[0]"
            [desktopTableDetails]="licenceDetailsDesktopTable"
            [otherParams]="{ paginationData: true }"
            (rowClick)="openGuardInfo($event)"
          ></gtapp-gt-table>
        </ng-container>

        }@else {
        <div class="alert alert-primary" role="alert">
          No licences have been added by any of your guards.
        </div>
        }
      </div>
    </div>
    <div
      class="tab-pane"
      [ngClass]="selectedTab === licenseTab.tab2 ? 'active' : ''"
    >
      <div class="mt-4">
        @if(userDataList?.[1]?.length){
        <ng-container>
          <gtapp-gt-table
            [desktopTableUIData]="userDataList?.[1]"
            [desktopTableDetails]="unLicnesedGuardTableDetails"
            [otherParams]="{ paginationData: true }"
            (rowClick)="openGuardInfo($event)"
          ></gtapp-gt-table>
        </ng-container>

        }@else {
        <div class="alert alert-primary" role="alert">
          No Users without Licence.
        </div>
        }
      </div>
    </div>
  </div>
</div>

<ng-template #userLicensesTempRef let-data let-userLicensesTempRef="dialogRef">
  <div class="dialog-box card">
    <div class="card-header">
      Expiring {{ data?.month }}
      <span class="float-end" (click)="userLicensesTempRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div>
      <gtapp-gt-table
        [desktopTableUIData]="data?.data"
        [desktopTableDetails]="licenceDetailsDesktopTable"
        (rowClick)="openGuardInfo($event)"
      ></gtapp-gt-table>
    </div>
  </div>
</ng-template>
