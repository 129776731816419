import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../../app.service';
import { DialogRef } from '../../../../shared/components/modal-dialogue/model-dialogue.ref';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner.service';
import { PagesService } from '../../pages.service';
import { ClientsService } from '../clients.service';
import { ToasterService } from '../../../../shared/services/toaster.service';

@Component({
  selector: 'gtapp-file-import',
  templateUrl: './file-import.component.html',
  styleUrl: './file-import.component.scss',
})
export class FileImportComponent implements OnInit {
  uploadedFile: any;
  constructor(
    protected dialogRef: DialogRef,
    private appService: AppService,
    private spinnerService: LoadingSpinnerService,
    private clientService: ClientsService,
    private toasterService: ToasterService
  ) {}

  ngOnInit(): void {}
  downloadTemplate() {
    this.spinnerService.show();
    this.clientService.downloadTemplateFile().subscribe((response): any => {
      var downloadURL = window.URL.createObjectURL(<any>response);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = `Client Template.csv`;
      link.click();
      this.spinnerService.hide();
    });
  }
  onCloseDialogue() {
    this.dialogRef.close('close');
  }
  onFileSubmit() {
    if (this.uploadedFile) {
      this.spinnerService.show();
      let fileData: FormData = new FormData();
      fileData.append('file', this.uploadedFile);

      this.appService
        .formDataApi(`file_import`, fileData)
        .then((response: any) => {
          if (response['status'] == 'success') {
            this.toasterService.setMessage({
              errorMessage: response['message'],
            });
            this.dialogRef.close();
          } else {
            this.toasterService.setMessage({
              errorMessage: response['message'],
            });
          }
          this.spinnerService.hide();
        });
    }
  }

  handleFileInput(event: any) {
    this.uploadedFile = event?.target?.files.item(0);
  }
  uploadFile() {
    var button: any = document.getElementById('fileInput');
    button.click();
  }
}
