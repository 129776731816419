import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner.service';
import { JobsService } from '../jobs.service';

@Component({
  selector: 'gtapp-add-job',
  templateUrl: './add-job.component.html',
  styleUrl: './add-job.component.scss',
})
export class AddJobComponent implements OnInit {
  selectedStatus: any = {};
  fromRosterComponent: boolean = false;
  patrolJobName: string = '';

  constructor(
    private jobService: JobsService,
    private spinnerService: LoadingSpinnerService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe({
      next: (params) => {
        if (this.route.snapshot.fragment) {
          if (this.route.snapshot.fragment === 'roster') {
            this.fromRosterComponent = true;
            // this.router.navigate(['/add-job']);
          }
        }
      },
    });
  }

  jobTypes: any = [];

  ngOnInit(): void {
    this.getAllRequiredFieldsForAddJob();
  }
  //    IDENTITY_RESPONSE = 0, _("RESPONSE")
  //     IDENTITY_SERVICE = 1, _("SERVICE")
  //     IDENTITY_STATIC_GUARD = 2, _("STATIC GUARD")
  // IDENTITY_OTHERS = 3, _("OTHER")
  // IDENTITY_PATROL = 4, _("PATROL")

  //Method to Get Status, Priority,Service Type details for adding job
  getAllRequiredFieldsForAddJob() {
    this.spinnerService.show();
    let subscriberStats = JSON.parse(
      localStorage.getItem('subscriberStats') || '{}'
    );
    this.jobService.getJobParams().subscribe((response: any) => {
      if (response['status'] == 'success') {
        if (this.fromRosterComponent) {
          this.jobTypes = response?.data?.filter(
            (_type: any) =>
              _type?.type_identity !== 0 &&
              (_type?.type_identity === 4
                ? subscriberStats.checkpoint_count > 0
                : true)
          );
        } else {
          let responseType = response['data']?.find(
            (status: any) => status?.type_identity === 0
          );
          this.changeJobType(responseType);
        }

        this.spinnerService.hide();
      } else {
        this.spinnerService.hide();
      }
    });
  }

  changeJobType(service: any) {
    this.selectedStatus = service;
  }
}
