<div class="card dialog-box">
  <div class="card-header">
    Bulk Upload Clients
    <span class="float-end" (click)="onCloseDialogue()">
      <i class="fa-solid fa-xmark"></i>
    </span>
  </div>

  <table class="table">
    <thead>
      <tr>
        <th>Client Name</th>
        <th>Sub Address*</th>
        <th>Street Number</th>
        <th>Street Name</th>
        <th>City Name</th>
        <th>State**</th>
        <th>Post Code</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Guard Tracker</td>
        <td>Suite 2</td>
        <td>345</td>
        <td>PACIFIC HIGHWAY</td>
        <td>LINDFIELD</td>
        <td>NSW</td>
        <td>2070</td>
      </tr>
    </tbody>
  </table>

  <em>
    * Optional Field
    <br />
    ** Will only accept short form for State
  </em>

  <div>
    Australian Capital Territory-ACT,New South Wales-NSW ,Northern Territory-NT,
    Queensland-QLD,South Australia-SA, Tasmania-TAS, Victoria-VIC,Western
    Australia-WA
  </div>

  <hr />
  <div class="row row-cols-2 gy-2">
    <div class="col">
      <button
        class="btn btn-md w-100 btn-secondary"
        (click)="downloadTemplate()"
      >
        Download Template
      </button>
    </div>
    <div class="col">
      <button
        type="button"
        class="btn btn-md w-100 btn-secondary"
        (click)="uploadFile()"
      >
        {{ uploadedFile ? "Change File" : "Upload File" }}
      </button>

      <input
        #fileInput
        id="fileInput"
        style="display: none; cursor: pointer"
        type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        (change)="handleFileInput($event)"
      />
    </div>
    <div class="col">
      <button class="btn btn-lg w-100 btn-dark" (click)="onCloseDialogue()">
        Cancel
      </button>
    </div>
    <div class="col">
      <button
        class="btn btn-lg w-100 btn-primary"
        (click)="onFileSubmit()"
        [disabled]="!uploadedFile"
      >
        Upload {{ uploadedFile?.name }}
      </button>
    </div>
  </div>
</div>
