<div class="d-flex flex-column flex-sm-row input-btn-group mb-2">
  <div class="flex-sm-grow-1 mb-2 mb-sm-0">
    <gtapp-auto-complete
      [data]="lookupDropDownList"
      placeHolder="Search Logs"
      searchKeyword="description"
      [itemTemplate]="holTemplate"
      (inputChanged)="onSearchLookup($event)"
      (submitted)="globalFilter = $event; getHandOverLogs()"
    >
    </gtapp-auto-complete>

    <ng-template #holTemplate let-item>
      <div>{{ item?.description }}</div>
    </ng-template>
  </div>
  <div class="ms-sm-2">
    <button
      class="btn btn-primary btn-lg w-100"
      [routerLink]="['/add-hand-over-log']"
    >
      + Create New Log
    </button>
  </div>
</div>

<div>
  <gtapp-card-list
    [mobileCardBackendData]="handOverLogList"
    [mobileCardDetails]="userEventDetailsMobileView"
    [mobileActionIcons]="cardActionIcons"
    [otherParams]="otherParams"
    (actionClick)="deleteLog($event?.event)"
    (paginationChange)="onChangePagination($event)"
    (rowClick)="onRowClick($event)"
  ></gtapp-card-list>
</div>
