<div class="d-flex flex-column flex-md-row input-btn-group">
  <div class="flex-md-grow-1 mb-2 mb-md-0">
    <gtapp-auto-complete
      [data]="userPromptDropDownList"
      placeHolder="Search Prompt"
      searchKeyword="name"
      [itemTemplate]="userPromptLookupTemplate"
      (inputChanged)="onUserPromptLookup($event)"
      (selected)="getPromptDetail($event)"
    >
    </gtapp-auto-complete>
    <ng-template #userPromptLookupTemplate let-item>
      <div>{{ item?.name }}</div>
    </ng-template>
  </div>
  <div class="ms-md-2 mb-2 mb-lg-0">
    <button class="btn btn-primary btn-lg w-100" routerLink="/user-prompt">
      + Add Prompt
    </button>
  </div>

  <div class="ms-md-2 mb-2 mb-lg-0">
    <div class="row row-cols-2">
      <div class="col pe-0">
        <button
          class="btn btn-lg btn-block w-100"
          [ngClass]="visibleOnHomePage ? 'btn-primary' : 'btn-secondary'"
          type="button"
          (click)="visibleOnHomePage = !visibleOnHomePage; getAllUserPrompts()"
        >
          {{ visibleOnHomePage ? "Show All" : "Visible on Home" }}
        </button>
      </div>
      <div class="col">
        <select
          class="form-select form-select-lg w-100 h-100"
          [(ngModel)]="promptStatusSelected"
          (change)="getAllUserPrompts()"
        >
          <option
            [value]="option.key"
            *ngFor="let option of promptStatusOptions"
          >
            {{ option.value }}
          </option>
        </select>
      </div>
    </div>
  </div>
</div>

<div class="my-3">Prompts are used to show or collect information.</div>
<gtapp-card-list
  [mobileCardBackendData]="userPromptList"
  [mobileCardDetails]="{
    individualDetails: [
      {
        name: 'name',
        cardRowStyle: { 'font-weight': 'bolder' }
      }
    ]
  }"
  [mobileActionIcons]="cardIcons"
  [otherParams]="otherParamsUserPrompt"
  (actionClick)="actionClick($event, copyPromptTemplateRef)"
  (rowClick)="getPromptDetail($event)"
  (paginationChange)="onChangePagination($event)"
></gtapp-card-list>
<ng-template
  #copyPromptTemplateRef
  let-copyPromptTemplateRef="dialogRef"
  let-data
>
  <div class="card dialog-box">
    <div class="card-header d-flex justify-content-between align-items-center">
      Copy User Prompt
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="copyPromptTemplateRef.close()"
      ></button>
    </div>
    <div>
      <div class="form-floating my-3">
        <input
          type="text"
          class="form-control"
          [(ngModel)]="promptName"
          placeholder="Prompt Name"
          id="promptNameInput"
        />
        <label for="promptNameInput">Prompt Name</label>
      </div>
      <div class="row row-cols-2">
        <div class="col">
          <button
            type="button"
            class="btn btn-dark btn-lg w-100"
            (click)="copyPromptTemplateRef.close()"
          >
            Cancel
          </button>
        </div>
        <div
          id="confirmBtn"
          class="col"
          (click)="onConfirmCopy(data?.data?.id); copyPromptTemplateRef.close()"
        >
          <button type="button" class="btn btn-primary btn-lg w-100">
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
