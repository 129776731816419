<div class="p-1">
  <input
    #fileInput
    id="fileInput"
    style="display: none; cursor: pointer"
    type="file"
    accept="image/*"
    (change)="handleFileInput($event.target)"
  />
  <div>
    <div class="row">
      <!-- Image and buttons section -->
      <div class="col-12 col-lg-3">
        <div
          class="row gy-2 row-cols-lg-1"
          [ngClass]="{
            'row-cols-sm-2': imageUploaded,
            'row-cols-sm-1': !imageUploaded,
            'justify-content-center': !imageUploaded
          }"
        >
          <div
            [ngClass]="{
              'col col-7': imageUploaded,
              'col-6 text-center': !imageUploaded
            }"
            class="subscriber-logo-container"
          >
            <img
              [ngClass]="
                largeView ? 'subscriber-logo' : 'subscriber-logo-mobile'
              "
              src="{{ subscriberLogoUrl }}"
              alt="User-Profile-Image"
            />
          </div>
          <div *ngIf="imageUploaded" class="col col-5">
            <div class="row gy-2 row-cols-1">
              <div class="col">
                <button
                  type="button"
                  class="btn btn-secondary w-100 btn-lg"
                  (click)="openCamPopup()"
                >
                  Change Picture
                </button>
              </div>
              <div class="col">
                <button
                  type="button"
                  class="btn btn-dark w-100 btn-lg"
                  (click)="removeImage()"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="!companyDetails?.logo_url && isAdmin"
          class="row cursor-pointer"
        >
          <div class="col text-center">
            <button
              type="button"
              class="btn-primary btn-sm btn mt-2"
              (click)="openCamPopup()"
            >
              + Add Logo
            </button>
          </div>
        </div>
      </div>

      <!-- Company details and update button section -->
      <div class="col-12 col-lg-6 details-card">
        <dl>
          <dt>Company Name</dt>
          <dd
            (click)="isAdmin ? openTemplate(companyDialog) : ''"
            class="badge-value fw-bold"
          >
            {{ companyDetails?.name }}
          </dd>
        </dl>
        <dl>
          <dt>Address</dt>
          <dd class="badge-value">
            {{ companyDetails?.address?.full_address }}
          </dd>
        </dl>
        <div class="col" *ngIf="companyDetails?.phone">
          <dt>Phone</dt>
          <dd class="badge-value">{{ companyDetails?.phone }}</dd>
        </div>
        <dl>
          <dt>Email</dt>
          <dd class="badge-value">{{ companyDetails?.email }}</dd>
        </dl>
      </div>

      <!-- Update details button section -->
      <div class="col-12 col-lg-3">
        <div class="mt-3">
          <button
            type="button"
            class="btn btn-primary w-100 btn-lg"
            (click)="openTemplate(companyDialog)"
          >
            Update Details
          </button>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <dl>
        <dt class="fw-bold d-none d-md-inline-block">
          <span class="d-flex align-items-center">
            <span>Company Licence</span>
            <button
              type="button"
              class="ms-2 btn-primary btn-sm btn"
              (click)="addNewLicense()"
            >
              + Add Company Master Licence
            </button>
          </span>
        </dt>
      </dl>
      <button
        type="button"
        class="d-block d-md-none btn btn-primary w-100 btn-lg mb-2"
        (click)="addNewLicense()"
      >
        + Add Company Master Licence
      </button>
      <div>
        <gtapp-add-edit-license-details
          [licensesDataList]="companyLicenses"
          [showLicensesList]="true"
          (deleteLicense)="deleteLicense($event)"
        ></gtapp-add-edit-license-details>
      </div>
    </div>
    <ng-container *ngIf="isAdmin && largeView">
      <div class="mb-5"></div>
      <gtapp-user-update-log [eventType]="'subscriber'">
      </gtapp-user-update-log>
    </ng-container>
  </div>
</div>

<ng-template #companyDialog let-cpRef="dialogRef">
  <div class="card dialog-box">
    <div class="card-header">
      Company Details
      <span
        class="float-end"
        (click)="companyDialogueStep = 1; cpRef.close('close')"
      >
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <form [formGroup]="companyForm" gtappFormEnterAction>
      <div class="d-flex flex-column gap-2">
        <ng-container *ngIf="companyDialogueStep === 1">
          <div *ngIf="companyDetails?.name" class="form-floating">
            <input
              class="form-control"
              type="text"
              formControlName="name"
              placeholder="Company Name"
            />
            <label for="name">Company Name</label>
          </div>
          <div class="form-floating">
            <input
              class="form-control"
              type="text"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              formControlName="email"
              placeholder="Email"
            />
            <label for="email">Email</label>
          </div>
          <div class="form-floating">
            <input
              class="form-control"
              type="tel"
              minlength="10"
              maxlength="10"
              formControlName="phone"
              placeholder="Telephone"
            />
            <label for="phone">Telephone</label>
          </div>
          <div class="row row-cols-2">
            <div class="col">
              <button
                type="button"
                class="btn btn-lg btn-secondary w-100"
                (click)="companyDialogueStep = 1; cpRef.close('close')"
              >
                Cancel
              </button>
            </div>
            <div class="col">
              <button
                type="button"
                class="btn btn-lg btn-primary w-100"
                (click)="companyDialogueStep = 2"
              >
                Next
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="companyDialogueStep === 2">
          <gtapp-address-lookup [addressForm]="companyForm">
          </gtapp-address-lookup>
          <div class="row row-cols-2 gy-2">
            <div class="col">
              <button
                type="button"
                class="btn btn-lg btn-dark w-100"
                (click)="companyDialogueStep = 1"
              >
                Back
              </button>
            </div>
            <div class="col">
              <button
                type="button"
                class="btn btn-lg btn-primary w-100"
                id="submitbtn"
                [disabled]="!companyForm.valid"
                (click)="updateData(); cpRef.close(); companyDialogueStep = 1"
              >
                Submit
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </form>
  </div>
</ng-template>
