<div
  class="pt-2"
  [ngStyle]="{
    'padding-bottom': '80px'
  }"
>
  <div class="me-lg-2 mb-2 text-end" *ngIf="isAdmin">
    <button class="btn btn-sm btn-danger" (click)="deleteSite(selectedSite)">
      Delete Site
    </button>
  </div>
  <div class="details-card mb-4">
    <div class="row row-cols-1 row-cols-lg-2 mb-lg-3">
      <div class="col-lg-6">
        <div class="col row row-cols-2">
          <div class="col-12 col-lg-9">
            <div class="d-flex">
              <div class="flex-grow-1">
                <dl>
                  <dt>
                    Site Name
                    <span
                      (click)="isAdmin ? editSite(selectedSite) : null"
                      role="button"
                    >
                      <i class="fa-solid fa-pen-to-square" *ngIf="isAdmin"></i>
                    </span>
                  </dt>

                  <dd>{{ selectedSite?.company_name }}</dd>
                </dl>
              </div>
            </div>

            <dl>
              <dt>
                Site Address
                <span
                  class="ms-2"
                  (click)="isAdmin ? editSite(selectedSite, false) : ''"
                  role="button"
                >
                  <i
                    class="fa-solid fa-pen-to-square"
                    *ngIf="isAdmin && selectedSite?.address?.state_code"
                  ></i>
                </span>
              </dt>

              <dd>
                {{ selectedSite?.address?.full_address }}
              </dd>
            </dl>
            <dl>
              <dt>
                Client
                <span (click)="redirectToClientPage()" role="button"
                  ><i class="fa-solid fa-link"></i
                ></span>
              </dt>

              <dd>
                {{ selectedSite?.company?.company_name }}
              </dd>
            </dl>
          </div>
          <div class="col-12 col-lg-3 d-none d-lg-block" *ngIf="isAdmin">
            <div class="row row-cols-1 gy-2">
              <div class="col" (click)="openJobView()">
                <button class="btn btn-primary w-100 btn-md">
                  {{
                    selectedSite?.site_stats?.job_count > 0 ? "Jobs" : "Add Job"
                  }}
                </button>
              </div>
              <div class="col" (click)="openCPView()">
                <button class="btn btn-primary w-100 btn-md">
                  {{
                    selectedSite?.site_stats?.checkpoint_count > 0
                      ? "Checkpoints"
                      : "Add Checkpoint"
                  }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="col d-lg-none mb-4">
          <gtapp-map-leaflet
            [latLon]="{
              lat: selectedSite?.address?.latitude,
              lon: selectedSite?.address?.longitude
            }"
            [mapId]="'siteLocation'"
          ></gtapp-map-leaflet>
        </div>
        <div class="row row-cols-1 row-cols-lg-2">
          <ng-container *ngIf="isAdmin">
            <div>
              <dl>
                <dt>
                  Manager Notes
                  <span
                    (click)="openManagerInfoTemplate(requestManagerRef)"
                    role="button"
                  >
                    <i class="fa-solid fa-pen-to-square"></i>
                  </span>
                  <span
                    class="ms-2"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-title="These notes are only visible to managers"
                  >
                    <i class="fa-solid fa-circle-info"></i>
                  </span>
                </dt>

                <dd class="gt-multi-line">
                  {{ selectedSite?.manager_notes }}
                </dd>
              </dl>
            </div>
          </ng-container>
          <ng-container
            *ngIf="isAdmin || isDispatchUser || selectedSite?.information"
          >
            <div>
              <dl>
                <dt>
                  Site Information
                  <span
                    role="button"
                    (click)="
                      isAdmin || isDispatchUser
                        ? openInformationTemplate(requestRef)
                        : ''
                    "
                  >
                    <i
                      class="fa-solid fa-pen-to-square"
                      *ngIf="isAdmin || isDispatchUser"
                    ></i>
                  </span>
                </dt>

                <dd class="gt-multi-line">{{ selectedSite?.information }}</dd>
              </dl>
            </div>
          </ng-container>

          <ng-container
            *ngIf="isAdmin || isDispatchUser || selectedSiteFiles?.length"
          >
            <div>
              <dl>
                <dt
                  (click)="
                    isAdmin || isDispatchUser
                      ? openFileUploadTemplate(uploadRef)
                      : ''
                  "
                  role="button"
                >
                  {{
                    isAdmin || isDispatchUser
                      ? "Site Files [" +
                        selectedSiteFiles?.length +
                        " / 10 Max]"
                      : "Site Files"
                  }}
                  <span>
                    <i
                      class="fa-solid fa-plus-circle"
                      *ngIf="isAdmin || isDispatchUser"
                    ></i>
                  </span>
                </dt>

                <dd>
                  <table class="table table-hover">
                    <tbody>
                      <tr
                        *ngFor="let file of selectedSiteFiles"
                        class="cursor-pointer"
                      >
                        <td>
                          <div class="d-flex">
                            <div class="flex-grow-1" (click)="onOpenFile(file)">
                              {{ file.description }}
                            </div>
                            <div
                              class="d-flex align-items-center me-3"
                              *ngIf="isAdmin || isDispatchUser"
                              (click)="deleteFile(file)"
                            >
                              <i class="fa-regular fa-trash-can fa-2x"></i>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </dd>
              </dl>
            </div>
          </ng-container>
          <ng-container
            *ngIf="isAdmin || isDispatchUser || selectedSiteKeys?.length"
          >
            <div>
              <dl>
                <dt>
                  {{
                    isAdmin || isDispatchUser
                      ? "Site Keys [" + selectedSiteKeys?.length + " / 10 Max]"
                      : "Site Keys"
                  }}
                  <span
                    (click)="
                      (isAdmin || isDispatchUser) &&
                      selectedSiteKeys?.length < 10
                        ? openSiteKeysTemplate(siteKeyRef)
                        : ''
                    "
                    role="button"
                  >
                    <i
                      class="fa-solid fa-plus-circle"
                      *ngIf="
                        (isAdmin || isDispatchUser) &&
                        selectedSiteKeys?.length < 10
                      "
                    ></i>
                  </span>
                </dt>

                <dd>
                  <table class="table table-hover">
                    <tbody>
                      <tr *ngFor="let siteKey of selectedSiteKeys">
                        <td>
                          <i class="fa-solid fa-key me-2 text-warning"></i
                          >{{ siteKey }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- <div
              *ngFor="let siteKey of selectedSiteKeys"
              class="d-flex justify-content-between align-items-center card"
            >
              <span>
                {{ siteKey }}
              </span>
            </div> -->
                </dd>
              </dl>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="col-lg-6 d-none d-lg-block">
        <gtapp-map-leaflet
          [latLon]="{
            lat: selectedSite?.address?.latitude,
            lon: selectedSite?.address?.longitude
          }"
          [mapId]="'siteLocationLargeView'"
        ></gtapp-map-leaflet>
      </div>
    </div>
    <div class="row row-cols-1 row-cols-md-3" *ngIf="isAdmin">
      <div>
        <dl>
          <dt>
            Patrol Reports
            <span (click)="openSchedulerTemplate()" role="button">
              <i class="fa-solid fa-pen-to-square"></i>
            </span>
          </dt>

          <dd>
            <div
              class="alert alert-warning"
              *ngIf="patrolReportSchedules?.length < 1"
            >
              No one is receiving patrol reports for checkpoint scans.
            </div>
            <div
              *ngIf="patrolReportSchedules.length > 0"
              class="small text-secondary"
            >
              <em>Reports for site updates will be sent to:</em>
            </div>
            <table class="table table-hover">
              <tbody>
                <tr
                  *ngFor="let schedule of patrolReportSchedules"
                  class="cursor-pointer"
                  (click)="openSchedulerTemplate(schedule)"
                >
                  <td>
                    <div class="d-flex">
                      <div class="d-flex align-items-center me-3">
                        <i class="fa-solid fa-calendar-days fa-2x"></i>
                      </div>
                      <div class="flex-grow-1">
                        <div>{{ schedule?.name }}</div>

                        <div class="small-font">
                          {{ schedule?.schedule?.repeat_type | titlecase }}
                          &#64;
                          {{ schedule?.mail_time }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- <div
          *ngFor="let schedule of patrolReportSchedules"
          class="card"
          (click)="openSchedulerTemplate(schedule)"
        >
          <div class="fw-bold">{{ schedule?.name }}</div>

          <div class="small">
            {{ schedule?.schedule?.repeat_type | titlecase }} &#64;
            {{ schedule?.mail_time }}
          </div>
        </div> -->
          </dd>
        </dl>
      </div>
      <div>
        <dl>
          <dt>
            Job Reports
            <span (click)="openJobReportContactTemplate()" role="button">
              <i class="fa-solid fa-pen-to-square"></i>
            </span>
          </dt>

          <dd>
            <div
              class="alert alert-warning"
              *ngIf="slicedSiteReportUsers.length < 1"
            >
              No one is receiving reports for completed jobs.
            </div>
            <div
              *ngIf="slicedSiteReportUsers.length > 0"
              class="small text-secondary"
            >
              <em>Reports for completed jobs will be sent to:</em>
            </div>
            <table class="table table-hover">
              <tbody>
                <tr
                  *ngFor="let contact of slicedSiteReportUsers"
                  class="cursor-pointer"
                  (click)="openSiteContactTemplate(contact, selectedSite, 'jc')"
                >
                  <td>
                    <div class="d-flex">
                      <div class="d-flex align-items-center me-3">
                        <i class="fa-solid fa-address-book fa-2x"></i>
                      </div>
                      <div class="flex-grow-1">
                        {{ contact?.full_name }}
                        <div class="small-font">
                          {{ contact?.email }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- <div *ngFor="let contact of slicedSiteReportUsers">
            <div
              class="card"
              (click)="
                contact?.is_only_location_contact
                  ? openSiteContactTemplate(contact, selectedSite, 'jc')
                  : openUserDialogBox(contact)
              "
            >
              <div>
                {{ contact?.full_name }}
              </div>
              <div *ngIf="contact?.full_name != contact?.email" class="small">
                {{ contact?.email }}
              </div>
            </div>
          </div> -->
            <div>
              <button
                *ngIf="slicedSiteReportUsers?.length > 3"
                class="btn btn-primary btn-sm"
                (click)="
                  slicedSiteReportUsers?.length > 3
                    ? (slicedSiteReportUsers = slicedSiteReportUsers?.slice(
                        0,
                        3
                      ))
                    : (slicedSiteReportUsers = slicedSiteReportUsers)
                "
              >
                {{ siteReportUsers?.length > 3 ? "View Less" : "View All" }}
              </button>
            </div>
          </dd>
        </dl>
      </div>
      <div>
        <dl>
          <dt>
            Site Contacts
            <span (click)="openSiteLocationContactTemplate()" role="button">
              <i class="fa-solid fa-pen-to-square"></i>
            </span>
          </dt>

          <dd>
            <div
              *ngIf="!siteContactListTemp?.length"
              class="alert alert-warning"
            >
              No Contacts Have Been Added To This Site Yet
            </div>
            <div
              *ngIf="siteContactListTemp.length > 0"
              class="small text-secondary"
            >
              <em>Contacts assigned to the Site:</em>
            </div>
            <table class="table table-hover">
              <tbody>
                <tr
                  *ngFor="let contact of siteContactListTemp"
                  class="cursor-pointer"
                  (click)="openSiteContactTemplate(contact, selectedSite, 'lc')"
                >
                  <td>
                    <div class="d-flex">
                      <div class="d-flex align-items-center me-3">
                        <i class="fa-solid fa-address-book fa-2x"></i>
                      </div>
                      <div class="flex-grow-1">
                        {{ contact?.full_name }}
                        <div class="small-font">
                          {{ contact?.email }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <div class="row row-cols-auto gy-2">
          <div *ngFor="let contact of siteContactListTemp" class="col">
            <div
              class="card"
              (click)="openSiteContactTemplate(contact, selectedSite, 'lc')"
            >
              <div>
                {{ contact?.full_name }}
              </div>
              <div *ngIf="contact?.full_name != contact?.email" class="small">
                {{ contact?.email }}
              </div>
            </div>
          </div>
        </div> -->
          </dd>
        </dl>
      </div>
    </div>
  </div>
  <ng-container *ngIf="largeView && isAdmin">
    <gtapp-gt-table
      [desktopTableUIData]="selectedSite?.history"
      [desktopTableDetails]="userEventDetailsTableView"
      (rowClick)="onTableRowClick($event)"
    ></gtapp-gt-table>
  </ng-container>
  <ng-container *ngIf="selectedEvent">
    <gtapp-selected-event-detail
      [selectedEvent]="selectedEvent"
    ></gtapp-selected-event-detail>
  </ng-container>
</div>
<div class="bottom-section d-block d-lg-none" id="fixedBtn" *ngIf="isAdmin">
  <div>
    <ng-container>
      <div class="p-2">
        <div class="d-flex justify-content-between">
          <div
            (click)="openJobView()"
            class="d-flex align-items-center justify-content-center flex-fill me-1 border border-primary btm-btn-height"
          >
            <i class="fa-solid fa-briefcase fa-2x"></i>
          </div>
          <div
            (click)="openCPView()"
            class="d-flex align-items-center justify-content-center flex-fill ms-1 border border-primary btm-btn-height"
          >
            <i class="fa-solid fa-location-dot fa-2x"></i>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<!-- Client Information POPOVER / DIALOG -->
<ng-template #requestRef let-data let-requestRef="dialogRef">
  <div class="dialog-box card">
    <div class="card-header">
      Site Information
      <span class="float-end" (click)="requestRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>

    <!-- Request Detail Section start-->
    <div class="row">
      <div class="col">
        <textarea
          class="form-control"
          placeholder="Information about the site."
          cols="20"
          rows="5"
          wrap="hard"
          fullWidth
          type="text"
          [(ngModel)]="newInfromation"
        ></textarea>
      </div>
    </div>

    <div class="row row-cols-2 mt-4">
      <div class="col">
        <button class="btn btn-lg w-100 btn-dark" (click)="requestRef.close()">
          Cancel
        </button>
      </div>
      <div class="col">
        <button
          class="btn btn-lg w-100 btn-primary"
          (click)="requestRef.close(); updateSiteInformation()"
        >
          Update
        </button>
      </div>
    </div>

    <!-- Request Detail Section end-->
  </div>
</ng-template>

<!-- Client file Upload DIALOG -->
<ng-template #uploadRef let-data let-uploadRef="dialogRef">
  <div class="dialog-box card">
    <div class="card-header">
      Upload File
      <span
        class="float-end"
        (click)="
          uploadRef.close();
          fileUploadError = null;
          fileDescription = null;
          uploadFile = null
        "
      >
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>

    <div class="mt-3" *ngIf="fileUploadError">
      <div class="alert alert-warning">
        {{ fileUploadError }}
      </div>
    </div>
    <div class="col mb-2">
      <button
        type="button"
        class="btn btn-md w-100 btn-primary"
        (click)="selectFile()"
      >
        {{ uploadFile ? "Change File" : "Select File From Device" }}
      </button>

      <input
        #fileInput
        id="fileInput"
        style="display: none; cursor: pointer"
        type="file"
        accept=".pdf, .jpg, .jpeg, .png"
        (change)="handleFileInput($event)"
      />
    </div>

    <div class="form-floating mb-2">
      <input
        class="form-control"
        placeholder="File Description"
        fullWidth
        required
        maxlength="30"
        type="text"
        [(ngModel)]="fileDescription"
      /><label>File Description</label>
    </div>

    <div class="row row-cols-2 g-2 mt-4">
      <div class="col">
        <button
          class="btn btn-lg w-100 btn-dark"
          (click)="
            uploadRef.close();
            fileUploadError = null;
            fileDescription = null;
            uploadFile = null
          "
        >
          Cancel
        </button>
      </div>

      <div class="col">
        <button
          class="btn btn-lg w-100 btn-primary"
          [disabled]="!uploadFile || !fileDescription"
          (click)="onUploadFile(); uploadRef.close()"
        >
          Upload {{ uploadFile?.name }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #siteKeyRef let-data let-siteKeyRef="dialogRef">
  <div class="dialog-box card">
    <div class="card-header">
      Site Keys
      <span class="float-end" (click)="siteKeyRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>

    <div *ngFor="let siteKey of siteKeyList; let i = index; let l = last">
      <div class="d-flex mb-2">
        <div class="flex-grow-1 form-floating me-1">
          <input
            [id]="'siteKey' + i"
            class="form-control"
            placeholder="Key"
            fullWidth
            maxlength="30"
            type="text"
            [(ngModel)]="siteKey.value"
          /><label>Key {{ i + 1 }}</label>
        </div>

        <div *ngIf="i + 1 < siteKeyList.length">
          <button
            class="btn btn-danger h-100"
            [disabled]="!siteKeyList[i]?.value"
            (click)="deleteSiteKeyField(i)"
          >
            <i class="fa-regular fa-trash-can fa-2x"></i>
          </button>
        </div>
        <div>
          <button
            (click)="addNewSiteKeyField(i)"
            *ngIf="i < 10 && l"
            class="btn btn-primary h-100"
            [disabled]="i + 1 < siteKeyList?.length || !siteKeyList[i]?.value"
          >
            <i class="fas fa-plus-circle fa-2x"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="row row-cols-2 mt-3">
      <div class="col">
        <button class="btn btn-lg w-100 btn-dark" (click)="siteKeyRef.close()">
          Cancel
        </button>
      </div>

      <div class="col">
        <button
          [disabled]=""
          class="btn btn-lg w-100 btn-primary"
          (click)="siteKeyRef.close(); updateSiteKeys()"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</ng-template>

<!-- Manager Notes POPOVER / DIALOG -->
<ng-template #requestManagerRef let-data let-requestManagerRef="dialogRef">
  <div class="dialog-box card">
    <div class="card-header">
      Manager Notes
      <span class="float-end" (click)="requestManagerRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>

    <div class="mb-2">These notes are only visible to managers.</div>
    <!-- Request Detail Section start-->
    <div class="row mb-2">
      <div class="col">
        <textarea
          class="form-control"
          placeholder="Notes about the site"
          cols="20"
          rows="5"
          wrap="hard"
          fullWidth
          type="text"
          [(ngModel)]="newInfromation"
        ></textarea>
      </div>
    </div>

    <div class="row row-cols-2 g-2">
      <div class="col">
        <button
          class="btn btn-lg w-100 btn-dark"
          (click)="requestManagerRef.close()"
        >
          Cancel
        </button>
      </div>

      <div class="col">
        <button
          class="btn btn-lg w-100 btn-primary"
          (click)="requestManagerRef.close(); updateSiteManagerNotes()"
        >
          Update
        </button>
      </div>
    </div>

    <!-- Request Detail Section end-->
  </div>
</ng-template>
