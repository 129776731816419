<!--create data view-->
<ng-container *ngIf="!selectedHandOverLogData">
  <ng-container *ngIf="currentStep == 1">
    <div class="row row-cols-2 mb-4">
      <div class="col">
        <button
          type="button"
          class="btn btn-lg btn-dark w-100"
          [routerLink]="['/hand-over-log']"
        >
          Cancel
        </button>
      </div>
      <div class="col">
        <button
          type="button"
          class="btn btn-lg btn-primary w-100"
          [disabled]="selectedEventCount < 1 || !handOverLogDescription"
          (click)="currentStep = 2; getRecipients()"
        >
          Next
        </button>
      </div>
    </div>
    <div class="form-floating mb-2">
      <input
        autofocus
        class="form-control"
        type="text"
        placeholder="Description"
        autocomplete="off"
        required
        maxlength="60"
        [(ngModel)]="handOverLogDescription"
      />
      <label>Description</label>
    </div>
    <ng-container *ngIf="handOverDraftedData?.length">
      <!--selected/drafted events-->
      <div class="d-flex my-4 align-items-center">
        <div class="fw-semibold flex-grow-1">Drafted Events</div>
        <div><button class="btn btn-danger btn-sm">Cancel Draft</button></div>
      </div>
      <table class="table">
        <tbody *ngFor="let event of handOverDraftedData">
          <tr>
            <td>
              <input
                class="form-check-input"
                type="checkbox"
                [checked]="true"
                disabled
              />
            </td>
            <td>{{ event?.updated_at | customDate : "d MMM y HH:mm" }}</td>
            <td>{{ event?.detail }}</td>
            <td>{{ event?.company_name }}</td>
            <td>
              <span
                (click)="openEventDetailTemplate(eventDetailTemplateRef, event)"
              >
                <i class="fa-solid fa-circle-info"></i>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="fw-semibold my-4">Add More</div>
    </ng-container>
    <table class="table">
      <tbody *ngFor="let event of eventsDataList">
        <tr (click)="selectUnselectEvents(event)" class="cursor-pointer">
          <td>
            <input
              class="form-check-input"
              type="checkbox"
              [checked]="event?.selected"
            />
          </td>
          <td>{{ event?.updated_at | customDate : "d MMM y HH:mm" }}</td>
          <td>{{ event?.detail }}</td>
          <td>{{ event?.company_name }}</td>
          <td>
            <span
              (click)="
                openEventDetailTemplate(eventDetailTemplateRef, event);
                $event.stopImmediatePropagation()
              "
            >
              <i class="fa-solid fa-circle-info"></i>
            </span>
          </td>
        </tr>
      </tbody>

      <div
        class="mt-3"
        *ngIf="
          eventDataTotalPages > 1 &&
          eventDataTotalRows != eventsDataList?.length
        "
      >
        <button
          type="button"
          class="btn btn-secondary btn-lg"
          (click)="onLoadMoreEvents()"
        >
          Load More
        </button>
      </div>
    </table>
  </ng-container>
  <ng-container *ngIf="currentStep == 2">
    <div class="row row-cols-2 g-2 mb-3">
      <div class="col">
        <button
          type="button"
          class="btn btn-lg btn-dark w-100"
          (click)="recipientList = []; currentStep = 2"
        >
          Back
        </button>
      </div>
      <div class="col">
        <button
          type="button"
          class="btn btn-lg btn-primary w-100"
          (click)="createhandOverLog()"
        >
          Submit
        </button>
      </div>
    </div>
    <div class="mb-2">Hand Over To</div>

    <div *ngIf="recipientList.length > 10" class="mb-1">
      <gtapp-auto-complete
        [data]="recipientSearchList"
        placeHolder="Guard Name"
        searchKeyword="full_name"
        [itemTemplate]="assigneeListTemplate"
        (inputChanged)="searchAssignee($event)"
        (selected)="addSelectedAssignee($event)"
      >
      </gtapp-auto-complete>
    </div>

    <ng-template #assigneeListTemplate let-item>
      <div>
        {{ item?.full_name }}
      </div>
    </ng-template>
    <div class="overflow-assignne" id="assigneeList">
      <table class="table my-2">
        <tbody>
          <tr
            *ngFor="let assignee of recipientList"
            class="cursor-pointer"
            (click)="addRemovessignee(assignee)"
          >
            <td>
              <div class="d-flex">
                <div>
                  <div class="form-floating">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      [checked]="assignee?.selected"
                    />
                  </div>
                </div>
                <div class="flex-grow-1 ms-2">
                  {{ assignee?.full_name }}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      class="mt-3"
      *ngIf="
        handOverLogRecipientsTotalPages > 1 &&
        handOverLogRecipientsTotalCount != recipientList?.length
      "
    >
      <button
        type="button"
        class="btn btn-secondary btn-lg"
        (click)="onLoadMoreAssignees()"
      >
        Load More
      </button>
    </div></ng-container
  >
</ng-container>
<!--detailed data view-->
<ng-container *ngIf="selectedHandOverLogData">
  <dl>
    <dt>Description</dt>
    <dd>
      {{ selectedHandOverLogData?.description }}
    </dd>
  </dl>
  <dl>
    <dt>Log Events</dt>
    <dd>
      <table class="table">
        <tbody *ngFor="let event of selectedHandOverLogData?.events">
          <tr (click)="openEventDetailTemplate(eventDetailTemplateRef, event)">
            <td>{{ event?.updated_at | customDate : "d MMM y HH:mm" }}</td>
            <td>{{ event?.detail }}</td>
            <td>{{ event?.company_name }}</td>
            <td>
              <span>
                <i class="fa-solid fa-circle-info"></i>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </dd>
  </dl>
</ng-container>
<ng-template
  #eventDetailTemplateRef
  let-data
  let-eventDetailTemplateRef="dialogRef"
>
  <div class="dialog-box card">
    <div class="card-header">
      <span class="float-end" (click)="eventDetailTemplateRef.close()">
        <i class="fa-solid fa-xmark"></i>
      </span>
    </div>
    <div>
      <gtapp-selected-event-detail
        [selectedEvent]="data?.selectedEvent"
        selectedEventFrom="patrol"
        [userEventDetailsTableView]="userEventDetailsTableView"
      ></gtapp-selected-event-detail>
    </div>
  </div>
</ng-template>
