import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Location } from '@angular/common';
import {
  Component,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  getDistanceUsingHaversine,
  isLargeScreen,
} from '../../../../../global.variable';
import { AppService } from '../../../../app.service';
import { AddEditSiteComponent } from '../../../../shared/components/add-edit-site/add-edit-site.component';
import { ConfirmDialogComponent } from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ModelDialogueService } from '../../../../shared/components/modal-dialogue/model-dialogue.service';
import { SaveNewAddressComponent } from '../../../../shared/components/save-new-address/save-new-address.component';
import { DataCheckService } from '../../../../shared/services/data-check.service';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { ProfileService } from '../../../profile/profile.service';
import { ClientsService } from '../../clients/clients.service';
import { UserPromptService } from '../../user-prompts/user-prompt.service';
import { CheckpointService } from '../checkpoint.service';

@Component({
  selector: 'gtapp-view-checkpoint',
  templateUrl: './view-checkpoint.component.html',
  styleUrl: './view-checkpoint.component.scss',
})
export class ViewCheckpointComponent implements OnInit {
  mapLatLong = { lat: -33.88434016724868, lon: 151.19734676150136 };

  checkPointForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    company: new UntypedFormControl('', Validators.required),
    company_id: new UntypedFormControl('', Validators.required),
    site: new UntypedFormControl('', Validators.required),
    site_id: new UntypedFormControl('', Validators.required),
    addressLookup: new UntypedFormControl(''),
    min_distance: new UntypedFormControl(100, [
      Validators.max(1000),
      Validators.required,
    ]),

    description: new UntypedFormControl(),
    address1: new UntypedFormControl(),
    address2: new UntypedFormControl(),
    address3: new UntypedFormControl(),
    city_name: new UntypedFormControl(''),
    state_code: new UntypedFormControl(''),
    country_name: new UntypedFormControl(''),
    postcode: new UntypedFormControl(
      '',
      Validators.compose([Validators.required, Validators.max(99999999)])
    ),
    longitude: new UntypedFormControl('', [Validators.required]),
    latitude: new UntypedFormControl('', [Validators.required]),
    is_locked: new UntypedFormControl(false),
  });

  userData: any;
  tableStyle = {
    'overflow': 'auto',
    'max-height': '600px',
  };

  isAdmin: any;

  cpId: any;

  detailData: any;

  minKm: number = 5;
  maxKm: number = 1000;
  nearestKmValue = 100;
  backupMinDistanceValue: number = 0;
  // checkpoint history log
  checkpointHistoryData: any;
  checkpointHistoryTable = [
    {
      header: 'Action',
      name: 'action',
    },
    {
      header: 'Detail',
      name: 'detail',
    },
    {
      header: 'User',
      name: 'user_name',
    },
    {
      header: 'Updated At',
      name: 'updated_at',
      datetimeObj: true,
    },
  ];
  selectedHistoryEvent: any;
  dialogref: any;

  //validate checkpoint

  showMap: boolean = false;
  gps: any;
  originalLatLng: any = {};
  siteListData: any = [];
  searchSiteValue: string = '';
  dialogRef: any;
  pinIsFar: boolean = false;

  currentValidateStep: number = 1;
  currentEditCPStep: number = 1;

  // prompt variables
  selectedUserPrompts: any = [];
  availableUserPrompts: any = [];
  userPromptsRows: number = 5;
  userPromptsPrevious: number = 0;
  userPromptsPageNum: number = 1;
  userPromptsTotalPages: number = 0;
  userPromptsTotalCount: number = 0;
  userPromptsSearchResults: any = [];
  currentStep: number = 1;

  largeView: Boolean = isLargeScreen;
  constructor(
    private checkpointService: CheckpointService,
    private spinnerService: LoadingSpinnerService,
    private appService: AppService,

    private dialogService: ModelDialogueService,
    private dataCheckService: DataCheckService,
    private profileService: ProfileService,
    private _location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private clientService: ClientsService,
    private viewContainerRef: ViewContainerRef,
    private userPromptService: UserPromptService,
    private toasterService: ToasterService
  ) {
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.dialogref?.close();
      }
      this.updateGeoDistance();
    });
    this.userData = this.appService.getUserData();
    this.isAdmin = this.dataCheckService.isUserAdmin();

    this.route.params.subscribe({
      next: (params) => {
        if (params?.['cpKey']) {
          this.cpId = params?.['cpKey'];

          this.getCheckPointDetails();
        }
      },
    });
  }
  updateGeoDistance() {
    if (
      this.nearestKmValue !== this.backupMinDistanceValue &&
      this.nearestKmValue <= this.maxKm &&
      this.nearestKmValue >= this.minKm &&
      this.detailData?.id &&
      this.detailData?.min_distance
    ) {
      this.updateCP(this.checkPointForm);
    }
  }

  ngOnInit(): void {
    this.getUserPrompts();
  }

  redirect(location: string) {
    if (location === 'client') {
      this.router.navigate([
        '/view-client',
        { cKey: String(this.detailData?.company_id) },
      ]);
    } else {
      this.router.navigate([
        '/view-site',
        { sKey: String(this.detailData?.site_id) },
      ]);
    }
  }
  getCheckPointDetails() {
    let params: any = {};
    if (this.largeView) {
      params['additional_info'] = 1;
    }
    this.checkpointService
      .getCheckPointDetailsById(this.cpId, params)
      .subscribe((resp: any) => {
        this.formatData(resp);
      });
  }
  onCPHistoryClick(event: any) {
    this.selectedHistoryEvent = event;
    this.tableStyle = {
      'overflow': 'auto',
      'max-height': '35vh',
    };
  }

  mapsSelector(lat: any, lon: any) {
    if (
      /* if we're on iOS, open in Apple Maps */
      navigator.platform.indexOf('iPhone') != -1 ||
      navigator.platform.indexOf('iPad') != -1 ||
      navigator.platform.indexOf('iPod') != -1
    )
      window.open(`maps://maps.google.com/maps?daddr=${lat},${lon}&amp;ll=`);
    /* else use Google */ else
      window.open(`https://maps.google.com/maps?daddr=${lat},${lon}&amp;ll=`);
  }

  deleteCheckPoint(event: any) {
    let dialogMsg = `WARNING: This action cannot be reversed.`;
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: `Delete Checkpoint`,
        message: dialogMsg,
        alertDanger: true,
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value === true) {
        this.checkpointService
          .deleteCheckpointById(event.id)
          .then((response: any) => {
            this.toasterService.setMessage({
              successMessage: '',
              errorMessage: response['message'],
            });

            let subscriberStats = JSON.parse(
              localStorage.getItem('subscriberStats') || '{}'
            );
            subscriberStats.checkpoint_count =
              (subscriberStats?.checkpoint_count || 0) - 1;
            localStorage.setItem(
              'subscriberStats',
              JSON.stringify(subscriberStats)
            );
            this.router.navigate(['/checkpoints']);
          });
      }
    });
  }

  openTemplate(template: TemplateRef<any>) {
    this.currentValidateStep = 1;
    this.currentEditCPStep = 1;
    this.makeSelectionChanges();
    this.dialogref = this.dialogService.open(
      template,
      {
        data: {},
      },
      this.viewContainerRef
    );
    this.appService.initializeBootstrapTooltips();
  }
  ngOnDestroy(): void {
    sessionStorage.removeItem('geoFenceMinRadius');
  }
  formatData(response: any) {
    this.pinIsFar = false;
    this.showMap = false;
    this.detailData = response['data'];
    let btnValidateElement: any = document.querySelectorAll(
      '.btnValidateCheckpoint'
    );
    if (!this.detailData?.address?.state_code) {
      btnValidateElement.forEach((element: any) => {
        element.disabled = true;
      });
    }
    sessionStorage.setItem('geoFenceMinRadius', this.detailData?.min_distance);
    this.checkpointHistoryData = response['history'];

    this.originalLatLng.lat = this.detailData?.address?.latitude;
    this.originalLatLng.lon = this.detailData?.address?.longitude;
    this.selectedUserPrompts = this.detailData?.user_prompts;
    this.nearestKmValue = this.detailData?.min_distance;
    this.backupMinDistanceValue = this.detailData?.min_distance;

    this.checkPointForm.patchValue({
      name: this.detailData?.name,
      company: this.detailData?.company,
      company_id: this.detailData?.company_id,
      site: this.detailData?.site,
      site_id: this.detailData?.site_id,
      addressLookup: this.detailData?.address?.full_address,
      min_distance: this.detailData?.min_distance,
      description: this.detailData?.description,
      address1: this.detailData?.address?.address1,
      address2: this.detailData?.address?.address2,
      address3: this.detailData?.address?.address3,
      city_name: this.detailData?.address?.city_name,
      state_code: this.detailData?.address?.state_code,
      country_name: this.detailData?.address?.country_name,
      postcode: this.detailData?.address?.postcode,
      latitude: this.detailData?.latitude,
      longitude: this.detailData?.longitude,
      is_locked: this.detailData?.is_locked,
    });
    if (this.detailData?.longitude && this.detailData?.latitude) {
      this.gps = {
        lat: this.detailData?.latitude,
        lon: this.detailData?.longitude,
      };
    }
  }

  updateCP(form: any, validate = false) {
    let params: any = {};
    if (this.largeView) {
      params['additional_info'] = 1;
    }
    if (!this.detailData?.is_validated) {
      params['validate'] = 1;
    }
    this.spinnerService.show();
    this.checkpointService
      .updateCheckpoint(
        this.detailData?.id,
        {
          ...form.value,
          user_prompt_ids: [
            ...new Set([...this.selectedUserPrompts].map((item) => item?.id)),
          ],
        },
        params
      )
      .then((response: any) => {
        if (response['status'] == 'success') {
          this.formatData(response);
          this.dialogref?.close();
          this.spinnerService.hide();
          this.toasterService.setMessage({
            successMessage: response['message'],
            errorMessage: '',
          });
          if (validate) {
            if (response?.validate_qr_count) {
              this.router.navigate(['/checkpoints'], {
                fragment: 'validateQr',
              });
            } else {
              this.router.navigate(['/checkpoints']);
            }
          }
        } else {
          this.showMap = false;
          this.spinnerService.hide();
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }
  onClickScanHistory() {
    this.router.navigate(
      ['/user-events', { cpKey: String(this.detailData?.id) }],
      {
        fragment: 'checkpointScans',
      }
    );
  }

  shiftFocus(elementId: string, time?: number) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      element?.focus();
    }, time);
  }

  addressSelected(address: any) {
    if (address.key === 0) {
      this.addPlace();
    } else if (address && typeof address === 'object') {
      this.checkPointForm.controls['name'].setValue(
        address?.address1 + ' ' + address?.address2
      );

      this.gps = { lat: address?.latitude, lon: address?.longitude };
      this.checkPointForm.controls['address1'].setValue(address?.address1);
      this.checkPointForm.controls['addressLookup'].setValue(
        address?.address1 +
          ' ' +
          address?.address2 +
          ' ' +
          address?.city_name +
          ' ' +
          address?.state_code +
          ' ' +
          address?.postcode
      );

      this.checkPointForm.controls['address2'].setValue(address?.address2);
      this.checkPointForm.controls['address3'].setValue(address?.address3);
      this.checkPointForm.controls['city_name'].setValue(address?.city_name);
      this.checkPointForm.controls['postcode'].setValue(address?.postcode);
      this.checkPointForm.controls['state_code'].setValue(address?.state_code);
      this.checkPointForm.controls['country_name'].setValue(
        address?.country_name
      );
      this.checkPointForm.controls['latitude'].setValue(address?.latitude);
      this.checkPointForm.controls['longitude'].setValue(address?.longitude);

      this.showMap = true;
      var element = <HTMLInputElement>document.getElementById('addressSearch');
      if (element) {
        element.disabled = false;
        element.blur();
      }
    }
  }

  emitData(event: any, addCheck = false) {
    this.checkPointForm.controls['latitude'].setValue(event.lat);
    this.checkPointForm.controls['longitude'].setValue(event.lng);

    this.gps.lat = event.lat;
    this.gps.lon = event.lng == undefined ? event.lon : event.lng;
    if (addCheck) {
      let distanceInKm = Number(
        getDistanceUsingHaversine(
          this.originalLatLng.lat,
          this.gps.lat,
          this.originalLatLng.lon,
          this.gps.lon
        )
      );

      this.pinIsFar = distanceInKm > 1;
    }
  }
  updateMinDistance(event: any) {
    this.checkPointForm.controls['min_distance'].setValue(event);
  }
  addPlace(data = { showFrom: 'fullAddressRequired' }) {
    this.showMap = false;
    this.dialogref = this.dialogService.open(SaveNewAddressComponent, {
      data: data,
    });
    this.dialogref.afterClosed().subscribe((value: any) => {
      if (value != 'close') {
        if (value) {
          this.addressSelected(value);

          this.showMap = false;
        }
      }
    });
  }
  onSiteSearch(event: any) {
    this.searchSiteValue = event.target.value;
    if (
      event.target.value.length === 3 ||
      (event.target.value.length > 3 && this.siteListData?.length)
    ) {
      this.clientService
        .searchSites({
          search_str: event.target.value,
          is_active: 1,
        })
        .subscribe((res: any) => {
          if (res['status'] == 'success') {
            this.siteListData = res?.data?.splice(0, 10);
          }
        });
    }
  }
  onSiteSelect(data: any) {
    if (data?.key === 0) {
      this.openAddSiteForm();
      this.checkPointForm.controls['site_id'].setValue(null);
      this.checkPointForm.controls['site'].setValue(null);
    }
    if (data?.id) {
      this.checkPointForm.controls['site_id'].setValue(data.id);
      this.checkPointForm.controls['site'].setValue(data.company_name);

      if (data?.company) {
        this.checkPointForm.controls['company_id'].setValue(data?.company?.id);
        this.checkPointForm.controls['company'].setValue(
          data?.company?.company_name
        );
      }
    }
  }
  openAddSiteForm() {
    const dialogRef = this.dialogService.open(AddEditSiteComponent, {
      data: {
        detailData: { company_name: this.searchSiteValue },
      },
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data !== 'close') {
        this.onSiteSelect(data);
      }
    });
  }

  showMapTrue() {
    setTimeout(() => {
      this.showMap = true;
    });
  }
  resetValues() {
    this.pinIsFar = false;
    this.showMap = false;

    this.originalLatLng.lat = this.detailData?.address?.latitude;
    this.originalLatLng.lon = this.detailData?.address?.longitude;

    this.checkPointForm.patchValue({
      name: this.detailData?.name,
      company: this.detailData?.company,
      company_id: this.detailData?.company_id,
      addressLookup: this.detailData?.address?.full_address,
      min_distance: this.detailData?.min_distance,
      description: this.detailData?.description,
      address1: this.detailData?.address?.address1,
      address2: this.detailData?.address?.address2,
      address3: this.detailData?.address?.address3,
      city_name: this.detailData?.address?.city_name,
      state_code: this.detailData?.address?.state_code,
      country_name: this.detailData?.address?.country_name,
      postcode: this.detailData?.address?.postcode,
      latitude: this.detailData?.latitude,
      longitude: this.detailData?.longitude,
    });
    if (this.detailData?.longitude && this.detailData?.latitude) {
      this.gps = {
        lat: this.detailData?.latitude,
        lon: this.detailData?.longitude,
      };
    }
  }

  removeUserPrompt(userPromptData: any): void {
    this.selectedUserPrompts = this.selectedUserPrompts.filter(
      (userPromptItem: any) => userPromptItem?.id !== userPromptData?.id
    );
  }

  addUserPrompt(userPromptData: any): void {
    if (
      this.selectedUserPrompts.some(
        (userPromptItem: any) => userPromptItem?.id === userPromptData?.id
      )
    ) {
      this.toasterService.setMessage({
        errorMessage: 'Prompt Already added',
        successMessage: '',
      });
    } else {
      this.selectedUserPrompts.push(userPromptData);
    }
  }
  getPromptDetail(promptData: any) {
    this.router.navigate([
      '/user-prompt',
      {
        uPKey: String(promptData?.id),
      },
    ]);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
  searchPrompts(event: any) {
    if (event.target.value?.length > 2) {
      const params: any = { search_str: event.target.value };
      this.userPromptService
        .getUserPromptList(params)
        .subscribe((response: any) => {
          this.userPromptsSearchResults = response?.data?.filter(
            (value1: any) =>
              !this.selectedUserPrompts.some(
                (value2: any) => value1?.id === value2?.id
              )
          );
        });
    }
  }
  onLoadMore() {
    this.userPromptsPrevious = this.userPromptsPrevious + this.userPromptsRows;

    this.getUserPrompts();
  }
  getUserPrompts(event?: any) {
    let params: any = {};
    if (this.userPromptsRows) {
      params['rows'] = this.userPromptsRows;
    }
    if (this.userPromptsPrevious) {
      params['previous'] = this.userPromptsPrevious;
    }

    if (event?.target?.value) {
      params['name'] = event.target.value;
    }

    this.userPromptService
      .getUserPromptList(params)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          response?.data.forEach((item1: any) => {
            if (
              !this.availableUserPrompts.some(
                (item2: any) => item2?.id === item1?.id
              )
            ) {
              this.availableUserPrompts.push(item1);
            }
          });
          this.makeSelectionChanges();

          this.userPromptsTotalCount = response['total_size'];
          this.userPromptsTotalPages = Math.ceil(
            this.userPromptsTotalCount / this.userPromptsRows
          );
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
  }
  removeSelectedUserPrompt(deleteUserPrompt: any) {
    this.selectedUserPrompts = this.selectedUserPrompts?.filter(
      (savedPrompt: any) => savedPrompt?.id !== deleteUserPrompt?.id
    );

    setTimeout(() => {
      this.makeSelectionChanges();
      this.showMap = true;
    }, 100);
  }
  addSelectedUserPrompt(userPrompt: any) {
    this.showMap = false;
    if (
      this.selectedUserPrompts?.some(
        (savedPrompt: any) => savedPrompt?.id === userPrompt?.id
      )
    ) {
      this.toasterService.setMessage({
        errorMessage: 'User Prompt Already Selected',
        successMessage: '',
      });
    } else {
      this.selectedUserPrompts.push(userPrompt);
      if (
        !this.availableUserPrompts?.some(
          (savedPrompt: any) => savedPrompt?.id === userPrompt?.id
        )
      ) {
        this.availableUserPrompts.push(userPrompt);
      }
    }

    setTimeout(() => {
      this.makeSelectionChanges();
      this.showMap = true;
    }, 100);
  }
  addRemoveUserPrompt(userPrompt: any) {
    userPrompt.selected = !userPrompt?.selected;
    if (userPrompt.selected) {
      this.addSelectedUserPrompt(userPrompt);
    } else {
      this.removeSelectedUserPrompt(userPrompt);
    }
  }
  makeSelectionChanges() {
    this.selectedUserPrompts?.forEach((savedUserPrompt: any) => {
      if (
        !this.availableUserPrompts?.some(
          (userPrompt: any) => userPrompt?.id === savedUserPrompt?.id
        )
      ) {
        this.availableUserPrompts.push(savedUserPrompt);
      }
    });

    this.availableUserPrompts.forEach((userPrompt: any) => {
      userPrompt.selected = this.selectedUserPrompts?.some(
        (selectedUp: any) => selectedUp?.id === userPrompt?.id
      );
    });
    this.updateUserPromtOrder();
  }
  updateUserPromtOrder() {
    this.availableUserPrompts.sort((a: any, b: any) => {
      if (a.selected === b.selected) {
        return 0;
      }
      return a.selected ? -1 : 1;
    });
  }
  lockUnlock() {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Are you sure?',
        message: this.detailData?.is_locked
          ? 'This will unlock the qr checkpoint and will enable the guard to scan it from any place.'
          : 'This will lock the qr checkpoint and will force the guard to scan it inside the geofence.',
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value === true) {
        this.checkPointForm.controls['is_locked'].setValue(
          !this.checkPointForm.value?.is_locked
        );
        this.updateCP(this.checkPointForm);
      }
    });
  }
}
