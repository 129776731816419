import { Component } from '@angular/core';
import { cardDeleteActionIcon } from '../../../../global.variable';
import { AddEditIncidentTypeComponent } from '../../../shared/components/add-edit-incident-type/add-edit-incident-type.component';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ModelDialogueService } from '../../../shared/components/modal-dialogue/model-dialogue.service';
import { LoadingSpinnerService } from '../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { IncidentsService } from '../incidents/incidents.service';

@Component({
  selector: 'gtapp-incident-type',
  templateUrl: './incident-type.component.html',
  styleUrl: './incident-type.component.scss',
})
export class IncidentTypeComponent {
  globalFilter: any;
  cardDetails = {
    individualDetails: [
      {
        name: 'name',
        cardRowStyle: { 'font-weight': 'bolder' },
      },
    ],
  };
  incidentTypeCount: number = 0;
  previous: number = 0;
  pageSize: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;
  incidentTypeList: any = [];
  totalIncidentTypeCount: number = 0;

  dialogref: any;
  cardActionIcons = cardDeleteActionIcon;
  otherParams: any = {};
  incidentTypeDropDownList: any = [];
  constructor(
    private incidentService: IncidentsService,
    private spinnerService: LoadingSpinnerService,
    private dialogService: ModelDialogueService,
    private toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    this.spinnerService.show();
    this.fetchIncidentTypeList();
  }

  fetchIncidentTypeList() {
    let params: any = {};
    if (this.pageSize) {
      params['rows'] = this.pageSize;
    }
    if (this.previous) {
      params['previous'] = this.previous;
    }

    if (this.globalFilter) {
      params['search_str'] = this.globalFilter;
    }
    this.spinnerService.show();
    this.incidentService.fetchIncidentTypeList(params).subscribe((res: any) => {
      if (res['status'] == 'success') {
        this.incidentTypeCount = res['total_size'];
        this.otherParams.paginationData = {
          totalRows: this.incidentTypeCount,
          previousRows: this.previous,
          rows: this.pageSize,
          pageNum: this.pageNum,
          totalPages: this.totalPages,
        };
        this.incidentTypeDropDownList = [];
        this.totalIncidentTypeCount = res?.monitoring_company_count || 0;
        this.totalPages = Math.ceil(this.incidentTypeCount / this.pageSize);
        this.incidentTypeList = res?.data;
      }
      this.spinnerService.hide();
    });
  }
  onIncidentTypeLookup(event: any) {
    if (
      event.target.value.length === 3 ||
      (event.target.value.length > 3 && this.incidentTypeDropDownList?.length)
    ) {
      this.incidentService
        .fetchIncidentTypeList({ search_str: event.target.value })
        .subscribe((res: any) => {
          if (res['status'] == 'success') {
            this.incidentTypeDropDownList = res?.data;
          }
        });
    }
  }

  onChangePagination(event: any) {
    this.previous = event?.paginationData?.previousRows;
    this.pageNum = event?.paginationData?.pageNum;
    this.pageSize = event?.paginationData?.rows;
    this.fetchIncidentTypeList();

    this.otherParams.paginationData = { ...event?.paginationData };
  }

  openAddEditIncidentTypeComponent(data?: any) {
    this.dialogref = this.dialogService.open(AddEditIncidentTypeComponent, {
      data: { incidentTypeData: data },
    });
    this.dialogref.afterClosed().subscribe((value: any) => {
      if (value != 'close') {
        this.fetchIncidentTypeList();
      }
    });
  }
  deleteIncidentType(data: any) {
    let dialogMsg = 'WARNING: This action cannot be reversed.';
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Delete Incident Type',
        message: dialogMsg,
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value === true) {
        this.spinnerService.show();
        this.incidentService
          .deleteIncidentType(data.id, { delete: 1 })
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.fetchIncidentTypeList();
              this.spinnerService.hide();
              this.toasterService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });
            } else {
              this.spinnerService.hide();
              this.toasterService.setMessage({
                successMessage: '',
                errorMessage: response['message'],
              });
            }
          });
      }
    });
  }
  onCloseDialogue() {
    this.dialogref.close();
  }
}
