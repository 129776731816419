<div class="d-flex flex-column flex-sm-row input-btn-group mb-2">
  <div class="flex-sm-grow-1 mb-2 mb-sm-0">
    <gtapp-auto-complete
      [data]="companyDropDownList"
      placeHolder="Search Monitoring Companies"
      searchKeyword="company_name"
      [itemTemplate]="mCTemplate"
      (inputChanged)="onCompanyLookup($event)"
      (selected)="openEditCompanyForm($event)"
      (submitted)="globalFilter = $event; fetchMonitoringCompanyList()"
    >
    </gtapp-auto-complete>

    <ng-template #mCTemplate let-item>
      <div>{{ item?.company_name }}</div>
    </ng-template>
  </div>
  <div class="ms-sm-2">
    <button class="btn btn-primary btn-lg w-100" (click)="openAddCompanyForm()">
      + Add Company
    </button>
  </div>
</div>

<div>
  <gtapp-card-list
    [mobileCardBackendData]="monitoringCompanyList"
    [mobileCardDetails]="cardDetails"
    [mobileActionIcons]="cardActionIcons"
    [otherParams]="otherParams"
    (actionClick)="deleteCompany($event?.event)"
    (rowClick)="openEditCompanyForm($event)"
    (paginationChange)="onChangePagination($event)"
  ></gtapp-card-list>
</div>
