import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { cardDeleteActionIcon } from '../../../../../../global.variable';
import { ModelDialogueService } from '../../../../../shared/components/modal-dialogue/model-dialogue.service';
import { LoadingSpinnerService } from '../../../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../../../shared/services/toaster.service';
import { UsersService } from '../../../users/users.service';
import { HolAndChatService } from '../../hol-and-chat.service';

@Component({
  selector: 'gtapp-hand-over-log',
  templateUrl: './hand-over-log.component.html',
  styleUrl: './hand-over-log.component.scss',
})
export class HandOverLogComponent {
  previous: number = 0;
  pageSize: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;
  totalRows: number = 0;
  sortKeys: any = [];
  searchParams: any;
  otherParams: any = {};
  handOverLogList: any = [];

  handOverDraftedData: any = [];

  userEventDetailsMobileView: any = {
    individualDetails: [
      {
        nestedValue: (row: any) => {
          return !row?.read_at ? '[LOG NOT ACCEPTED YET]' : '';
        },
      },
      {
        name: 'updated_at',
        datetimeObj: true,
        header: 'Event Time',
        cardRowStyle: {
          'font-size': 'small',
        },
      },
      {
        name: 'description',
      },
      {
        prefix: 'Notes: ',
        name: 'event_count',
        cardRowStyle: {
          'font-size': 'small',
        },
      },

      {
        nestedValue: (row: any) => {
          return !row?.created_by_same_user
            ? `Sent By: ${row?.created_by}`
            : '';
        },

        cardRowStyle: {
          'font-size': 'small',
        },
      },

      {
        nestedValue: (row: any) => {
          return !row?.received_by_same_user
            ? `Sent To: ${row?.received_by}`
            : '';
        },

        cardRowStyle: {
          'font-size': 'small',
        },
      },
    ],
  };

  globalFilter: string = '';
  lookupDropDownList: any = [];
  cardActionIcons = cardDeleteActionIcon;
  constructor(
    private spinnerService: LoadingSpinnerService,
    private toasterService: ToasterService,
    private userService: UsersService,
    private handOverLogService: HolAndChatService,
    private dialogService: ModelDialogueService,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.getDraftedHandOverLog();
    this.getHandOverLogs();
  }
  getDraftedHandOverLog() {
    this.spinnerService.show();

    let body: any = {
      event_type: 'patrol',
      hand_over_log_drafted: 1,
    };

    this.userService.getUserUpdateLog(body).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.handOverDraftedData = response?.data;

        this.spinnerService.hide();
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
        this.spinnerService.hide();
      }
    });
  }
  getHandOverLogs() {
    this.spinnerService.show();

    let params: any = {};

    if (this.pageSize) {
      params['rows'] = this.pageSize;
    }
    if (this.previous) {
      params['previous'] = this.previous;
    }

    if (this.globalFilter) {
      params['name'] = this.globalFilter;
    }

    this.handOverLogService
      .getHandOverLogs(params)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.handOverLogList = response['data'];
          this.totalRows = response?.total_size;

          this.spinnerService.hide();

          this.otherParams.paginationData = {
            totalRows: this.totalRows,
            previousRows: this.previous,
            rows: this.pageSize,
            pageNum: this.pageNum,
            totalPages: Math.ceil(this.totalRows / this.pageSize),
          };
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
          this.spinnerService.hide();
        }
      });
  }
  onSearchLookup(event: any) {
    if (
      event.target.value.length === 3 ||
      (event.target.value.length > 3 && this.lookupDropDownList?.length)
    ) {
      this.handOverLogService
        .getHandOverLogs({ name: event.target.value })
        .subscribe((res: any) => {
          if (res['status'] == 'success') {
            this.lookupDropDownList = res?.data;
          }
        });
    }
  }
  onChangePagination(event: any) {
    this.previous = event?.paginationData?.previousRows;
    this.pageNum = event?.paginationData?.pageNum;
    this.pageSize = event?.paginationData?.rows;
    this.getHandOverLogs();

    this.otherParams.paginationData = { ...event?.paginationData };
  }
  onRowClick(data: any) {
    this.router.navigate([
      '/view-hand-over-log',
      { hKey: btoa(String(data?.id)) },
    ]);
  }

  deleteLog(data: any) {
    // let dialogMsg = 'WARNING: This action cannot be reversed.';
    // const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
    //   data: {
    //     title: 'Delete Company',
    //     message: dialogMsg,
    //   },
    // });
    // dialogRef.afterClosed().subscribe((value) => {
    //   if (value === true) {
    //     this.spinnerService.show();
    //     this.handOverLogService
    //       .updateHandOverLog(data.id, { delete: 1 })
    //       .subscribe((response: any) => {
    //         if (response['status'] == 'success') {
    //           this.getHandOverLogs();
    //           this.spinnerService.hide();
    //           this.toasterService.setMessage({
    //             successMessage: response['message'],
    //             errorMessage: '',
    //           });
    //         } else {
    //           this.spinnerService.hide();
    //           this.toasterService.setMessage({
    //             successMessage: '',
    //             errorMessage: response['message'],
    //           });
    //         }
    //       });
    //   }
    // });
  }
}
