import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  browserGpsInfo,
  getInitalDateRangeForListData,
  isLargeScreen,
  isLargeScreenFun,
} from '../../../../global.variable';
import { AppService } from '../../../app.service';
import { ModelDialogueService } from '../../../shared/components/modal-dialogue/model-dialogue.service';
import { DataCheckService } from '../../../shared/services/data-check.service';
import { LoadingSpinnerService } from '../../../shared/services/loading-spinner.service';
import { OrientationLockService } from '../../../shared/services/orientationlock.service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { CheckpointService } from '../checkpoints/checkpoint.service';
import {
  checkPointHistoryTable,
  checkPointScanTable,
  getDateTimeRange,
  userGroupTableDetails,
} from '../tableDetails';
import { UsersService } from '../users/users.service';

@Component({
  selector: 'gtapp-user-update-log',
  templateUrl: './user-update-log.component.html',
  styleUrl: './user-update-log.component.scss',
})
export class UserUpdateLogComponent implements OnInit {
  @Input()
  eventType: any;
  userEvents: any;

  selectedEvent: any;
  userEventDetailsMobileView: any = {
    individualDetails: [
      {
        name: 'updated_at',
        datetimeObj: true,
        dateRangeKey: 'updated_at',
        header: 'Event Time',
        ...getDateTimeRange(),
        sortKey: 'updated_at',
        cardRowStyle: {
          'font-size': 'small',
          'float': 'right',
        },
      },
      {
        name: 'user_name',
        cardRowStyle: {
          'font-size': 'small',
          'float': 'end',
        },
      },
      {
        name: 'action',
        cardRowStyle: {
          'font-size': 'small',
          'font-weight': 'bold',
          'text-transform': 'uppercase',
        },
      },
      {
        name: 'company_name',
        cardRowStyle: {
          'font-size': 'small',
        },
      },

      {
        name: 'detail',
        cardRowStyle: {
          'font-size': 'small',
        },
      },
    ],
  };
  tableStyle = {
    'overflow': 'auto',
    'max-height': '100%',
  };
  userEventDetailsTableView: any = [
    {
      header: 'Event Time',
      name: 'updated_at',
      datetimeObj: true,
      dateRangeKey: 'updated_at',
      sortKey: 'updated_at',
      ...getDateTimeRange(),
    },

    {
      header: 'Event Type',
      name: 'action',
    },
    {
      header: 'Company',
      name: 'company_name',
    },
    {
      header: 'Checkpoint',
      columnDataStyle: {
        'white-space': 'pre-line',
      },
      nestedValue: (row: any) => {
        return [0, 6].includes(row?.event_action)
          ? [row?.detail, row?.address].join('\n')
          : '';
      },
    },
    {
      header: 'Guard',
      name: 'user_name',
      sortKey: 'user_name',
      searchKey: 'user_name',
    },
  ];

  mapLatLong: any;
  uploadedImage: any;
  selectedEventType: string = 'patrol';
  sortKeys: any = [];
  searchParams: any;

  dateRange: any;
  apiFilter: boolean = true;
  scrollPosition: any;
  browserGpsInfo: any = browserGpsInfo;

  //pagination components
  previous: number = 0;
  pageSize: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;
  totalRows: number = 0;

  subscriberUserId: any;
  newComment: any;
  dialogref: any;

  previousComment: any;
  cpId: any;
  eventTitle = 'Patrol History';
  showMap: boolean = true;

  clockedInTime: any;
  otherParams: any = {};

  largeView: Boolean = isLargeScreen;
  isLargeScreenSubscription: Subscription = new Subscription();

  isAdmin = this.dataCheckService?.isUserAdmin();
  isDispatchUser = this.dataCheckService?.isDispatchUser();
  infiniteDataList: any = [];
  constructor(
    private userService: UsersService,
    private spinnerService: LoadingSpinnerService,
    private route: ActivatedRoute,
    private appService: AppService,
    private checkpointService: CheckpointService,
    private router: Router,
    private dialogService: ModelDialogueService,
    private viewContainerRef: ViewContainerRef,
    private toasterService: ToasterService,
    private orientationService: OrientationLockService,
    private dataCheckService: DataCheckService
  ) {
    let userData = this.appService.getUserData();
    this.subscriberUserId = userData?.subscriber?.subscriber_user_id;
    this.route.params.subscribe({
      next: (params) => {
        if (this.route.snapshot.fragment) {
          if (this.route.snapshot.fragment === 'checkpointScans') {
            this.eventTitle = 'Scan History';
            this.userEventDetailsMobileView = checkPointScanTable.mobileTable;
            this.userEventDetailsTableView = checkPointScanTable.desktopTable;
            this.apiFilter = false;
            if (params?.['cpKey']) {
              this.cpId = params?.['cpKey'];
            }
          } else if (this.route.snapshot.fragment === 'eventLog') {
            this.selectedEventType = 'user_update_history';
            this.sortKeys = ['-updated_at'];
            this.eventTitle = 'Event Log';

            const individualDetails = [
              {
                name: 'user_name',
                header: 'Guard',
                searchKey: 'user_name',
                autoComplete: 'full_name',
                cardRowStyle: {
                  'float': 'right',
                  'font-size': 'small',
                },
              },
              {
                name: 'updated_at',
                datetimeObj: true,
                dateRangeKey: 'updated_at',
                sortKey: 'updated_at',
                ...getDateTimeRange(2),
                cardRowStyle: {
                  'font-size': 'small',
                },
              },
              {
                name: 'event_type',
                cardRowStyle: {
                  'font-size': 'small',
                  'font-weight': 'bold',
                },
              },
              {
                name: 'action',
              },

              {
                nestedValue: (row: any) => {
                  let appendList = [];
                  if (row?.event_key) appendList.push(row?.event_key);
                  if (row?.site_name) appendList.push(row?.site_name);
                  if (row?.company_name) appendList.push(row?.company_name);
                  return appendList.join('\n');
                },
                cardRowStyle: {
                  'font-size': 'small',
                  'white-space': 'pre-line',
                },
              },
            ];
            const userEventDetailsTableView = [
              {
                header: 'Event Time',
                name: 'updated_at',
                datetimeObj: true,
                dateRangeKey: 'updated_at',
                sortKey: 'updated_at',
                ...getDateTimeRange(2),
              },
              {
                header: 'Event Type',
                name: 'event_type',
              },
              {
                header: 'Event Action',
                name: 'action',
              },
              {
                header: 'Update',
                name: 'detail',
              },
              {
                header: 'Site/Client',
                columnDataStyle: {
                  'white-space': 'pre-line',
                },

                nestedValue: (row: any) => {
                  let appendList = [];
                  if (row?.event_key) appendList.push(row?.event_key);
                  if (row?.site_name) appendList.push(row?.site_name);
                  if (row?.company_name) appendList.push(row?.company_name);
                  return appendList.join('\n');
                },
              },
              {
                header: 'Guard',
                name: 'user_name',
                searchKey: 'user_name',
                autoComplete: 'full_name',
              },
            ];

            if (!(this.isAdmin || this.isDispatchUser)) {
              individualDetails.splice(0, 1);
              userEventDetailsTableView.splice(
                userEventDetailsTableView?.length - 1,
                1
              );
            }

            this.userEventDetailsMobileView = {
              individualDetails: individualDetails,
            };

            this.userEventDetailsTableView = userEventDetailsTableView;
          }
        }
      },
    });
  }
  ngOnDestroy(): void {
    this.userEventDetailsMobileView = {};
    this.userEventDetailsTableView = [];
  }
  ngOnInit(): void {
    this.isLargeScreenSubscription =
      this.orientationService.isLargeScreen.subscribe(async (event: any) => {
        if (event) {
          this.largeView = await isLargeScreenFun();
        }
      });

    if (this.eventType) {
      if (this.eventType == 'userGroup') {
        this.selectedEventType = 'user_group';
        this.userEventDetailsMobileView = JSON.parse(
          JSON.stringify(userGroupTableDetails.mobileTable)
        );
        this.userEventDetailsTableView = JSON.parse(
          JSON.stringify(userGroupTableDetails?.desktopTable)
        );
      } else if (this.eventType == 'subscriber') {
        this.userEventDetailsMobileView = Object.freeze({
          individualDetails: [
            {
              name: 'action',
              cardRowStyle: {
                'font-weight': 'bolder',
                'float': 'right',
              },
            },
            {
              name: 'detail',
              cardRowStyle: {},
            },

            {
              name: 'updated_at',
              datetimeObj: true,
              dateRangeKey: 'updated_at',
              ...getDateTimeRange(5),
              cardRowStyle: {
                'font-size': 'small',
              },
            },

            {
              name: 'user_name',

              cardRowStyle: {
                'font-size': 'small',
              },
            },
          ],
        });
        this.userEventDetailsTableView = Object.freeze([
          {
            header: 'Event Time',
            name: 'updated_at',
            datetimeObj: true,
            dateRangeKey: 'updated_at',
            sortKey: 'updated_at',
            ...getDateTimeRange(),
          },

          {
            header: 'Action',
            name: 'action',
          },

          {
            header: 'Value',
            name: 'detail',
          },

          {
            header: 'Event By',
            name: 'user_name',
            sortKey: 'user_name',
            searchKey: 'user_name',
          },
        ]);
        this.selectedEventType = 'subscriber';
      } else if (this.eventType == 'checkpoint') {
        this.userEventDetailsMobileView = JSON.parse(
          JSON.stringify(checkPointHistoryTable.mobileTable)
        );
        this.userEventDetailsTableView = JSON.parse(
          JSON.stringify(checkPointHistoryTable.desktopTable)
        );
        this.selectedEventType = 'checkpoint';
      }
    }
    this.dateRange = getInitalDateRangeForListData(
      this.userEventDetailsMobileView?.individualDetails || []
    );
    this.getUserUpdateLog();
  }
  getUserUpdateLog() {
    this.spinnerService.show();

    let body: any = {
      event_type: this.selectedEventType,
      search_filters: {},
    };
    if (this.selectedEventType === 'user_update_history' && !this.dateRange) {
      this.dateRange = getInitalDateRangeForListData(
        this.userEventDetailsMobileView?.individualDetails || []
      );
    }

    if (this.dateRange) body.date_range = this.dateRange;
    if (this.sortKeys) body.sort_key = this.sortKeys;
    if (this.searchParams) body.search_filters = this.searchParams;
    if (this.pageSize) body.rows = this.pageSize;
    if (this.previous) body.previous = this.previous;
    if (this.cpId) body.search_filters.check_point_id = this.cpId;

    this.showMap = false;
    this.userService.getUserUpdateLog(body).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.totalRows = response?.total_size;
        this.selectedEvent = null;
        this.spinnerService.hide();
        this.showMap = true;
        this.otherParams.paginationData = {
          totalRows: this.totalRows,
          previousRows: this.previous,
          rows: this.pageSize,
          pageNum: this.pageNum,
          totalPages: Math.ceil(this.totalRows / this.pageSize),
        };

        this.userEvents = response['data'];
        let newList = response?.data;
        this.infiniteDataList = this.infiniteDataList.concat(newList);
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
        this.spinnerService.hide();
      }
    });
  }
  clearPagination() {
    this.previous = 0;
    this.pageSize = 10;
    this.pageNum = 1;
    this.totalPages = 0;
    this.totalRows = 0;
    this.infiniteDataList = [];
  }
  onTableRowClick(event: any) {
    this.scrollPosition = window.scrollY;
    this.selectedEvent = event;
    this.tableStyle = {
      'overflow': 'auto',
      'max-height': '35vh',
    };
    if (event?.gps) this.mapLatLong = { ...event?.gps };
    this.uploadedImage = event?.file;
  }

  sortColumn(body: any) {
    this.clearPagination();
    this.sortKeys = body?.sortList || [];
    this.searchParams = body?.searchParams || {};
    this.getUserUpdateLog();
  }
  searchColumn(event: any) {
    this.dateRange = event?.date;
    this.clearPagination();
    this.searchParams = event?.searchParams || {};
    this.getUserUpdateLog();
  }
  hideDetails() {
    this.selectedEvent = null;
    // let scrollPosition: any = window.localStorage.getItem('scrollToView');
    setTimeout(() => {
      window.scrollTo(0, this.scrollPosition);
    }, 100);
  }

  editComment(commentData: any, requestRef: TemplateRef<any>) {
    this.newComment = commentData?.detail;
    this.previousComment = commentData?.detail;
    this.dialogref = this.dialogService.open(
      requestRef,
      {
        data: commentData,
      },
      this.viewContainerRef
    );
  }
  addComment(data: any) {
    if (this.newComment && this.newComment.trim()) {
      this.spinnerService.show();
      let body = {
        company_id: data?.company_id,
        comment: this.newComment,
        id: data?.id,
        edit_comment: 1,
      };
      this.checkpointService.updateClient(body).then((response: any) => {
        this.getUserUpdateLog();
        this.newComment = '';
        this.selectedEvent = null;
        this.spinnerService.hide();
        window.location.reload();
      });
    }
  }

  onPaginationChange(data: any) {
    this.previous = data?.paginationData?.previousRows;
    this.pageNum = data?.paginationData?.pageNum;
    this.pageSize = data?.paginationData?.rows;

    this.getUserUpdateLog();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    this.otherParams.paginationData = { ...data?.paginationData };
  }
}
