<div>
  <!-- Nav/Tab section  -->
  <ul class="nav" [ngClass]="isAdmin ? 'nav-tabs' : ''">
    <li class="nav-item" (click)="changeTab(tabNames.tab1)">
      <a
        class="nav-link"
        [ngClass]="selectedTab === tabNames.tab1 ? 'active' : ''"
        >{{ tabNames.tab1 }}</a
      >
    </li>
    <!--QR Code section-->
    <li *ngIf="isAdmin" class="nav-item" (click)="changeTab(tabNames.tab2)">
      <a
        class="nav-link"
        [ngClass]="selectedTab === tabNames.tab2 ? 'active' : ''"
        >{{ tabNames.tab2 }}</a
      >
    </li>
  </ul>
</div>

@if(selectedTab === tabNames.tab1){
<gtapp-sites></gtapp-sites>

} @else if(selectedTab === tabNames.tab2){
<div>
  <div
    *ngIf="fileImportInProgress"
    class="alert alert-warning alert-dismissible fade show"
    role="alert"
  >
    <div class="fw-bold">
      File(s) import in progress ({{ fileImportInProgress }})
    </div>
  </div>

  <div
    *ngIf="showArchivedList"
    class="alert alert-primary fw-bold text-center my-3"
    role="alert"
  >
    Showing Deleted Clients
  </div>
  <div class="d-flex flex-column flex-md-row input-btn-group">
    <div class="ms-md-2 mb-2 mb-lg-0">
      <button
        class="btn btn-primary btn-lg w-100"
        (click)="openAddClientForm()"
      >
        + Add Client
      </button>
    </div>
    <div class="ms-md-2 mb-2 mb-lg-0" *ngIf="archivedCount">
      <button
        class="btn btn-secondary btn-lg w-100"
        (click)="getArchivedUnarchivedList()"
      >
        {{ showArchivedList ? "Show Active" : "Show Deleted" }}
      </button>
    </div>
    <div class="flex-md-grow-1 mb-2 mb-md-0 ms-lg-2">
      <gtapp-auto-complete
        [data]="clientDropDownList"
        placeHolder="Search Clients"
        searchKeyword="company_name"
        [itemTemplate]="clientLookupTemplate"
        (inputChanged)="onClientLookup($event)"
        (selected)="openCustomerView($event)"
        (submitted)="
          globalFilter = $event; clientPaginationData = {}; fetchCompanyList()
        "
      >
      </gtapp-auto-complete>
      <ng-template #clientLookupTemplate let-item>
        <div>{{ item?.company_name }}</div>
      </ng-template>
    </div>

    <div class="ms-md-2 d-none d-lg-block">
      <button
        class="btn btn-secondary btn-lg w-100"
        (click)="openUploadDialogBox()"
      >
        <i class="fa-solid fa-upload"></i> Bulk Upload
      </button>
    </div>
  </div>

  <div class="mt-3">
    <ng-container>
      <gtapp-gt-table
        [desktopTableBackendData]="clientPaginationData[pageNum]"
        [desktopTableDetails]="clientDetailsTableView"
        [tableStyle]="tableStyle"
        [otherParams]="otherParams"
        (sortColumn)="sortColumn($event)"
        (rowClick)="openCustomerView($event)"
        (searchColumn)="searchColumn($event)"
        (paginationChange)="onChangeTablePagination($event)"
      >
      </gtapp-gt-table>
    </ng-container>
  </div>
</div>
}
