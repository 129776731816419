import {
  Component,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { invoiceDataDetail, isLargeScreen } from '../../../../global.variable';
import { AppService } from '../../../app.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ModelDialogueService } from '../../../shared/components/modal-dialogue/model-dialogue.service';
import { LoadingSpinnerService } from '../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { SubscriptionService } from './subscription.service';
@Component({
  selector: 'gtapp-subscription',
  templateUrl: './subscription.component.html',
  styleUrl: './subscription.component.scss',
})
export class SubscriptionComponent implements OnInit {
  appPlans: any;
  subscriptions: any = [];
  expiredSubscriptions: any = [];
  currentSubscription: any;
  queuedSubscriptions: any = [];

  subscriptionInvoices: any = [];
  paymentCards: any = [];
  showPlans: boolean = false;
  hasActivePlan: boolean = true;
  trialAllowedUsers: number = 0;

  invoiceDetails: any = invoiceDataDetail.card;
  invoiceIcons = [
    {
      condition: function () {
        return {
          icon: 'fa-solid fa-download',
          status: 'primary',
          title: 'Download',
          type: 'download',
        };
      },
    },
  ];

  randomNumber: number = 0;
  showReasonText: boolean = false;
  cancelReason: any;
  feedbackOptions: any;
  largeView: Boolean = isLargeScreen;
  transactionEventHeaders = [
    { name: 'event_key', header: 'ID' },
    {
      name: 'updated_at',
      header: 'Date',
      datetimeObj: true,
      dateRangeKey: 'updated_at',
    },
    { name: 'client', header: 'Client' },
  ];
  transactionHeaders = [
    {
      header: 'Date',
      name: 'date',
    },
    {
      header: 'Transactions',
      name: 'transaction_count',
    },
  ];
  dateRange: any;
  subscriberTransactions: any;
  rowData: any;

  couponCode: any;
  showApplyCouponForm: boolean = false;
  isCouponApplied: boolean = false;
  errorCouponCode: boolean = false;
  showCouponInput: boolean = false;
  dataAfterCouponApplied: any;

  showPaymentForm: boolean = false;

  pageLoaded: boolean = false;
  firstTimeSubscriber: boolean = false;
  exBeta: boolean = false;

  isPWAApp: boolean = Boolean(
    window.matchMedia('(display-mode: standalone)').matches
  );

  queuedPlanData: any;
  queuedPlanDataCopy: any;

  allottedSlots: number = 1;

  selectedPlan: any = {};
  totalAddedUsers: any;
  addUser: boolean = false;
  changePlanFromGuardsPage: boolean = false;
  minAllottedSlots: number = 1;

  isTrialApplicable: boolean = false;

  queuedSelectedPlanData: any = {};
  isSubscriberLocked: boolean = false;
  tabNames = {
    tab1: 'Saved Cards',
    tab2: 'Invoices',
  };
  selectedTab = this.tabNames.tab1;
  deleteAccStep: number = 1;

  constructor(
    private appService: AppService,
    private spinnerService: LoadingSpinnerService,
    private subscriptionService: SubscriptionService,
    private dialogService: ModelDialogueService,
    private router: Router,
    private route: ActivatedRoute,
    private viewContainerRef: ViewContainerRef,
    private toasterService: ToasterService
  ) {
    this.route.params.subscribe({
      next: (params: any) => {
        if (this.route.snapshot.fragment) {
          this.addUser = this.route.snapshot.fragment === 'addUser';
          this.changePlanFromGuardsPage =
            this.route.snapshot.fragment === 'changePlan';
        }
      },
    });

    this.getValues();
  }

  ngOnInit(): void {
    this.isfirstTimeSubscriber();
  }
  isfirstTimeSubscriber() {
    const decodedToken: any = jwtDecode(
      this.appService.getUserData()?.user_token
    );
    this.firstTimeSubscriber =
      localStorage.getItem('firstTimeSubscriber') == 'true' ||
      (decodedToken?.subscriber?.test_user === false &&
        decodedToken?.subscriber?.subscription_count
          ?.total_subscription_count === 0);
  }

  getValues() {
    this.spinnerService.show();

    this.subscriptions = [];
    this.isCouponApplied = false;
    this.showApplyCouponForm = false;
    this.showCouponInput = false;
    this.couponCode = null;
    this.dataAfterCouponApplied = null;
    this.queuedPlanData = null;
    this.queuedPlanDataCopy = null;
    let promises = [
      this.getSubscribedPlans(),
      this.getPlans(),
      this.getSavedCards(),
      this.getInvoicesBySubscription(),
      this.getSubscriptionStatus(),
    ];

    Promise.all(promises).then(() => {
      if (this.showPlans && this.appPlans?.length == 1) {
        this.onPlanClick(this.appPlans[0]);
      }
      this.pageLoaded = true;
      setTimeout(() => {
        this.spinnerService.hide();
      }, 1000);
    });
  }
  getSubscriptionStatus() {
    this.subscriptionService
      .getSubscriberSubscriptionStatus()
      .subscribe((response: any) => {
        this.hasActivePlan = response['is_active'];
        this.exBeta = response['beta_user'];
        this.trialAllowedUsers = response['trial_allowed_users'];
        this.isTrialApplicable = response['is_trial_applicable'];
        this.isSubscriberLocked = response?.lock_plan || false;
      });
  }
  getSubscribedPlans() {
    this.queuedSelectedPlanData = {};
    this.subscriptionService.getHavingPlans().subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.totalAddedUsers = response['total_user_count'];
        this.subscriptions = response['data'];
        this.formatSubscriptionPage();
      }
    });
  }
  formatSubscriptionPage() {
    const expiredSubscriptions = [];
    const queuedSubscriptions = [];
    let currentSubscription = null;

    for (const subscription of this.subscriptions) {
      if (subscription.status === 'Expired') {
        expiredSubscriptions.push(subscription);
      } else if (subscription.status === 'Upcoming Plan') {
        queuedSubscriptions.push(subscription);
      }
      if (subscription.is_current_subscription) {
        currentSubscription = subscription;
      }
    }

    this.expiredSubscriptions = expiredSubscriptions;
    this.queuedSubscriptions = queuedSubscriptions;
    this.currentSubscription = currentSubscription;
    if (this.queuedSubscriptions?.length) {
      this.queuedSelectedPlanData = this.queuedSubscriptions[0];
    } else {
      if (
        this.currentSubscription?.plan?.id &&
        this.currentSubscription?.recurring_plan
      ) {
        if (
          this.currentSubscription?.plan?.reset_date &&
          new Date(this.currentSubscription?.plan?.reset_date).getTime() <=
            new Date(this.currentSubscription?.subscription_end).getTime()
        ) {
          this.queuedSelectedPlanData.plan =
            this.currentSubscription?.plan?.app_subscription_plan;
        } else if (
          this.currentSubscription?.app_coupon?.usage_count &&
          this.currentSubscription?.app_coupon?.repeatable_count &&
          this.currentSubscription?.app_coupon?.usage_count >=
            this.currentSubscription?.app_coupon?.repeatable_count
        ) {
          this.queuedSelectedPlanData.plan =
            this.currentSubscription?.plan?.selected_subscription_plan;
        }
      }
    }

    if (this.currentSubscription?.plan && this.addUser) {
      this.allottedSlots = this.totalAddedUsers;

      this.makePaymentView(this.currentSubscription?.plan);
    }
    this.showPlans = Boolean(!this.currentSubscription);

    if (this.currentSubscription) {
      // plan recommendation code logic
      const amtPayable =
        (this.currentSubscription?.app_coupon?.payable_amount
          ? this.currentSubscription?.app_coupon?.payable_amount
          : this.currentSubscription?.plan?.final_amount) +
        (this.totalAddedUsers > this.currentSubscription?.plan?.allowed_users
          ? (this.totalAddedUsers -
              this.currentSubscription?.plan?.allowed_users) *
            this.currentSubscription?.plan?.user_cost
          : 0);
      let amtSavable: number = 0;
      let selectedIndex: any;

      setTimeout(() => {
        this.appPlans?.forEach((plan: any, i: number) => {
          if (plan?.total_cost < amtPayable) {
            plan.amtSavable = amtPayable - plan?.total_cost;
            if (amtPayable - plan?.total_cost > amtSavable) {
              amtSavable = amtPayable - plan?.total_cost;
              selectedIndex = i;
            }
          }
        });
        if (selectedIndex >= 0) {
          this.appPlans[selectedIndex]['recommended'] = true;
        }
      }, 1000);
    }
  }
  getPlans() {
    this.subscriptionService.getSubscriptionPlans().subscribe((res: any) => {
      if (res['status'] === 'success') {
        this.appPlans = res['data'];
        if (this.changePlanFromGuardsPage) {
          this.router.navigate(['/subscription']);
          this.showPlans = true;
          this.changePlanFromGuardsPage = false;
        }
      }
    });
  }

  changeSubscription(plan?: any) {
    this.showApplyCouponForm = true;
    this.minAllottedSlots = Math.max(plan?.allowed_users, this.totalAddedUsers);
    this.allottedSlots = this.minAllottedSlots;
    this.queuedPlanData = {
      plan: plan,
    };
    this.queuedPlanDataCopy = JSON.parse(JSON.stringify(this.queuedPlanData));
  }
  queueSubscription() {
    this.spinnerService.show();
    var requestData = {
      plan_id: this.queuedPlanDataCopy?.plan.id,
      coupon_code: this.couponCode,
      allotted_slots: this.allottedSlots,
    };
    this.subscriptionService
      .createSubscription(requestData)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.hasActivePlan = true;
          this.getValues();

          this.showPlans = false;
          this.showPaymentForm = false;
          this.showApplyCouponForm = false;
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
          this.toasterService.setMessage({
            errorMessage: response['message'],
            successMessage: '',
          });
          this.showPaymentForm = false;
        }
      });
  }

  getSavedCards() {
    this.subscriptionService.getSavedCards().subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.paymentCards = response['data'];
      }
    });
  }

  changeSubscriptionRecursion(subscription: any) {
    let dialogMsg = '';
    let title = '';
    let status = '';
    if (subscription.recurring_plan) {
      dialogMsg =
        'Cancelling your subscription means we will not automatically charge your card and renew your subscription.';
      title = 'Cancel Subscription';
      status = 'cancel_recursion';
    } else {
      dialogMsg =
        'By turning on auto renew you give us permission to automatically charge your card and renew your subscription.';
      title = 'Auto Renew';
      status = 'reactivate_recursion';
    }

    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: title,
        message: dialogMsg,
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value === true) {
        this.spinnerService.show();
        this.subscriptionService
          .changeSubscriptionRecursion(subscription.id, status)
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.getValues();

              this.toasterService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
                timeOut: 1000,
              });
            } else {
              this.spinnerService.hide();
              this.toasterService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
            }
          });
      }
    });
  }

  getInvoicesBySubscription() {
    this.spinnerService.show();
    this.subscriptionService
      .getInvoicesBySubscription()
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.subscriptionInvoices = response['data'];
        }
      });
  }

  downloadInvoice(data: any) {
    this.spinnerService.show();
    this.subscriptionService
      .downloadInvoiceById(data.event.id)
      .subscribe((response): any => {
        var downloadURL = window.URL.createObjectURL(<any>response);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = `${data.invoice_reference}.pdf`;
        link.click();
        this.spinnerService.hide();
      });
  }

  makePaymentView(plan: any) {
    this.spinnerService.show();
    this.selectedPlan = plan;

    setTimeout(() => {
      this.showPaymentForm = true;
      this.spinnerService.hide();
    }, 100);
  }
  onPlanClick(event: any) {
    this.showPlans = false;
    this.allottedSlots = this.totalAddedUsers;
    this.currentSubscription
      ? this.changeSubscription(event)
      : this.makePaymentView(event);
  }

  deleteQueuedPlan(subscription: any) {
    let dialogMsg = 'Are you sure you want to cancel auto renew?';
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Cancel Auto Renew',
        message: dialogMsg,
      },
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value === true) {
        this.spinnerService.show();
        this.subscriptionService
          .deleteQueuedPlan({ subscription_id: subscription?.id })
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.spinnerService.hide();
              this.toasterService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });

              window.location.reload();
            } else {
              this.spinnerService.hide();
              this.toasterService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
            }
          });
      }
    });
  }
  onApplyCouponCode() {
    this.spinnerService.show();
    let body = {
      coupon_code: this.couponCode,
      plan_id: this.queuedPlanData?.plan?.id,
    };
    this.subscriptionService
      .applyCouponCode(body)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.toasterService.setMessage({
            successMessage: 'Coupon Applied',
            errorMessage: '',
          });

          this.isCouponApplied = true;
          this.dataAfterCouponApplied = response['data'];
          if (this.dataAfterCouponApplied.effective_plan) {
            this.queuedPlanData.plan =
              this.dataAfterCouponApplied?.effective_plan;
          }
        } else {
          this.toasterService.setMessage({
            successMessage: '',

            errorMessage: 'Invalid Coupon',
            timeOut: 1000,
          });
          this.errorCouponCode = true;
        }
        this.spinnerService.hide();
      });
  }
  forceUppercaseConditionally(event: any) {
    this.couponCode = event.target.value.toUpperCase();
  }
  removeAppliedCoupon() {
    this.isCouponApplied = false;
    this.couponCode = null;
    this.dataAfterCouponApplied = null;
    this.showCouponInput = false;
    this.queuedPlanData = JSON.parse(JSON.stringify(this.queuedPlanDataCopy));
  }

  getBillingDate(subscriptionData: any) {
    if (subscriptionData?.recurring_plan || this.queuedSelectedPlanData?.id) {
      const endDay = new Date(subscriptionData?.subscription_end);

      endDay.setDate(endDay.getDate() + 1);
      return endDay;
    } else {
      return subscriptionData?.subscription_end;
    }
  }
  changeCardStatus(cardData: any) {
    if (!cardData?.is_primary) {
      const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
        data: {
          title: 'Manage Payment Card',
          message: `Are you sure you want to make this the default payment card?`,
        },
      });

      dialogRef.afterClosed().subscribe((value: any) => {
        if (value === true) {
          this.spinnerService.show();
          this.subscriptionService
            .cardStatusChange(cardData.id)
            .subscribe((response: any) => {
              if (response['status'] == 'success') {
                this.spinnerService.hide();
                this.toasterService.setMessage({
                  successMessage: response['message'],
                  errorMessage: '',
                });
                this.paymentCards = response?.data;
                window.location.reload();
              } else {
                this.spinnerService.hide();
                this.toasterService.setMessage({
                  errorMessage: response['message'],
                  successMessage: '',
                });
              }
            });
        }
      });
    }
  }
  increment() {
    this.allottedSlots++;
  }

  decrement() {
    if (this.allottedSlots > 1) {
      this.allottedSlots--;
    }
  }
  cancelPaymentForm() {
    this.showPaymentForm = false;
    this.formatSubscriptionPage();
    if (this.addUser === true) {
      this.router.navigate(['/users']);
    }
  }
  getExtraUserCost(): number {
    const extraUsers =
      this.allottedSlots - this.queuedPlanData?.plan?.allowed_users;
    if (extraUsers <= 0) {
      return 0;
    }
    return extraUsers * this.queuedPlanData?.plan?.user_cost;
  }

  getTotalAmount(): number {
    let totalAmount = this.queuedPlanData?.plan?.final_amount;
    if (this.allottedSlots > this.queuedPlanData?.plan?.allowed_users) {
      totalAmount += this.getExtraUserCost();
    }
    if (this.dataAfterCouponApplied) {
      if (this.dataAfterCouponApplied?.details?.discount_amount) {
        totalAmount =
          totalAmount - this.dataAfterCouponApplied?.details?.discount_amount;
        this.dataAfterCouponApplied.applicableDiscount =
          this.dataAfterCouponApplied?.details?.discount_amount;
      }
      if (this.dataAfterCouponApplied?.details?.discount_percentage) {
        const discountPercentage =
          this.dataAfterCouponApplied.details.discount_percentage;
        const discountAmount = (totalAmount * discountPercentage) / 100;

        totalAmount -= discountAmount;
        this.dataAfterCouponApplied.applicableDiscount = discountAmount;
      }
    }
    return totalAmount;
  }
  getGSTAmount() {
    return (this.getTotalAmount() * 0.1).toFixed(2);
  }
  changeTab(tab: string) {
    this.selectedTab = tab;
  }

  // close account code
  randomInteger(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  deleteSubscriberTemplate(template: TemplateRef<any>) {
    this.subscriptionService.getFeedBackOptions().subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.feedbackOptions = response['data'];
      }
    });
    this.randomNumber = this.randomInteger(1000, 9999);
    this.dialogService.open(
      template,
      {
        data: {},
      },
      this.viewContainerRef
    );
  }
  deleteSubscriber() {
    if (this.cancelReason) {
      this.spinnerService.show();
      this.subscriptionService
        .deleteSubscriberAccount({ feedback: this.cancelReason })
        .subscribe((response: any) => {
          if (response['status'] == 'success') {
            this.spinnerService.hide();

            localStorage.clear();
            localStorage.setItem('from', 'deletion');
            this.router.navigate(['/delete-account']);
          } else {
            this.spinnerService.hide();
            this.toasterService.setMessage({
              errorMessage: response['message'],
              successMessage: '',
            });
          }
        });
    } else {
      this.toasterService.setMessage({
        errorMessage: 'Provide a reason for deletion of your account',
        successMessage: '',
      });
    }
  }
  checkValidation(event: any) {
    if (event?.target?.value == this.randomNumber) {
      this.deleteAccStep = 2;
    }
  }
  selectDeleteReason(event: any) {
    let reason = event?.target.value;
    if (reason === 'Other') {
      this.showReasonText = true;
      this.cancelReason = null;
    } else {
      this.showReasonText = false;
      this.cancelReason = reason;
    }
  }
  getRoundOffValue(value: any, cent = true) {
    return Math.round(value * 100 * (cent ? 100 : 1)) / 100;
  }
}
