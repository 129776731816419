import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ModelDialogueService } from '../../../../shared/components/modal-dialogue/model-dialogue.service';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner.service';
import { AddEditUserComponent } from '../add-edit-user/add-edit-user.component';
import { UsersService } from '../users.service';

@Component({
  selector: 'gtapp-licences',
  templateUrl: './licences.component.html',
  styleUrl: './licences.component.scss',
})
export class LicencesComponent implements OnInit {
  licenseTab = {
    tab1: 'Guard Licences',
    tab2: 'Unlicenced Guards',
  };

  unLicnesedGuardTableDetails = [
    {
      name: 'full_name',
      header: 'Guard',
      searchKey: 'full_name',
      sortKey: 'full_name',
    },
    {
      name: 'email',
      header: 'Email',
      searchKey: 'email',
      sortKey: 'email',
    },
  ];

  licenceDetailsDesktopTable = [
    {
      header: 'Licence',
      name: 'licence_class',
      searchKey: 'licence_class',
      sortKey: 'licence_class',
    },
    {
      header: 'State',
      name: 'licence_state',
      searchKey: 'licence_state',
      sortKey: 'licence_state',
    },
    {
      header: 'Guard',
      name: 'full_name',
      searchKey: 'full_name',
      sortKey: 'full_name',
    },
    {
      header: 'Expiry',
      name: 'licence_validity',
      dateRangeKey: 'licence_validity',
      nestedValue: (row: any) => {
        return formatDate(row?.licence_validity, 'd MMM Y', 'en');
      },
      sortKey: 'licence_validity',
    },
  ];
  userDataList: any = {};

  selectedTab: string = this.licenseTab.tab1;

  constructor(
    private userService: UsersService,
    private dialog: ModelDialogueService,
    private spinnerService: LoadingSpinnerService
  ) {}

  ngOnInit(): void {
    this.getUserList();
  }

  getUserList() {
    let params: any = { licenced_users: 1 };

    this.spinnerService.show();
    this.userService.getUserData(params).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.userDataList = response?.data;

        // 0 = licenced users
        // 1 = not licenced users
      }
      this.spinnerService.hide();
    });
  }

  openGuardInfo(guardData: any) {
    if (guardData.id) {
      this.dialog.open(AddEditUserComponent, {
        data: {
          editUser: true,
          detailData: guardData,
        },
      });
    }
  }

  changeTab(tab: any) {
    this.selectedTab = tab;
  }
}
