<div class="p-1">
  <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
    <div class="mt-2" *ngFor="let alert of sosAlerts; let i = index">
      <div class="card table-card-data pointer p-2">
        <div class="d-flex">
          <div class="flex-grow-1">
            <div class="fw-bold">
              {{ alert.user_name }}
            </div>
            <div class="small-font">
              {{ alert?.updated_at | customDate : "d MMM y HH:mm" }}
            </div>
          </div>
          <div (click)="showGps(i)" *ngIf="!alert?.showGps">
            <i class="fa-solid fa-circle-info"></i>
          </div>
        </div>

        <div
          [ngClass]="alert?.mobile_number ? 'row row-cols-2 mt-2' : 'row mt-2'"
        >
          <div
            (click)="
              selectedAlert = alert;
              openAlertTemplate(acknowledgeTemplate, alert)
            "
          >
            <button class="btn btn-primary btn-lg w-100 fw-bold">
              Acknowledge
            </button>
          </div>
          <div *ngIf="alert?.mobile_number">
            <a href="tel:{{ alert?.mobile_number }}" class="me-2">
              <button class="btn btn-success btn-lg w-100 fs-5 fw-bold">
                <i class="fa-solid fa-phone-volume text-light me-2"></i> CALL
              </button>
            </a>
          </div>
        </div>
        <ng-container *ngIf="alert?.showGps">
          <div class="mt-2">
            <gtapp-map-leaflet
              [latLon]="alert?.gps"
              [mapId]="alert?.userId"
            ></gtapp-map-leaflet>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

@if(largeView){
<gtapp-gt-table
  [desktopTableBackendData]="sosAlertHistoryData"
  [desktopTableDetails]="userEventDetailsTableView"
  [otherParams]="{ dateRange: dateRangeSOSAlertsHistory }"
  [tableStyle]="tableStyle"
  (rowClick)="onTableRowClickSOSAlertsHistory($event)"
  (sortColumn)="sortColumnSOSAlertsHistory($event)"
  (searchColumn)="searchColumnSOSAlertsHistory($event)"
></gtapp-gt-table>

<div class="mt-3">
  <gtapp-pagination-control
    [pageSize]="pageSizeSOSAlertsHistory"
    [pageNum]="pageNumSOSAlertsHistory"
    [previous]="previousSOSAlertsHistory"
    [totalRows]="totalRowsSOSAlertsHistory"
    (onClickPagination)="onChangePaginationSOSAlertsHistory($event)"
    (onChangePagination)="onChangePaginationSOSAlertsHistory($event)"
  >
  </gtapp-pagination-control>
</div>

}@else {
<gtapp-card-list
  *ngIf="!selectedEvent"
  [mobileCardBackendData]="sosAlertHistoryData"
  [mobileCardDetails]="userEventDetailsMobileView"
  (rowClick)="onTableRowClickSOSAlertsHistory($event)"
></gtapp-card-list
>}

<div
  #detailsSection
  class="row row-cols-1 row-cols-sm-1 row-cols-md-2 gt-p-small"
  *ngIf="selectedEvent"
>
  <div class="col-md-5">
    <div class="events-details-section">
      <ng-container>
        <h5>Event Details</h5>
        <div class="row row-cols-1 row-cols-xxl-2">
          <ng-container *ngFor="let indColumn of userEventDetailsTableView">
            <ng-container
              *ngIf="
                (indColumn.nestedValue
                  ? indColumn.nestedValue(selectedEvent)
                  : selectedEvent[indColumn.name]
                )?.trim()
              "
            >
              <dl>
                <dt class="text-uppercase">
                  {{ indColumn?.header }}
                </dt>
                <ng-container *ngIf="indColumn?.datetimeObj == true">
                  <dd>
                    {{
                      indColumn.nestedValue
                        ? (indColumn.nestedValue(selectedEvent)
                          | date
                            : (indColumn.dateTimeFormat
                                ? indColumn.dateTimeFormat
                                : "d MMM y HH:mm"))
                        : (selectedEvent[indColumn.name]
                          | date
                            : (indColumn.dateTimeFormat
                                ? indColumn.dateTimeFormat
                                : "d MMM y HH:mm"))
                    }}
                  </dd></ng-container
                >
                <ng-container *ngIf="!indColumn?.datetimeObj">
                  <dd>
                    {{
                      indColumn.nestedValue
                        ? indColumn.nestedValue(selectedEvent)
                        : selectedEvent[indColumn.name] == "null" ||
                          selectedEvent[indColumn.name] == "undefined"
                        ? "N/A"
                        : selectedEvent[indColumn.name]
                    }}
                  </dd></ng-container
                >
              </dl>
            </ng-container>
          </ng-container>
          <dl *ngIf="selectedEvent?.acknowledge_info?.updated_at">
            <dt>Guard has been contacted</dt>
            <dd>
              {{ selectedEvent?.acknowledge_info?.inquired ? "Yes" : "No" }}
            </dd>
          </dl>
          <dl *ngIf="selectedEvent?.acknowledge_info?.comment">
            <dt class="text-uppercase">Details and actions taken</dt>
            <dd>{{ selectedEvent?.acknowledge_info?.comment }}</dd>
          </dl>
        </div>
      </ng-container>

      <ng-container *ngIf="selectedEvent?.device_details">
        <h5>Device Details</h5>
        <div class="row row-cols-1 row-cols-xxl-2">
          <div *ngFor="let item of selectedEvent?.device_details | keyvalue">
            <dl *ngIf="item?.value">
              <dt class="text-uppercase">
                {{ item.key }}
              </dt>
              <dd>{{ item.value }}</dd>
            </dl>
          </div>

          <dl>
            <dt>
              LOCATION
              <span
                *ngIf="browserGpsInfo?.[selectedEvent?.gps?.source]?.description"
                class="ms-1"
                tabindex="0"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="{{browserGpsInfo?.[selectedEvent?.gps?.source]?.description}}"
              >
                <i class="fa-solid fa-circle-info"></i>
              </span>
            </dt>
            <dd>
              {{ selectedEvent?.gps?.lat }},
              {{ selectedEvent?.gps?.lon }}
            </dd>
          </dl>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="col-md-7">
    <gtapp-map-leaflet
      *ngIf="selectedEvent?.gps"
      [latLon]="selectedEvent?.gps"
      [mapId]="'event-location'"
    ></gtapp-map-leaflet>
    <div class="text-center" style="font-size: small">
      {{ selectedEvent?.gps?.lat }},
      {{ selectedEvent?.gps?.lon }}
    </div>
  </div>
</div>
<div *ngIf="selectedEvent && !largeView" class="pb-2 fixed-back-btn">
  <button class="btn btn-dark btn-lg w-100" (click)="selectedEvent = null">
    Back
  </button>
</div>
<ng-template #acknowledgeTemplate let-acknowledgeTemplate="dialogRef">
  <div class="dialog-box p-2 card">
    <div class="card-header">
      <div class="d-flex align-items-center">
        <div class="flex-grow-1">Acknowledge SOS</div>
        <div *ngIf="isAlertActive">
          <button class="btn btn-info btn-sm" (click)="muteAlert()">
            <i class="fas fa-volume-mute text-white"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="my-1" *ngIf="guardAlertData">
      <gtapp-map-leaflet
        [latLon]="guardAlertData?.gps"
        [mapId]="'alertMapId'"
      ></gtapp-map-leaflet>
    </div>
    <form [formGroup]="acknowledgeForm">
      <div class="mt-2">
        Guard has been contacted?
        <div class="d-flex justify-content-center" data-toggle="buttons">
          <label class="mx-2">
            <input type="radio" formControlName="inquired" [value]="true" /> Yes
          </label>
          <label class="mx-2">
            <input type="radio" formControlName="inquired" [value]="false" /> No
          </label>
        </div>
      </div>

      <div class="col mt-2">
        <textarea
          class="form-control"
          placeholder="Details and actions taken"
          cols="20"
          rows="5"
          wrap="hard"
          maxlength="900"
          formControlName="comment"
        ></textarea>
      </div>
    </form>

    <div class="row my-2">
      <div class="col">
        <button
          class="btn btn-dark btn-lg w-100"
          (click)="acknowledgeTemplate.close()"
        >
          Cancel
        </button>
      </div>
      <div id="confirmBtn" class="col">
        <button
          class="btn btn-primary btn-lg w-100"
          (click)="acknowledgeTemplate.close(); acknowledgeAlert()"
          [disabled]="!acknowledgeForm.valid"
        >
          Confirm
        </button>
      </div>
    </div>
  </div>
</ng-template>
