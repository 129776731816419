<div class="d-flex">
  <div class="mb-2 flex-grow-1 me-2">
    Add Job
    <span>
      <span *ngIf="selectedStatus?.id"> > {{ selectedStatus?.name }}</span>
      <span
        *ngIf="otherJobType?.site?.data?.id"
        (click)="currentStep = 1; shiftFocus('siteId')"
      >
        >
        <span class="cursor-pointer text-decoration-underline"
          >{{ otherJobType.site.value }} [{{ otherJobType.client.value }}]</span
        >
      </span>
      <!-- <span *ngIf="otherJobType?.oc?.data?.id" (click)="currentStep = 2">
        >
        <span class="cursor-pointer text-decoration-underline">{{
          otherJobType?.oc?.value
        }}</span>
      </span> -->
      <span
        *ngIf="otherJobType?.assignee?.data?.id"
        (click)="currentStep = 2; shiftFocus('assigneeId')"
      >
        >
        <span class="cursor-pointer text-decoration-underline">{{
          otherJobType?.assignee?.value
        }}</span>
      </span>
      <span
        *ngIf="otherJobType.ai.value"
        (click)="currentStep = 3; shiftFocus('aiId')"
      >
        >
        <span class="cursor-pointer text-decoration-underline">{{
          otherJobType.ai.value | misc : "sliceWord" : 10
        }}</span>
      </span>
    </span>
  </div>

  <div *ngIf="otherJobType?.site?.data?.id" class="me-lg-3">
    <button
      class="btn btn-outline-info btn-sm"
      (click)="showDataPreview = !showDataPreview"
      (blur)="updateShowDataPreview()"
    >
      @if(showDataPreview){<span
        ><i class="fa-solid fa-caret-up fa-2x"></i></span
      >}@else{<span><i class="fa-solid fa-caret-down fa-2x"></i></span>}
    </button>
  </div>
</div>
<ng-container *ngIf="showDataPreview">
  <div class="row row-cols-1 row-cols-md-2">
    <dl>
      <dt>
        Site
        <span (click)="currentStep = 1; shiftFocus('siteId')">
          <i class="fa-solid fa-pen-to-square ms-2"></i
        ></span>
      </dt>
      <dd>{{ otherJobType.site.value }} [{{ otherJobType.client.value }}]</dd>
    </dl>
    <!-- <dl *ngIf="otherJobType?.oc?.data?.id">
      <dt>
        Site Contact
        <span (click)="currentStep = 2">
          <i class="fa-solid fa-pen-to-square ms-2"></i
        ></span>
      </dt>
      <dd>{{ otherJobType?.oc?.value }}</dd>
    </dl> -->
    <dl *ngIf="otherJobType?.assignee?.data?.id">
      <dt>
        Assigned To
        <span (click)="currentStep = 2; shiftFocus('assigneeId')">
          <i class="fa-solid fa-pen-to-square ms-2"></i
        ></span>
      </dt>
      <dd>{{ otherJobType?.assignee?.value }}</dd>
    </dl>
    <dl *ngIf="otherJobType.ai.value">
      <dt>
        Additional Information
        <span (click)="currentStep = 3; shiftFocus('aiId')">
          <i class="fa-solid fa-pen-to-square ms-2"></i
        ></span>
      </dt>
      <dd>{{ otherJobType.ai.value | misc : "sliceWord" : 20 }}</dd>
    </dl>
  </div>
</ng-container>
<div class="row row-cols-1 row-cols-lg-2">
  <div class="col">
    <form [formGroup]="formdata">
      <!-- Step 1: Select Site -->
      <ng-container *ngIf="currentStep === 1">
        <div class="row row-cols-2 my-3">
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-dark w-100"
              [routerLink]="['/roster']"
            >
              Cancel
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-primary w-100"
              [disabled]="!otherJobType.site.data"
              (click)="
                currentStep = 2; shiftFocus('assigneeId'); getAssignees()
              "
            >
              Next
            </button>
          </div>
        </div>
        <!-- Client name display -->
        <div *ngIf="otherJobType.client.data" class="fw-bold mb-2">
          <dl>
            <dt>Client</dt>
            <dd>
              {{ otherJobType.client.value }}
            </dd>
          </dl>
        </div>
        <div class="mb-3">
          <gtapp-auto-complete
            [data]="siteList"
            placeHolder="Search Sites"
            searchKeyword="company_name"
            [initialValue]="formdata.value.siteName"
            [itemTemplate]="siteTemplate"
            [addInitialKey]="true"
            (inputChanged)="onSiteSearch($event)"
            (selected)="onSelectSite($event)"
            inputId="siteId"
          >
          </gtapp-auto-complete>
        </div>

        <ng-template #siteTemplate let-item>
          @if (item?.key === 0) {
          <div>Add New Site</div>
          } @else {
          <div>
            <div class="fw-medium">
              {{ item?.company_name }} [{{ item?.company?.company_name }}]
            </div>

            <div class="small-font text-secondary">
              {{ item?.address?.full_address }}
            </div>
          </div>
          }
        </ng-template>
        <div *ngIf="otherJobType.site?.data?.address?.latitude && !largeView">
          <gtapp-map-leaflet
            [latLon]="{
              lat: otherJobType.site?.data?.address?.latitude,
              lon: otherJobType.site?.data?.address?.longitude
            }"
            [mapId]="'largeJob'"
          ></gtapp-map-leaflet>
        </div>
      </ng-container>

      <!-- Step 2: Assign to Guard -->
      <ng-container *ngIf="currentStep === 2">
        <div class="row row-cols-2 my-2">
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-dark w-100"
              (click)="currentStep = 1; assigneeList = []"
            >
              Back
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-primary w-100"
              (click)="currentStep = 3"
            >
              Next
            </button>
          </div>
        </div>

        <div *ngIf="selectedStatus.enable_assignees === true" class="mb-3">
          <gtapp-auto-complete
            [data]="assigneeList"
            placeHolder="Assign To"
            searchKeyword="full_name"
            [initialValue]="otherJobType?.assignee?.value"
            [itemTemplate]="assigneeTemplate"
            (inputChanged)="onAssigneeSearch($event)"
            (selected)="onAssigneeSelect($event)"
            (inputFocused)="inputFocused('assigneeId')"
            inputId="assigneeId"
          >
          </gtapp-auto-complete>
        </div>

        <ng-template #assigneeTemplate let-item>
          <div>
            {{ item?.full_name }}
            <small *ngIf="!item?.validStateLicense">
              <i class="fa-solid fa-shield-halved"></i>
            </small>
          </div>
        </ng-template>
      </ng-container>

      <!-- Step 3: Additional Info -->
      <ng-container *ngIf="currentStep === 3">
        <div class="row row-cols-2 my-2">
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-dark w-100"
              (click)="currentStep = 2"
            >
              Back
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-primary w-100"
              (click)="formatScheduleForm(); currentStep = 4"
            >
              Next
            </button>
          </div>
        </div>

        <div class="mb-3">
          <textarea
            id="aiId"
            class="form-control"
            placeholder="Additional Information"
            cols="20"
            rows="10"
            wrap="hard"
            maxlength="900"
            style="width: 100%"
            type="text"
            [(ngModel)]="otherJobType.ai.value"
            formControlName="requestDetail"
          ></textarea>
        </div>
      </ng-container>

      <!-- Step 4: Schedule Time? -->
      <ng-container *ngIf="currentStep === 4">
        <div class="row row-cols-2 my-2">
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-dark w-100"
              (click)="currentStep = 3"
            >
              Back
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-primary btn-lg w-100"
              (click)="onAddJob()"
              [disabled]="addJobPressed || !schedulerForm.valid"
            >
              Add Job
            </button>
          </div>
        </div>

        <gtapp-scheduler
          *ngIf="scheduleStepperActive"
          [dialogBox]="false"
          [schedulerForm]="schedulerForm"
          [editSchedule]="true"
        ></gtapp-scheduler>
        <div class="mt-4 form-floating">
          <select
            class="form-select"
            (click)="getIntervalData()"
            [ngModel]="intervalData[0]?.value"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="updateInterval($event)"
            placeholder="Welfare Check Interval"
          >
            <option
              *ngFor="let interval of intervalData"
              [ngValue]="interval.value"
            >
              {{ interval.name }}
            </option>
          </select>
          <label for="">Welfare Check Interval</label>
        </div>
        <div *ngIf="intervalCheck" class="alert">
          <div class="mb-2">
            Guards will be reminded to submit a welfare check
            <span class="fw-bold">
              {{ welfareReminderDict?.[intervalCheck]  }} minutes before</span
            >
            the interval set above.
          </div>
          <div>
            Maximum inactivity time allowed before a missed welfare check is
            recorded:
            <span class="fst-italic fw-bold">
              {{ intervalCheck }} minutes.
            </span>
          </div>
        </div>
      </ng-container>
    </form>
  </div>
  <div
    class="col"
    *ngIf="otherJobType.site?.data?.address?.latitude && largeView"
  >
    <gtapp-map-leaflet
      [latLon]="{
        lat: otherJobType.site?.data?.address?.latitude,
        lon: otherJobType.site?.data?.address?.longitude
      }"
      [mapId]="'largeJob'"
    ></gtapp-map-leaflet>
  </div>
</div>
<!-- License Reminder Check Template -->
<ng-template
  #licenceCheckTemplateRef
  let-data
  let-licenceCheckTemplateRef="dialogRef"
>
  <div class="card dialog-box">
    <div class="card-header">
      WARNING

      <span
        class="float-end"
        (click)="licenceCheckTemplateRef.close(); shiftFocus('assigneeId')"
        ><i class="fa-solid fa-xmark"></i
      ></span>
    </div>
    <div>
      <div class="alert alert-danger">
        You have not added any security licences for
        {{ otherJobType?.site?.data?.address?.state_code }}

        <div class="mt-2">
          Licences can be added in
          <a
            (click)="ignoreLicenseCheck(); licenceCheckTemplateRef.close()"
            routerLink="/company-profile"
            class="link-primary"
          >
            Company Profile
          </a>
        </div>
      </div>

      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          id="licenseCheckbox"
          (change)="licenseReminderField = !licenseReminderField"
        />
        <label class="form-check-label" for="licenseCheckbox">
          Don't show this again
        </label>
      </div>

      <div class="row row-cols-2 mt-4">
        <div class="col">
          <button
            class="btn btn-secondary btn-lg w-100"
            (click)="ignoreLicenseCheck(); licenceCheckTemplateRef.close()"
          >
            Ignore
          </button>
        </div>
        <div class="col">
          <button
            class="btn btn-primary btn-lg w-100"
            (click)="showLicensePopup(); licenceCheckTemplateRef.close()"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
