import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isLargeScreen } from '../../../../../../global.variable';
import { AppService } from '../../../../../app.service';
import { ImagePreviewComponent } from '../../../../../shared/components/image-preview/image-preview.component';
import { ModelDialogueService } from '../../../../../shared/components/modal-dialogue/model-dialogue.service';
import { WebcamImageComponent } from '../../../../../shared/components/web-cam-image/web-cam-image.component';
import { LoadingSpinnerService } from '../../../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../../../shared/services/toaster.service';
import { WebSocketService } from '../../../../../shared/services/web-socket.service';
import { JobsService } from '../../../jobs/jobs.service';
import { HolAndChatService } from '../../hol-and-chat.service';

@Component({
  selector: 'gtapp-message',
  templateUrl: './message.component.html',
  styleUrl: './message.component.scss',
})
export class MessageComponent implements OnInit, AfterViewInit, OnDestroy {
  userMessageList: any = [];
  assigneeSearchList: any = [];
  assigneeList: any = [];
  subscriberUserId =
    this.appService.getUserData()?.subscriber?.subscriber_user_id;

  previous: number = 0;
  rows: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;
  totalRows: number = 0;
  selectedUser: any;
  messageList: any = [];
  newChat: boolean = false;
  chatMessage: string = '';
  largeView: Boolean = isLargeScreen;
  hideAdditionalItems: Boolean = false;
  containerHeight: string = '';
  messageFixedHeight: string = '';

  @ViewChildren('messages') messages: any = QueryList<any>;
  @ViewChild('content') content: any = ElementRef;
  constructor(
    private messageService: HolAndChatService,
    private jobService: JobsService,
    private toasterService: ToasterService,
    private appService: AppService,
    private dialogService: ModelDialogueService,
    private viewContainerRef: ViewContainerRef,
    private router: Router,
    private spinnerService: LoadingSpinnerService,
    private route: ActivatedRoute,
    private webSocketService: WebSocketService
  ) {
    router.events.subscribe((event: any) => {
      if (event.navigationTrigger === 'popstate') {
        this.goBack();
      }
    });
    this.selectedUser = JSON.parse(
      sessionStorage.getItem('counterPart') || '{}'
    );
    this.route.params.subscribe({
      next: (params) => {
        if (!this.largeView) {
          if (this.route.snapshot.fragment && this.selectedUser?.id) {
            this.getUserMessage(this.selectedUser);
          } else {
            this.getUserMessageList();
          }
        } else {
          if (this.route.snapshot.fragment && this.selectedUser?.id) {
            this.getUserMessage(this.selectedUser);
          }
          this.getUserMessageList();
        }
      },
    });
    this.webSocketService.webSocketSubject.subscribe((event: any) => {
      if (event?.event === 'individual_chat' && event?.data?.id) {
        this.appendNewMessage(event?.data);
      }
    });
  }
  ngOnDestroy(): void {
    sessionStorage.removeItem('counterPart');
  }
  ngOnInit(): void {}
  ngAfterViewInit(): void {
    setTimeout(() => {
      const btnBtnsHeight: any =
        document?.getElementById('bottom-btns')?.offsetHeight;

      this.containerHeight = `${window.innerHeight - 80}px`;
      //  80 - arbitrary value
      this.messageFixedHeight = `${
        window.innerHeight - (80 + btnBtnsHeight)
      }px`;
      this.scrollToBottom();
    }, 100);

    this.messages?.changes?.subscribe(this.scrollToBottom);
  }
  scrollToBottom = () => {
    try {
      this.content.nativeElement.scrollTop =
        this.content.nativeElement.scrollHeight;
    } catch (err) {}
  };
  appendNewMessage(msg: any) {
    if (this.selectedUser?.id === msg?.sender?.id) {
      // this is a hack to avoid multiple api calls to get correct unread message count
      sessionStorage.setItem('lastMsgReceivedAt', String(new Date().getTime()));
      this.messageList.push(msg);
    } else {
      const isUserthere = this.userMessageList?.findIndex(
        (user: any) => user?.id === msg?.sender?.id
      );
      if (isUserthere >= 0) {
        this.userMessageList[isUserthere].unread += 1;
      } else {
        this.userMessageList?.splice(0, 0, {
          unread: 1,
          id: msg?.sender?.id,
          full_name: msg?.sender?.full_name,
        });
      }
    }

    // ;
  }
  getUserMessageList() {
    const lastWSTime = sessionStorage.getItem('lastMsgReceivedAt') || 0;
    this.messageService
      .getUserMessageList({ group: 1, count_after: lastWSTime })
      .subscribe((response: any) => {
        if (response?.status == 'success') {
          this.userMessageList = response?.data;
        }
      });
  }
  searchAssignee(event?: any) {
    if (event.target.value?.length > 2) {
      let params: any = { full_name: event.target.value };
      this.jobService.getAssignees(params).subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.assigneeSearchList = response?.data?.filter(
            (guard: any) => guard?.id != this.subscriberUserId
          );
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
    }
  }
  getGuardList() {
    this.jobService.getAssignees({}).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.assigneeList = response?.data?.filter(
          (guard: any) => guard?.id != this.subscriberUserId
        );
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }
    });
  }
  openTemplate(template: TemplateRef<any>) {
    this.assigneeList = [];
    this.getGuardList();
    this.dialogService.open(
      template,
      {
        data: {},
      },
      this.viewContainerRef
    );
  }
  getUserMessage(userData: any) {
    this.messageList = [];
    this.selectedUser = userData;
    sessionStorage.setItem('counterPart', JSON.stringify(userData));
    this.router.navigate(['/message'], { fragment: userData?.id });
    let params: any = { counter_part_id: userData?.id };

    if (this.rows) {
      params['rows'] = this.rows;
    }
    if (this.previous) {
      params['previous'] = this.previous;
    }

    this.messageService
      .getUserMessageList(params)
      .subscribe((response: any) => {
        if (response?.status == 'success') {
          this.totalRows = response?.total_count;
          this.formatMessageList(response?.data);
        }
      });
  }
  formatMessageList(messageData: any) {
    let tempPrevMessageList = [...(this.messageList || [])];
    let newList = (messageData || []).reverse();
    this.messageList = newList.concat(tempPrevMessageList);

    if (this.newChat) {
      this.getUserMessageList();
      this.newChat = false;
    }
  }
  openCamPopupDialog() {
    const dialogRef = this.dialogService.open(WebcamImageComponent, {
      data: {},
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value && value !== 'error') {
        this.sendFileMessage(value);
      }
    });
  }
  sendFileMessage(value: any) {
    let fileData: FormData = new FormData();

    fileData.append('file', value.actualFileUpload);
    if (value.newComment) {
      fileData.append('message', value.newComment);
    }
    let url = `gt_message`;
    fileData.append('counter_part_id', this.selectedUser?.id);
    fileData.append('image_source', value?.uploadType);
    // if (this.selectedJobData?.event_id) {
    //   fileData.append(
    //     this.selectedJobData?.job_type_name?.toLowerCase() === 'patrol'
    //       ? 'patrol_route_id'
    //       : 'job_id',
    //     this.selectedJobData?.event_id
    //   );
    // }
    this.appService.formDataApi(url, fileData).then((response: any) => {
      if (response?.data) {
        this.formatMessageList(response?.data);
      }
    });
  }
  sendMessage() {
    let body: any = {
      counter_part_id: this.selectedUser?.id,
      message: this.chatMessage,
    };
    // if (this.selectedJobData?.event_id) {
    //   body[
    //     this.selectedJobData?.job_type_name?.toLowerCase() === 'patrol'
    //       ? 'patrol_route_id'
    //       : 'job_id'
    //   ] = this.selectedJobData?.event_id;
    // }
    this.messageService.sendMessage(body).subscribe((response: any) => {
      if (response?.status == 'success') {
        this.messageList = [];
        this.formatMessageList(response?.data);

        this.chatMessage = '';
      }
    });
  }
  goBack() {
    this.messageList = [];
    this.selectedUser = null;
    sessionStorage.removeItem('counterPart');
    this.getUserMessageList();
    this.router.navigate(['/message']);
  }
  onOpenImage(event: any, data: any) {
    var target = event.target || event.srcElement || event.currentTarget;
    var srcAttr = target.attributes.src;

    this.dialogService.open(ImagePreviewComponent, {
      data: { imageSrc: srcAttr.nodeValue, timeStamp: data.updated_at },
    });
  }
}
