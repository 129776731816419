import { Component, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ModelDialogueService } from '../../../shared/components/modal-dialogue/model-dialogue.service';
import { LoadingSpinnerService } from '../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { UserPromptService } from './user-prompt.service';

@Component({
  selector: 'gtapp-user-prompts',
  templateUrl: './user-prompts.component.html',
  styleUrl: './user-prompts.component.scss',
})
export class UserPromptsComponent {
  previousUserPrompt: number = 0;
  pageSizeUserPrompt: number = 10;
  pageNumUserPrompt: number = 1;
  totalPagesUserPrompt: number = 0;
  totalCountUserPrompt: number = 0;
  userPromptList: any = [];
  userPromptDropDownList: any = [];
  cardIcons = [
    {
      condition: function (row: any) {
        return {
          icon: 'fa-regular fa-copy fa-2x',
          status: 'basic',
          title: 'Copy Prompt',
          confirm: true,

          type: 'copy',
        };
      },
    },
    {
      condition: function (row: any) {
        return {
          icon: 'fa-regular fa-trash-can fa-2x',
          title: 'Delete Prompt',
          confirm: true,
          type: 'delete',
        };
      },
    },
  ];
  otherParamsUserPrompt: any = {};
  paginatedPromptData: any;
  selectedPagePrompts: any;
  page: number = 0;
  promptName: any;
  visibleOnHomePage: boolean = false;
  promptStatusOptions = [
    { key: 1, value: 'All' },
    { key: 2, value: 'Assigned' },
    { key: 3, value: 'Unassigned' },
  ];
  promptStatusSelected: number = 1;
  constructor(
    private userPromptService: UserPromptService,
    private dialogService: ModelDialogueService,
    private router: Router,
    private spinnerService: LoadingSpinnerService,
    private toasterService: ToasterService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.getAllUserPrompts();
  }
  getAllUserPrompts() {
    this.spinnerService.show();
    let params: any = {};
    if (this.pageSizeUserPrompt) {
      params['rows'] = this.pageSizeUserPrompt;
    }
    if (this.visibleOnHomePage) {
      params['show_on_home'] = 1;
    }
    if (this.previousUserPrompt) {
      params['previous'] = this.previousUserPrompt;
    }
    if (this.promptStatusSelected == 2) {
      params['assigned'] = 1;
    } else if (this.promptStatusSelected == 3) {
      params['unassigned'] = 1;
    }
    this.userPromptService
      .getUserPromptList(params)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.totalCountUserPrompt = response['total_size'];
          this.totalPagesUserPrompt = Math.ceil(
            this.totalCountUserPrompt / this.pageSizeUserPrompt
          );
          this.otherParamsUserPrompt.paginationData = {
            totalRows: this.totalCountUserPrompt,
            previousRows: this.previousUserPrompt,
            rows: this.pageSizeUserPrompt,
            pageNum: this.pageNumUserPrompt,
            totalPages: this.totalPagesUserPrompt,
          };

          this.userPromptList = [...response?.data];
        }
        this.spinnerService.hide();
      });
  }
  getPromptDetail(promptData: any) {
    this.router.navigate([
      '/user-prompt',
      {
        uPKey: String(promptData?.id),
      },
    ]);
  }
  deletePrompt(promptData: any) {
    let dialogMsg = 'Delete User Prompt';
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Are you sure?',
        message: dialogMsg,
        alertDanger: true,
      },
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value === true) {
        this.userPromptService
          .deleteUserPrompt(promptData?.id)
          .subscribe((response: any) => {
            if (response['status'] == 'success') {
              this.getAllUserPrompts();
            }
          });
      }
    });
  }
  onChangePagination(event: any) {
    this.previousUserPrompt = event?.paginationData?.previousRows;
    this.pageNumUserPrompt = event?.paginationData?.pageNum;
    this.pageSizeUserPrompt = event?.paginationData?.rows;
    this.getAllUserPrompts();

    this.otherParamsUserPrompt.paginationData = { ...event?.paginationData };
  }
  onUserPromptLookup(event: any) {
    if (
      event.target.value.length === 3 ||
      (event.target.value.length > 3 && this.userPromptDropDownList?.length)
    ) {
      this.userPromptService
        .getUserPromptList({ search_str: event.target.value })
        .subscribe((res: any) => {
          if (res['status'] == 'success') {
            this.userPromptDropDownList = res?.data;
          }
        });
    }
  }
  actionClick(promptData: any, copyTemplate?: any) {
    if (promptData?.type === 'delete') {
      this.deletePrompt(promptData?.event);
    } else {
      this.copyUserPromp(promptData?.event, copyTemplate);
    }
  }
  copyUserPromp(promptData: any, copyTemplate: any) {
    let dialogMsg = 'Copy User Prompt';
    this.promptName = promptData?.name;
    const dialogRef = this.dialogService.open(
      copyTemplate,
      {
        data: {
          title: 'Copy User Prompt?',
          message: dialogMsg,
          data: promptData,
        },
      },
      this.viewContainerRef
    );
    dialogRef.afterClosed().subscribe(async (dialogResponse) => {
      if (dialogResponse === 'Yes') {
        this.getUserPromptDetail(promptData?.id);
      }
    });
  }
  onConfirmCopy(userPrompId: any) {
    this.getUserPromptDetail(userPrompId);
  }
  getUserPromptDetail(userPromptId: any) {
    this.userPromptService
      .getUserPromptById(userPromptId)
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.paginatedPromptData = [...response?.data?.prompts];
          this.selectedPagePrompts = this.paginatedPromptData[this.page];
          this.spinnerService.show();
          const body: any = {
            name: this.promptName,
            prompts: this.paginatedPromptData,
          };
          this.userPromptService
            .createUserPrompt(body)
            .subscribe((response: any) => {
              this.spinnerService.hide();
              if (response?.status === 'success') {
                this.toasterService.setMessage({
                  successMessage: response?.message,
                  errorMessage: '',
                });
                this.getAllUserPrompts();
              } else {
                this.toasterService.setMessage({
                  successMessage: '',
                  errorMessage: response?.message,
                });
              }
              this.spinnerService.hide();
            });
        }
      });
  }
}
