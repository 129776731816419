import {
  Component,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelDialogueService } from '../../../../../../shared/components/modal-dialogue/model-dialogue.service';
import { LoadingSpinnerService } from '../../../../../../shared/services/loading-spinner.service';
import { ToasterService } from '../../../../../../shared/services/toaster.service';
import { JobsService } from '../../../../jobs/jobs.service';
import { getDateTimeRange } from '../../../../tableDetails';
import { UsersService } from '../../../../users/users.service';
import { HolAndChatService } from '../../../hol-and-chat.service';

@Component({
  selector: 'gtapp-add-edit-hand-over-log',
  templateUrl: './add-edit-hand-over-log.component.html',
  styleUrl: './add-edit-hand-over-log.component.scss',
})
export class AddEditHandOverLogComponent implements OnInit {
  eventDataPrevious: number = 0;
  eventDataPageSize: number = 10;
  eventDataPageNum: number = 1;
  eventDataTotalPages: number = 0;
  eventDataTotalRows: number = 0;

  eventsDataList: any = [];
  handOverDraftedData: any = [];
  selectedEventCount: number = 0;

  selectedEventType: string = 'patrol';
  sortKeys: any = [];
  searchParams: any;
  otherParams: any = {};

  userEventDetailsMobileView: any = {
    individualDetails: [
      {
        name: 'updated_at',
        datetimeObj: true,
        dateRangeKey: 'updated_at',
        header: 'Event Time',
        ...getDateTimeRange(),
        sortKey: 'updated_at',
        cardRowStyle: {
          'font-size': 'small',
          'float': 'right',
        },
      },
      {
        name: 'action',
        cardRowStyle: {
          'font-size': 'small',
          'font-weight': 'bold',
          'text-transform': 'uppercase',
        },
      },
      {
        name: 'company_name',
        cardRowStyle: {
          'font-size': 'small',
        },
      },

      {
        name: 'detail',
        cardRowStyle: {
          'font-size': 'small',
        },
      },
    ],
  };
  tableStyle = {
    'overflow': 'auto',
    'max-height': '100%',
  };
  userEventDetailsTableView: any = [
    {
      header: 'Event Time',
      name: 'updated_at',
      datetimeObj: true,
      dateRangeKey: 'updated_at',
      sortKey: 'updated_at',
      ...getDateTimeRange(),
    },

    {
      header: 'Event Type',
      name: 'action',
    },
    {
      header: 'Company',
      name: 'company_name',
    },
    {
      header: 'Checkpoint',
      columnDataStyle: {
        'white-space': 'pre-line',
      },
      nestedValue: (row: any) => {
        return [0, 6].includes(row?.event_action)
          ? [row?.detail, row?.address].join('\n')
          : '';
      },
    },
  ];
  currentStep: number = 1;
  handOverLogDescription: string = '';

  recipientList: any = [];
  selectedRecipients: any = [];
  recipientSearchList: any = [];

  handOverLogRecipientsRows: number = 25;
  handOverLogRecipientsPrevious: number = 0;
  handOverLogRecipientsPageNum: number = 1;
  handOverLogRecipientsTotalPages: number = 0;
  handOverLogRecipientsTotalCount: number = 0;

  selectedHandOverLogId: string = '';
  selectedHandOverLogData: any;

  constructor(
    private spinnerService: LoadingSpinnerService,
    private toasterService: ToasterService,
    private userService: UsersService,
    private dialogService: ModelDialogueService,
    private viewContainerRef: ViewContainerRef,
    private jobService: JobsService,
    private handOverLogService: HolAndChatService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe({
      next: (params) => {
        if (params?.['hKey']) {
          this.selectedHandOverLogId = atob(params?.['hKey']);
        }
      },
    });
  }
  ngOnInit(): void {
    if (this.selectedHandOverLogId) {
      this.getHandOverLogDetailData();
    } else {
      this.getUserUpdateLog('drafted');
      this.getUserUpdateLog('add_more');
    }
  }
  getHandOverLogDetailData() {
    this.handOverLogService
      .retrieveHandOverLog(this.selectedHandOverLogId)
      .subscribe((resp: any) => {
        if (resp?.status === 'success') {
          this.selectedHandOverLogData = resp?.data;
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: resp['message'],
          });
        }
      });
  }
  getUserUpdateLog(event: string) {
    this.spinnerService.show();
    let body: any = {
      event_type: this.selectedEventType,
      search_filters: {},
    };
    switch (event) {
      case 'drafted':
        body.hand_over_log_drafted = 1;
        break;

      case 'add_more':
        body.hand_over_log = 1;
    }

    if (this.sortKeys) body.sort_key = this.sortKeys;
    if (this.searchParams) body.search_filters = this.searchParams;
    if (this.eventDataPageSize) body.rows = this.eventDataPageSize;
    if (this.eventDataPrevious) body.previous = this.eventDataPrevious;

    this.userService.getUserUpdateLog(body).subscribe((response: any) => {
      if (response['status'] == 'success') {
        if (event === 'drafted') {
          this.handOverDraftedData = response['data'];
          this.selectedEventCount = this?.handOverDraftedData?.length;
        } else {
          this.eventsDataList.push(...response?.data);
          this.eventDataTotalRows = response?.total_size;
          this.eventDataTotalPages = Math.ceil(
            this.eventDataTotalRows / this.eventDataPageSize
          );
          this.otherParams.paginationData = {
            totalRows: this.eventDataTotalRows,
            previousRows: this.eventDataPrevious,
            rows: this.eventDataPageSize,
            pageNum: this.eventDataPageNum,
            totalPages: Math.ceil(
              this.eventDataTotalRows / this.eventDataPageSize
            ),
          };
        }

        this.spinnerService.hide();
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
        this.spinnerService.hide();
      }
    });
  }
  openEventDetailTemplate(template: TemplateRef<any>, event: any) {
    this.dialogService.open(
      template,
      {
        data: { selectedEvent: event },
      },
      this.viewContainerRef
    );
  }
  getRecipients() {
    let params: any = {};
    if (this.handOverLogRecipientsRows) {
      params['rows'] = this.handOverLogRecipientsRows;
    }
    if (this.handOverLogRecipientsPrevious) {
      params['previous'] = this.handOverLogRecipientsPrevious;
    }

    this.spinnerService.show();
    this.jobService.getAssignees(params).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.recipientList.push(...response?.data);

        this.handOverLogRecipientsTotalCount = response?.total_count;
        this.handOverLogRecipientsTotalPages = Math.ceil(
          this.handOverLogRecipientsTotalCount / this.handOverLogRecipientsRows
        );

        this.makeAssigneeSelectionChanges();
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response?.message,
        });
      }
      this.spinnerService.hide();
    });
  }
  removeSelectedAssignee(deleteAssignee: any) {
    this.selectedRecipients = this.selectedRecipients?.filter(
      (assignee: any) => assignee?.id !== deleteAssignee?.id
    );
    this.makeAssigneeSelectionChanges();
  }
  addSelectedAssignee(addAssignee: any) {
    if (addAssignee?.id) {
      if (
        this.selectedRecipients?.some(
          (assignee: any) => assignee?.id === addAssignee?.id
        )
      ) {
        this.toasterService.setMessage({
          errorMessage: 'assignee already added',
          successMessage: '',
        });
      } else {
        this.selectedRecipients.push(addAssignee);
        if (
          !this.recipientList?.some(
            (assignee: any) => assignee?.id === addAssignee?.id
          )
        ) {
          this.recipientList.push(addAssignee);
        }
      }

      this.makeAssigneeSelectionChanges();
    }
  }
  selectUnselectEvents(event: any) {
    event.selected = !event?.selected;
    const selectedItems = this.eventsDataList?.filter(
      (event: any) => event?.selected
    );
    this.selectedEventCount =
      this?.handOverDraftedData?.length + selectedItems?.length;
  }
  addRemovessignee(assignee: any) {
    assignee.selected = !assignee?.selected;
    if (assignee.selected) {
      this.addSelectedAssignee(assignee);
    } else {
      this.removeSelectedAssignee(assignee);
    }
  }
  makeAssigneeSelectionChanges() {
    this.recipientList.forEach((assignee: any) => {
      assignee.selected = this.selectedRecipients?.some(
        (selectedAssignee: any) => selectedAssignee?.id === assignee?.id
      );
    });
  }

  searchAssignee(event?: any) {
    if (event.target.value?.length > 2) {
      let params: any = { full_name: event.target.value };
      this.jobService.getAssignees(params).subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.recipientSearchList = response?.data?.filter(
            (value1: any) =>
              !this.selectedRecipients?.some(
                (value2: any) => value1?.id === value2?.id
              )
          );
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
      });
    }
  }

  onLoadMoreAssignees() {
    this.handOverLogRecipientsPrevious =
      this.handOverLogRecipientsPrevious + this.handOverLogRecipientsRows;

    this.getRecipients();
    setTimeout(() => {
      const table = document.querySelector('#assigneeList');
      if (table) {
        table.scrollTo({ top: table.scrollHeight, behavior: 'smooth' });
      }
    }, 100);
  }
  onLoadMoreEvents() {
    this.eventDataPrevious = this.eventDataPrevious + this.eventDataPageSize;
    this.getUserUpdateLog('add_more');
  }
  createhandOverLog() {
    let body = {
      description: this.handOverLogDescription,
      recipient_ids: [
        ...new Set(this.selectedRecipients?.map((user: any) => user?.id)),
      ],
      user_update_ids: [
        ...new Set(
          this.eventsDataList
            ?.filter((event: any) => event?.selected)
            ?.map((event: any) => event?.id)
        ),
        ...new Set(this.handOverDraftedData?.map((event: any) => event?.id)),
      ],
    };
    this.handOverLogService.createHandOverLog(body).subscribe((resp: any) => {
      if (resp?.status === 'success') {
        this.router.navigate(['/']);
        this.toasterService.setMessage({
          successMessage: resp['message'],
          errorMessage: '',
        });
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: resp['message'],
        });
      }
    });
  }
}
